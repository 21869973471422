export const ORDER_VIEW_PERMISSION = "order-view-permission";
export const ORDER_CREATE_PERMISSION = "order-create-permission";
export const ORDER_UPDATE_PERMISSION = "order-update-permission";
export const ORDER_PROCEED_PERMISSION = "order-proceed-permission";
export const ZONE_VIEW_PERMISSION = "zone-view-permission";
export const ZONE_CREATE_PERMISSION = "zone-create-permission";
export const ZONE_UPDATE_PERMISSION = "zone-update-permission";
export const ZONE_DELETE_PERMISSION = "zone-delete-permission";
export const OUTLET_VIEW_PERMISSION = "outlet-table-view-permission";
export const OUTLET_CREATE_PERMISSION = "outlet-table-create-permission";
export const OUTLET_UPDATE_PERMISSION = "outlet-table-update-permission";
export const OUTLET_DELETE_PERMISSION = "outlet-table-delete-permission";
export const SHIFT_VIEW_PERMISSION = "shift-view-permission";
export const SHIFT_CREATE_PERMISSION = "shift-start-permission";
export const SHIFT_END_PERMISSION = "shift-end-permission";
