import { AuthContext } from "context/authContext";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { OrderServices } from "services/OrderServices";
import { useReactToPrint } from "react-to-print";
import { useGetTenderTaxHook } from "hooks/useGetTenderTaxHook";

export const useDuplicateReceipt = () => {
  const { manualLogout } = useContext(AuthContext);
  const cartState = useSelector((state) => state.cart);
  const editOrder = useSelector((state) => state.editOrder);
  const receiptRef = useRef();
  const { decimal_preference, taxEnabled } = useGetActiveLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mainOrder, setMainOrder] = useState(null);
  const [qrCode, setQrCode] = useState("");

  // Getting the order details
  const getOrder = useCallback(() => {
    setLoading(true);
    setError("");
    OrderServices.getOrder(
      { orderNumber: editOrder.order?.order_no },
      (res) => {
        setLoading(false);
        // console.log(res, "Res");
        setMainOrder(res?.data?.data?.data);
        setQrCode(res?.data?.data?.data?.qr_code);
      },
      (err) => {
        setLoading(false);
        if (err && err?.response?.status === 401) {
          manualLogout();
        } else {
          setError(
            err?.response?.data?.message || "Error occurred while getting order"
          );
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubTotalPrice = () => {
    let subTotalPrice = 0;
    if (
      cartState &&
      cartState?.cart &&
      cartState?.cart[cartState?.activeCart]
    ) {
      for (const item of cartState?.cart[cartState?.activeCart]) {
        subTotalPrice += +item?.price * item?.quantity;
      }
    }

    return subTotalPrice;
  };

  //  Tax
  const { showTenderTax, tenderFee } = useGetTenderTaxHook({
    tax: mainOrder?.tender?.tax,
    tax_type: mainOrder?.tender?.tax_type,
    order_total: getSubTotalPrice(),
  });

  const getTotalTax = () => {
    const orderTax = mainOrder && mainOrder?.tax ? Number(mainOrder?.tax) : 0;

    return Number(orderTax).toFixed(decimal_preference);
  };

  //   Channel
  const showChannel =
    mainOrder && mainOrder?.commission && Number(mainOrder?.commission) > 0
      ? true
      : false;

  // Get channel commission if available
  const getChannelPrice = () => {
    let channelFee = 0;
    if (showChannel) {
      channelFee =
        mainOrder &&
        mainOrder?.delivery_channel &&
        mainOrder?.delivery_channel?.commission_type === "percentage"
          ? Number(mainOrder?.commission_amount)
          : Number(mainOrder?.commission);
    }
    const mainChannel = Number(channelFee);
    return mainChannel.toFixed(decimal_preference);
  };

  //  Discount
  const showDiscount =
    mainOrder &&
    mainOrder?.total_discount &&
    Number(mainOrder?.total_discount) > 0
      ? true
      : false;
  const discountValue =
    mainOrder && mainOrder?.total_discount
      ? Number(mainOrder?.total_discount)
      : 0;
  const discountType =
    mainOrder && mainOrder?.discount_type
      ? mainOrder?.discount_type
      : "percentage";

  // Getting total discount from API result
  const discountPrice = () => {
    let discount = 0.0;
    if (showDiscount && discountType === "percentage") {
      const mainDiscount = (discountValue * getSubTotalPrice()) / 100;
      discount = mainDiscount;
      return discount;
    } else if (
      showDiscount &&
      (discountType === "fixed" || discountType === "amount")
    ) {
      return discountValue;
    } else {
      return discount;
    }
  };
  // Calculating the total price
  const getOrderTotalPrice = () => {
    let totalPrice = 0;
    if (showChannel && showDiscount) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(getTotalTax());
      totalPrice += Number(getChannelPrice());
      totalPrice += tenderFee;
      totalPrice -= discountPrice();
      return totalPrice.toFixed(decimal_preference);
    } else if (!showChannel && showDiscount) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(getTotalTax());
      totalPrice += tenderFee;
      totalPrice -= discountPrice();
      return totalPrice.toFixed(decimal_preference);
    } else if (showChannel && !showDiscount) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(getChannelPrice());
      totalPrice += tenderFee;
      totalPrice += Number(getTotalTax());
      return totalPrice.toFixed(decimal_preference);
    } else if (!showChannel && !showDiscount) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(getTotalTax());
      totalPrice += tenderFee;
      return totalPrice.toFixed(decimal_preference);
    } else {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(getTotalTax());
      totalPrice += tenderFee;
      return totalPrice.toFixed(decimal_preference);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  return {
    receiptRef,
    loading,
    mainOrder,
    qrCode,
    error,
    getOrder,
    handlePrint,
    showTenderTax,
    tenderFee,
    showChannel,
    showDiscount,
    discountPrice,
    getOrderTotalPrice,
    getChannelPrice: () => Number(getChannelPrice()),
    getSubTotalPrice,
    getTotalTax: () => Number(getTotalTax()),
    taxEnabled,
  };
};
