import { useDispatch, useSelector } from "react-redux";
import { toggleOrderTypesInCart } from "redux/cart";
import { setCheckoutChannel } from "redux/channel";
import { toggleOrderTypes } from "redux/createOrder";
import { updateSelectedOrders } from "redux/editingOrder";
import { setCheckoutTender } from "redux/tenders";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

export const useDefaultSetting = () => {
  const { activeLocation } = useSelector((state) => state.location);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedOrders } = useSelector((state) => state.editOrder);
  const { activeCart } = useSelector((state) => state.cart);

  // This is passed to the payment channel screen url to get the tender and channel taxes for the check out order
  const checkoutOrderId = useMemo(() => {
    return selectedOrders.filter((item) => item?.type === activeCart)[0]?.id;
  }, [activeCart, selectedOrders]);

  const chooseOrderServiceType = (newOrderType) => {
    dispatch(toggleOrderTypes(newOrderType));
    dispatch(toggleOrderTypesInCart(newOrderType));
    dispatch(
      updateSelectedOrders({
        type: newOrderType,
        status: "draft",
        order_no: "",
        id: Math.floor(Math.random() * 990) + 10,
        outlet_table_id: newOrderType === "dine in" ? 1 : null,
        orderItems: [],
        vatvalue: null,
        total: null,
        order_price: null,
        comment: "",
        discount_type: "percentage",
        discount_value: 0,
      })
    );
  };

  const paymentChannelDestination = (order_id = "") => {
    if (
      activeLocation?.default_delivery_channel &&
      activeLocation?.default_tender
    ) {
      dispatch(
        setCheckoutChannel({
          checkoutChannel: {
            ...activeLocation?.default_delivery_channel,
            img: activeLocation?.default_delivery_channel?.smallimage,
          },
        })
      );
      dispatch(setCheckoutTender(activeLocation?.default_tender));
      navigate("/orders/checkout/receipt");
    } else if (activeLocation?.default_delivery_channel) {
      dispatch(
        setCheckoutChannel({
          checkoutChannel: { ...activeLocation?.default_delivery_channel },
          img: activeLocation?.default_delivery_channel?.smallimage,
        })
      );
      if (checkoutOrderId && !order_id) {
        // navigate(`/orders/checkout/payment-channel/${checkoutOrderId}`);
        navigate("/orders/checkout/payment-channel", {
          state: {
            order_id: `${checkoutOrderId}`,
          },
        });
      } else if (checkoutOrderId && order_id) {
        // navigate(`/orders/checkout/payment-channel/${order_id}`);
        navigate("/orders/checkout/payment-channel", {
          state: {
            order_id: `${order_id}`,
          },
        });
      } else navigate("/orders/checkout/payment-channel");
    } else if (activeLocation?.default_tender) {
      dispatch(setCheckoutTender(activeLocation?.default_tender));
      if (checkoutOrderId && !order_id) {
        // navigate(`/orders/checkout/payment-channel/${checkoutOrderId}`, {});
        navigate("/orders/checkout/payment-channel", {
          state: {
            order_id: `${checkoutOrderId}`,
          },
        });
      } else if (checkoutOrderId && order_id) {
        // navigate(`/orders/checkout/payment-channel/${order_id}`);
        navigate("/orders/checkout/payment-channel", {
          state: {
            order_id: `${order_id}`,
          },
        });
      } else navigate("/orders/checkout/payment-channel");
    } else {
      if (checkoutOrderId && !order_id) {
        // navigate(`/orders/checkout/payment-channel/${checkoutOrderId}`);
        navigate("/orders/checkout/payment-channel", {
          state: {
            order_id: `${checkoutOrderId}`,
          },
        });
      } else if (checkoutOrderId && order_id) {
        // navigate(`/orders/checkout/payment-channel/${order_id}`);
        navigate("/orders/checkout/payment-channel", {
          state: {
            order_id: `${order_id}`,
          },
        });
      } else navigate("/orders/checkout/payment-channel");
    }
  };

  const paymentChannelDestinationHelper = (order_id = "") => {
    if (
      activeLocation?.default_delivery_channel &&
      activeLocation?.default_tender
    ) {
      dispatch(
        setCheckoutChannel({
          checkoutChannel: {
            ...activeLocation?.default_delivery_channel,
            img: activeLocation?.default_delivery_channel?.smallimage,
          },
        })
      );
      dispatch(setCheckoutTender(activeLocation?.default_tender));
      navigate("/orders/checkout/payment-channel", {
        state: {
          showReceipt: true,
        },
      });
    } else if (activeLocation?.default_delivery_channel) {
      dispatch(
        setCheckoutChannel({
          checkoutChannel: { ...activeLocation?.default_delivery_channel },
          img: activeLocation?.default_delivery_channel?.smallimage,
        })
      );
      navigate("/orders/checkout/payment-channel", {
        state: {
          order_id: `${order_id}`,
        },
      });
    } else if (activeLocation?.default_tender) {
      dispatch(setCheckoutTender(activeLocation?.default_tender));
      navigate("/orders/checkout/payment-channel", {
        state: {
          order_id: `${order_id}`,
        },
      });
    } else {
      navigate("/orders/checkout/payment-channel", {
        state: {
          order_id: `${order_id}`,
        },
      });
    }
  };

  return {
    default_tender: activeLocation?.default_tender,
    default_channel: activeLocation?.default_delivery_channel,
    skip_customer_info: activeLocation?.skip_customer_info,
    default_service_type: activeLocation?.default_service_type,
    chooseOrderServiceType,
    paymentChannelDestination,
    paymentChannelDestinationHelper,
  };
};
