import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleHideHeader, toggleHideSideBar } from "redux/drawer";

export const useHideNavigation = () => {
  const activeLocation = useLocation();
  const url = activeLocation?.pathname.split("/");
  const dispatch = useDispatch();

  useMemo(() => {
    if (url.includes("shift") || url.includes("floor")) {
      dispatch(toggleHideSideBar(false));
      return false;
    } else {
      dispatch(toggleHideSideBar(true));
      return true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, activeLocation, dispatch]);

  useMemo(() => {
    if (url.includes("shift") || url.includes("floor")) {
      dispatch(toggleHideHeader(false));
      return false;
    } else if (
      url.includes("orders") &&
      (activeLocation?.pathname === "/orders/create/order-type" ||
        activeLocation?.pathname === "/orders/create/allocation-steps" ||
        activeLocation?.pathname === "/orders/create/table-allocation")
    ) {
      dispatch(toggleHideHeader(false));
      return false;
    } else {
      dispatch(toggleHideHeader(true));
      return true;
    }
  }, [url, activeLocation, dispatch]);

  //   return {
  //     hideSideBar,
  //   };
};
