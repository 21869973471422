import React from "react";
import styles from "./Table.module.scss";

const TableRow = ({ children, onClick }) => {
  return (
    <tr
      className={`${styles.table__row} ${onClick ? "cursor-pointer" : ""}`}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {children}
    </tr>
  );
};

export default TableRow;
