import React from "react";
import MainDishServices from "./MainDishServices";
import MainDishItems from "./MainDishItems";
import MainDishFooter from "./MainDishFooter";
import MainDishNoteView from "./MainDishNoteView";
import { MdOutlineChevronLeft } from "react-icons/md";

const MainDishSelectionRightSide = ({
  dishView,
  onRemove,
  onReduceItemQuantity,
  onIncreaseItemQuantity,
  changeClickedNote,
  clickedNoteItem,
  openNoteView,
  closeNoteView,
  noteView,
  changeDishView,
}) => {
  return (
    <div className="w-full bg-[#F3F4F6] h-full lg:px-4 py-8 lg:pr-4 pr-8 pl-4  overflow-y-auto pb-[0.5rem] xl:pb-[1.5rem]">
      {!noteView && (
        <div className="w-full h-full  grid grid-rows-[auto_1fr_auto] ">
          <div className="w-full">
            <button
              className="flex flex-row w-full items-center mb-4"
              onClick={() => changeDishView("discount_selection")}
            >
              <MdOutlineChevronLeft className="text-[2rem] text-gray-500" />
              <span className="text-[1.3rem] text-gray-500">
                Go back to discount type
              </span>
            </button>
            <MainDishServices />
          </div>

          <MainDishItems
            dishView={dishView}
            onRemove={onRemove}
            onReduceItemQuantity={onReduceItemQuantity}
            onIncreaseItemQuantity={onIncreaseItemQuantity}
            changeClickedNote={changeClickedNote}
            clickedNoteItem={clickedNoteItem}
            openNoteView={openNoteView}
            closeNoteView={closeNoteView}
          />

          <MainDishFooter />
        </div>
      )}
      {noteView && (
        <MainDishNoteView
          noteView={noteView}
          closeNoteView={closeNoteView}
          clickedNoteItem={clickedNoteItem}
        />
      )}
    </div>
  );
};

export default MainDishSelectionRightSide;
