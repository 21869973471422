import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import React from "react";
import SingleOrderAmount from "./SingleOrderAmount";
import classes from "../CheckoutReceiptChangeModal.module.scss";
import IconButton from "components/IconButton/IconButton";
import { toast } from "react-toastify";

const OrderChangeInterface = ({
  orderTotal,
  toggleModal,
  returnChange,
  amount,
  completeOrder,
  resetAmount,
}) => {
  const { currency, decimal_preference } = useGetActiveLocation();
  const cashReceived = Number(amount).toFixed(decimal_preference);

  const sendOrder = () => {
    if (Number(amount) >= Number(orderTotal)) {
      toggleModal();
      completeOrder();
    } else {
      toast.error("Cash received should not be less than payable amount", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  return (
    <div className="w-full h-full border border-gray-200 rounded-md px-10 py-7  flex-1 relative ">
      <div className="bg-[#F9FAFB] rounded w-full py-6  mr-4 font-Inter text-4xl font-medium text-gray-500 flex-1">
        <div className="flex flex-row items-center justify-center">
          <span>{currency}&nbsp;</span>
          <span>{returnChange}</span>
        </div>
        <span className="block text-center text-gray-600 font-normal pt-1 text-sm">
          Return Change
        </span>
      </div>
      <div className="w-full border-t border-t-gray-100 mt-12 pt-12  flex-1 ">
        <SingleOrderAmount
          title="Cash Received"
          amount={cashReceived}
          style={{ marginBottom: "30px" }}
        />
        <SingleOrderAmount
          title="Total bill amount"
          amount={Number(orderTotal)?.toFixed(decimal_preference)}
          style={{ marginBottom: "20px" }}
          hideBackground={true}
        />
        <div className=" w-[90%] mx-auto flex-1 absolute bottom-10  right-0 left-0">
          <IconButton
            icon="tick-circle"
            iconPosition="left"
            text={`Confirm Order & Print Receipt`}
            // text={`Confirm ${cartState?.checkoutOrder?.type} Order & Print Receipt`}
            customClasses={classes.ConfirmAndPrintBtn}
            onClicked={sendOrder}
          />
          <IconButton
            icon="cross"
            iconPosition="left"
            text={`Cancel`}
            customClasses={classes.CancelBtn}
            onClicked={() => {
              resetAmount();
              toggleModal();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderChangeInterface;
