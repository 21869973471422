import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toggleOrderTypes } from "redux/createOrder";
import { saveOrderTaxes } from "redux/editingOrder";
import { addItemToCart, changeActiveCart } from "redux/cart";
import axiosWithLocationV2 from "config/axiosWithLocationV2";
import MainLoader from "components/Loader/MainLoader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import SingleOrderDetailItem from "./SingleOrderDetailItem";

const OrderDetailsItems = ({ clickedOrder }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const { orderTypes: cartTypes } = useSelector((state) => state.createOrder);
  // const { selectedOrders } = useSelector((state) => state.editOrder);
  const cartState = useSelector((state) => state.cart);

  // const onEditHandler = async () => {
  //   if (cartTypes.length === 0) {
  //     const orderTypes = selectedOrders.map((item) => item.type);
  //     for (const arr of orderTypes) {
  //       dispatch(toggleOrderTypes(arr));
  //     }
  //   }

  //   await dispatch(fetchServices());
  //   setIsLoading(false);
  // };
  const formatOrder = useCallback(async () => {
    dispatch(changeActiveCart(clickedOrder?.order_type));

    if (clickedOrder && clickedOrder?.uuid) {
      // Get all the dishes with the uuid
      const mainOrders = await axiosWithLocationV2?.post("/order/show", {
        uuid: clickedOrder.uuid,
      });
      if (!mainOrders) {
        setError("An error occurred ");
        setIsLoading(false);
      }
      const networkData = mainOrders.data.data.data;
      const mainOrderTypes = [clickedOrder?.order_type];
      for (const arr of mainOrderTypes) {
        dispatch(toggleOrderTypes(arr));
        dispatch(
          saveOrderTaxes({
            service: arr,
            value: networkData["order_tax"][arr],
          })
        );
        for (const orderItem of networkData[arr]) {
          const item = {
            category: { id: orderItem.category_id, name: "" },
            id: orderItem.item.id,
            name: orderItem.item.name,
            price: Number(orderItem.item.price),
            quantity: orderItem.quantity,
            order_id: orderItem?.order_id,
            uuid: clickedOrder?.uuid,
            sizes: orderItem?.item?.sizes,
            translations: orderItem?.item?.translations,
            vatvalue: orderItem?.item?.vat,
            order_vat: orderItem?.vat,
            total_discount: orderItem?.total_discount,
            discount_value: orderItem?.discount ?? 0,
            discount_type: orderItem?.discount_type ?? "percentage",
            notes:
              orderItem?.notes && orderItem?.notes === null
                ? ""
                : orderItem?.notes,
          };

          await dispatch(addItemToCart({ ...item, cartType: arr }));
        }
      }
      setIsLoading(false);
    }
  }, [clickedOrder, dispatch]);
  /**
   * @function this useEffect runs to set the necessary cart details
   */

  useEffect(() => {
    formatOrder();
    //   loadOrdersAndFormat();
  }, [formatOrder]);

  return (
    <div className="w-full h-full">
      {isLoading && !error && (
        <div className="w-full h-full flex flex-row items-center justify-center mt-10 ">
          <MainLoader />
        </div>
      )}
      {!isLoading && error && (
        <div className="w-full h-full flex flex-row items-center justify-center mt-10 ">
          <p className="text-3xl lg:text-4xl font-Inter font-medium text-red-300">
            {error}
          </p>
          <div className="mt-16 w-full flex flex-row items-center justify-center">
            <PrimaryButton
              text="Try Again"
              onClicked={() => {
                formatOrder();
              }}
            />
          </div>
        </div>
      )}
      {!isLoading && !error && (
        <div className="w-full mt-5">
          {cartState?.cart &&
            cartState?.cart[cartState?.activeCart]?.map((item) => (
              <SingleOrderDetailItem key={item?.item_id} {...item} />
            ))}
        </div>
      )}
    </div>
  );
};

export default OrderDetailsItems;
