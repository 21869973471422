import React from "react";
import OrderStatusSingleFilter from "./OrderStatusSingleFilter";
import OrderDateSingleFilter from "./OrderDateSingleFilter";
import OrderTimeSingleFilter from "./OrderTimeStatusFilter";

const PosApplyFilter = ({
  toggleShowFilter,
  setQueryObj,
  queryObj,
  applyFilter,
  toggleTimeChanged,
  clearFilter,
}) => {
  return (
    <div className="border border-gray-200 w-full rounded-full py-2 px-6 flex flex-row justify-between items-center">
      <div className="flex flex-row space-x-4 items-center">
        <div className="pr-2">
          <button onClick={toggleShowFilter}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M16.5 2.25H1.5L7.5 9.345V14.25L10.5 15.75V9.345L16.5 2.25Z"
                stroke="#4B5563"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        {/* Filters */}
        <div className="space-x-4 flex flex-row items-center">
          <OrderStatusSingleFilter
            setQueryObj={setQueryObj}
            queryObj={queryObj}
          />
          <OrderDateSingleFilter
            setQueryObj={setQueryObj}
            queryObj={queryObj}
          />
          <OrderTimeSingleFilter
            setQueryObj={setQueryObj}
            queryObj={queryObj}
            toggleTimeChanged={toggleTimeChanged}
          />
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <button
          onClick={() => {
            clearFilter();
            toggleShowFilter();
          }}
          className="px-[2rem] py-4 font-Inter text-textColor font-semibold flex flex-row items-center justify-center bg-[#F9FAFB] rounded-[10rem]"
        >
          Clear
        </button>
        <button
          onClick={() => {
            toggleShowFilter();
            applyFilter();
          }}
          className="px-[2rem] py-4 font-Inter text-white font-semibold flex flex-row items-center justify-center bg-[#222] rounded-[10rem]"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default PosApplyFilter;
