import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Icon from "components//Icon/Icon";
import { useTranslation } from "react-i18next";
import userImg from "assets//img/User-32.png";
import classes from "./Sidebar.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer, toggleDrawer } from "redux/drawer";
import { useWindowSize } from "hooks/useWindowSize";
// import { AuthContext } from "context/authContext";
// import useScrollBlock from "hooks/useScrollBlock";

const NEWLINKS = [
  { link: "/orders", text: "ORDERS", icon: "orders-bag" },
  { link: "/floor", text: "FLOOR", icon: "floor" },
  { link: "/queue", text: "QUEUE", icon: "queue" },
  { link: "/shift", text: "SHIFT", icon: "hour-glass" },
  { break: true },
  { link: "/settings", text: "SETTINGS", icon: "settings" },
];

const Sidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let size = useWindowSize();
  // const { loggedInUser } = useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { activeLocation } = useSelector((state) => state.location);

  let sidebarClasses = classes.Sidebar;

  if (!sidebarOpen) {
    sidebarClasses = [classes.Sidebar, classes.SidebarCollapsed].join(" ");
  }

  const toggleSidebar = () => {
    setSidebarOpen((oldState) => !oldState);
    dispatch(toggleDrawer());
  };

  useEffect(() => {
    if (size.width === 1024) {
      dispatch(closeDrawer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.width]);

  const cashierName = localStorage.getItem("cashier_name") || "Admin";

  return (
    <div className={sidebarClasses}>
      <button onClick={toggleSidebar} className={classes.SidebarToggleHead}>
        <Icon name="burger" width={2.4} height={2.4} />
        <span>{t("COLLAPSE_MENU")}</span>
      </button>
      <div className={classes.SidebarHeader}>
        <Icon name="building" width={2.4} height={2.4} />
        <label>{t("YOU_ARE_IN_FOH")}</label>
        <a
          href={process.env.REACT_APP_BOH_URL}
          target="_blank"
          rel="noreferrer"
        >
          {t("SWITCH_TO_BOH")}
        </a>
      </div>
      <hr className={classes.Breaker} />
      <nav>
        <ul>
          {NEWLINKS.map((link) => {
            if (link.break)
              return <hr key={Math.random()} className={classes.LinkBreaker} />;
            return (
              <li key={link.link} className={classes.SidebarNavItem}>
                <NavLink
                  to={{ pathname: link.link }}
                  className={(navData) => {
                    return navData.isActive
                      ? classes.SidebarNavLinkActive
                      : classes.SidebarNavLink;
                  }}
                >
                  <Icon name={link.icon} height={2.4} width={2.4} />
                  <span>{t(link.text)}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className={classes.UserBox}>
        <hr className={classes.Breaker} />
        <p className={classes.UserBoxLabel}>{t("LOGGED_IN_AS")}</p>
        <span className={classes.UserBoxStatus}>{cashierName}</span>
        {/* <span className={classes.UserBoxStatus}>{t("ADMIN")}</span> */}
        <div className={classes.User}>
          <img className={classes.UserImg} src={userImg} alt="User Img" />
          <label className={classes.UserName}>{activeLocation?.name}</label>
          <Icon name="chevron-up" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
