import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../config/axiosWithLocationV2";

const initialState = {
  mainDishes: [],
  error: null,
  isLoading: false,
  extra: null,
  categoryId: null,
};
// dashboard-report/top-popular-dishes-of-the-day?perPage=15&no_of_items=15&category_id=100
// dashboard-report/get-featured-items?perPage=15&no_of_items=15&category_id=100

export const fetchDashboardDishes = createAsyncThunk(
  "dish/fetchDashboardCategoryDishes",
  async (payload, thunkAPI) => {
    const response = await axios
      .get(
        `/dashboard-report/${payload.apiUrl}?page=${payload.page}&category_id=${
          payload.category_id
        }&perPage=${payload?.perPage ?? 15} &no_of_items=${12}`
      )
      .then((res) => {
        return {
          id: payload.category_id,
          data: res.data.data?.data,
          extra: res?.data?.data?.extra,
        };
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(
          err?.response?.data?.message ||
            "Something went wrong! Please try again later"
        );
      });
    return response;
  }
);

export const dashboardCategoryDishes = createSlice({
  name: "dashboardCategoryDishes",
  initialState,
  reducers: {
    clearCategoryDishes: (state) => {
      state.mainDishes = null;
    },
    clearCategoryDishError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardDishes.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchDashboardDishes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.mainDishes = action?.payload?.data;
      state.extra = action.payload?.extra;
      state.categoryId = action.payload?.id;
      state.error = null;
    });
    builder.addCase(fetchDashboardDishes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setDishes,
  updateDishStock,
  incrementDishUsedStock,
  decrementDishUsedStock,
  updateDishUsedStock,
  clearDishes,
  clearDishError,
} = dashboardCategoryDishes.actions;

export default dashboardCategoryDishes.reducer;
