import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { MdOutlineChevronLeft } from "react-icons/md";
import styled from "styled-components";

const MainDishSelectionDishes = ({
  fetchDishes,
  changePage,
  total_pages,
  page,
  addDishToCartHandler,
  changeCategoryView,
}) => {
  const dispatch = useDispatch();
  const { decimal_preference } = useGetActiveLocation();
  const {
    selectedCategory,
    selectedCategoryName,
    // categories,
    isLoading: categoriesLoading,
  } = useSelector((state) => state.category);
  const {
    dishes,
    isLoading: dishLoading,
    error,
  } = useSelector((state) => state.dish);

  console.log(dishes);

  return (
    <div className="w-full h-full ">
      <button
        className="flex flex-row w-full items-center mb-4"
        onClick={() => changeCategoryView("category-selection")}
      >
        <MdOutlineChevronLeft className="text-[2rem] text-gray-500" />
        <span className="text-[1.3rem] text-gray-500">Back To Categories</span>
      </button>
      {selectedCategory && selectedCategoryName && (
        <div className="w-full flex flex-row items-center justify-between">
          <p className="text-[1.6rem] text-textColor font-Inter font-semibold">
            {selectedCategoryName}
          </p>
        </div>
      )}

      {(dishLoading || categoriesLoading) && (
        <div className="w-full flex flex-row mt-10">
          <Skeleton
            count={5}
            width="100%"
            height={100}
            containerClassName="flex-1 flex flex-row w-full space-x-5"
            className="h-[8rem] flex-row flex w-full"
          />
        </div>
      )}
      {error && categoriesLoading && dishLoading && (
        <div className="w-full flex flex-row items-center justify-between">
          <p className="text-[1.6rem] text-red-500 ">
            An error occurred while loading dishes
          </p>
          <div className="mt-16 w-full flex flex-row items-center justify-center">
            <PrimaryButton
              text="Try Again"
              onClicked={() => {
                dispatch(fetchDishes());
              }}
            />
          </div>
        </div>
      )}
      {!dishLoading &&
        !categoriesLoading &&
        !error &&
        dishes &&
        dishes !== undefined &&
        dishes?.length === 0 && (
          <div className="w-full flex flex-col items-center justify-center mt-40">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
            >
              <path
                d="M15.801 11.7033C17.3601 11.7033 18.8954 12.3834 20.2066 13.6368C20.5526 13.9394 20.5768 14.4716 20.26 14.803C19.9414 15.1365 19.4098 15.1365 19.0934 14.8048C18.0232 13.7797 16.8989 13.3157 15.801 13.3157C14.7044 13.3157 13.5823 13.7794 12.5121 14.8048C12.3578 14.9566 12.1469 15.0402 11.93 15.0345C11.2079 15.016 10.8708 14.1296 11.401 13.6364C12.7101 12.3834 14.2436 11.7033 15.801 11.7033ZM19.1602 8.5214C19.8049 8.5214 20.329 9.04656 20.329 9.69153C20.329 10.3369 19.8045 10.8595 19.1602 10.8595C18.516 10.8595 17.9937 10.3369 17.9937 9.69153C17.9933 9.0462 18.5156 8.5214 19.1602 8.5214ZM12.4456 8.5214C13.0902 8.5214 13.6122 9.04656 13.6122 9.69153C13.6122 10.3369 13.0898 10.8595 12.4456 10.8595C11.7992 10.8595 11.2769 10.3369 11.2769 9.69153C11.2769 9.0462 11.7992 8.5214 12.4456 8.5214ZM1.63988 0.873047C0.0676176 0.917491 -0.182338 3.28905 1.3398 3.70256L3.32451 4.40336L5.65979 16.5794C5.93962 18.0364 6.40219 19.3552 7.04859 20.4898C7.99864 22.1605 9.64877 23.3936 11.684 23.3936H21.791C23.6154 23.4266 23.6154 20.5079 21.791 20.5417H11.6843C10.2454 20.3582 9.64877 19.6332 9.12113 18.5705H22.4623C23.6858 18.5705 24.3691 17.4839 24.6714 16.227L27.5894 6.29989C27.7451 4.66789 26.8804 4.34576 25.3672 4.34576L6.20131 4.36034L5.68611 2.78594C5.54708 2.31731 5.19153 1.95536 4.74566 1.82949L1.63988 0.873047ZM12.3734 24.8474C11.1791 24.8474 10.2102 25.817 10.2102 27.0114C10.2102 28.2071 11.1791 29.1767 12.3734 29.1767C13.5677 29.1767 14.5345 28.2071 14.5345 27.0114C14.5345 25.817 13.5677 24.8474 12.3734 24.8474ZM20.9914 24.8474C19.7988 24.8474 18.8303 25.817 18.8303 27.0114C18.8303 28.2071 19.7992 29.1767 20.9914 29.1767C22.1857 29.1767 23.1546 28.2071 23.1546 27.0114C23.1546 25.817 22.1857 24.8474 20.9914 24.8474Z"
                fill="#222222"
              />
            </svg>
            <p className="text-[1.6rem] text-textColor font-semibold font-Inter my-8">
              No dishes available for this category
            </p>
            <EmptyBtn onClick={() => changeCategoryView("category-selection")}>
              <span>Select Category Again</span>
            </EmptyBtn>
          </div>
        )}
      {!dishLoading &&
        !error &&
        dishes &&
        dishes !== undefined &&
        dishes?.length > 0 && (
          <div className="w-full mt-10 ">
            <div
              className="w-full grid md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5
           gap-5"
            >
              {dishes.map((dish) => (
                <button
                  className="w-full bg-white rounded-md border-b border-b-green-500 grid grid-rows-[1fr_auto] grid-cols-1 px-4 py-4 h-[12rem] max-h-[12rem] items-start"
                  key={dish?.id}
                  onClick={() => addDishToCartHandler(dish)}
                >
                  <div className="w-full flex flex-col flex-wrap ">
                    <p className="text-[1.4rem] font-medium break-words leading-6 text-left capitalize w-full">
                      {dish?.name}
                    </p>
                    <p className="text-[1.4rem] font-semibold truncate ... leading-6 w-full text-right pt-4">
                      {dish?.translations?.ar?.name}
                    </p>
                  </div>
                  <div className="w-full flex flex-row items-center justify-between">
                    <p className="text-gray-800 text-[1.4rem] font-semibold">
                      {Number(dish?.price).toFixed(decimal_preference)}
                    </p>

                    {dish && dish?.cal && (
                      <div className="bg-gray-100  px-4 py-1 rounded-3xl">
                        <p className="text-gray-800 font-medium">
                          {dish?.cal} {dish?.cal_unit ?? "kcl"}
                        </p>
                      </div>
                    )}
                  </div>
                </button>
              ))}
            </div>
            {/* Pagination */}
            <div className="w-full">
              {!dishLoading && dishes && dishes?.length >= 1 && (
                <div className="flex mt-10 pb-6">
                  <div className="mx-auto">
                    {total_pages && total_pages > 1 && (
                      <p className="text-sm text-gray-400 font-semibold font-Inter text-center  my-3">
                        Page {page} of&nbsp;
                        {total_pages}
                      </p>
                    )}
                    <Pagination
                      count={total_pages}
                      page={page}
                      onChange={(event, val) => {
                        changePage(val);
                      }}
                      renderItem={(item) => (
                        <PaginationItem
                          {...item}
                          shape="rounded"
                          size="small"
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
    </div>
  );
};

export default MainDishSelectionDishes;

const EmptyBtn = styled.button`
  border-radius: 2px;
  border: 1px solid #222;
  background: #222;
  display: flex;
  padding: 11px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
