import React from "react";
import moment from "moment";

const SinglePosOrderBody = ({ selectedOrderType, filterOrderType, item }) => {
  const {
    order_type,
    tender,
    created_at,
    invoice_number,
    client_name,
    outletTable,
    status,
    completed_at,
  } = item;

  return (
    <div className="w-full mt-3">
      {order_type !== "dine in" && (
        <div className="w-full">
          {status !== "complete" && status !== "cancel" && (
            <div className="w-full flex flex-row items-center justify-between">
              <h6 className="text-[2rem] text-gray-800 font-medium capitalize">
                {order_type}
              </h6>
              <div className=" flex flex-row items-center justify-center bg-[#F1F5FD] rounded-lg py-[0.8rem] px-[1.2rem]">
                <p className="text-gray-700 font-medium font-Inter">
                  {moment(created_at).startOf("seconds").fromNow()}
                </p>
              </div>
            </div>
          )}
          {(status === "complete" || status === "cancel") && (
            <div className="w-full flex flex-row items-center justify-between">
              {tender && tender?.name ? (
                <h6 className="text-[2rem] text-gray-800 font-medium capitalize">
                  {tender?.name}
                </h6>
              ) : (
                <h6 className="text-[2rem] text-gray-800 font-medium capitalize">
                  {order_type}
                </h6>
              )}
              <div className=" flex flex-row items-center justify-center bg-[#F1F5FD] rounded-lg py-[0.8rem] px-[1.2rem]">
                <p className="text-gray-700 font-medium font-Inter">
                  {moment(created_at).startOf("hour").fromNow(completed_at)}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      {/* Dine in */}
      {order_type === "dine in" && (
        <div className="w-full">
          {status !== "complete" && status !== "cancel" && (
            <div className="w-full">
              {" "}
              <div className="w-full flex flex-row items-center justify-between">
                <h6 className="text-[2rem] text-gray-800 font-medium capitalize">
                  {order_type}
                </h6>
                <div className=" flex flex-row items-center justify-center bg-[#F1F5FD] rounded-lg py-[0.8rem] px-[1.2rem]">
                  <p className="text-gray-700 font-medium font-Inter text-[1.4rem]">
                    {moment(created_at).startOf("seconds").fromNow()}
                  </p>
                </div>
              </div>
            </div>
          )}
          {(status === "complete" || status === "cancel") && (
            <div className="w-full flex flex-row items-center justify-between">
              <div className="">
                {client_name && client_name !== null && client_name !== "" && (
                  <div className="flex flex-row items-center space-x-2 mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M10.4724 8.47374C11.1259 7.95952 11.603 7.25436 11.8372 6.45637C12.0713 5.65838 12.051 4.80725 11.7789 4.02139C11.5068 3.23552 10.9965 2.554 10.3191 2.07165C9.64161 1.58929 8.83066 1.33008 7.99902 1.33008C7.16739 1.33008 6.35643 1.58929 5.67897 2.07165C5.00152 2.554 4.49125 3.23552 4.21916 4.02139C3.94707 4.80725 3.9267 5.65838 4.16086 6.45637C4.39503 7.25436 4.87209 7.95952 5.52569 8.47374C4.40574 8.92244 3.42855 9.66664 2.69828 10.627C1.96802 11.5874 1.51206 12.7279 1.37902 13.9271C1.36939 14.0146 1.3771 14.1032 1.4017 14.1878C1.42631 14.2723 1.46733 14.3512 1.52243 14.42C1.6337 14.5587 1.79554 14.6476 1.97235 14.6671C2.14917 14.6865 2.32646 14.6349 2.46524 14.5237C2.60401 14.4124 2.69291 14.2506 2.71235 14.0737C2.85874 12.7705 3.48015 11.5669 4.45783 10.6929C5.43552 9.81892 6.70095 9.33576 8.01236 9.33576C9.32376 9.33576 10.5892 9.81892 11.5669 10.6929C12.5446 11.5669 13.166 12.7705 13.3124 14.0737C13.3305 14.2376 13.4086 14.3888 13.5318 14.4984C13.6549 14.608 13.8142 14.6681 13.979 14.6671H14.0524C14.2271 14.647 14.3868 14.5586 14.4967 14.4212C14.6066 14.2839 14.6578 14.1087 14.639 13.9337C14.5054 12.7312 14.0469 11.5877 13.3129 10.6259C12.5789 9.66402 11.597 8.92006 10.4724 8.47374ZM7.99902 8.00041C7.4716 8.00041 6.95603 7.84401 6.5175 7.55099C6.07897 7.25798 5.73718 6.8415 5.53534 6.35423C5.33351 5.86696 5.2807 5.33078 5.38359 4.8135C5.48649 4.29622 5.74046 3.82106 6.1134 3.44812C6.48634 3.07518 6.9615 2.82121 7.47878 2.71831C7.99606 2.61542 8.53224 2.66823 9.01951 2.87006C9.50678 3.0719 9.92326 3.41369 10.2163 3.85222C10.5093 4.29075 10.6657 4.80633 10.6657 5.33374C10.6657 6.04099 10.3847 6.71926 9.88464 7.21936C9.38454 7.71946 8.70627 8.00041 7.99902 8.00041Z"
                        fill="#374151"
                      />
                    </svg>
                    <p className="text-[1.4rem] text-gray-700 font-Inter font-medium">
                      <span>{client_name}</span>
                    </p>
                  </div>
                )}
                {outletTable && outletTable?.no_of_seats && (
                  <p className="text-[1.4rem] text-gray-700 font-Inter font-medium">
                    <span>{outletTable?.no_of_seats}</span>
                  </p>
                )}
              </div>
              <div className="flex flex-row items-center justify-center bg-[#F1F5FD] rounded-lg py-[0.8rem] px-[1.2rem]">
                <p className="text-gray-700 font-medium font-Inter">
                  {moment(created_at).startOf("hour").fromNow(completed_at)}
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="w-full flex flex-row items-center justify-between mt-5">
        <p className="text-[1.4rem] text-gray-700 font-Inter font-medium">
          <span>{moment(created_at).format("ll")}</span>
        </p>
        <div>
          <p className="text-[1.4rem] text-gray-700 font-Inter font-medium text-right">
            <span className="text-right">#{invoice_number}</span>
            {order_type === "dine in" &&
              outletTable &&
              outletTable?.table_number && (
                <p className="text-right">{outletTable?.table_number}</p>
              )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SinglePosOrderBody;
