import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTable, unSelectSelectedTable } from "redux/createOrder";
import { cn } from "utillity/utils";
import { toast } from "react-toastify";

const ZoneTables = ({
  zoneDetailError,
  zoneDetailLoading,
  zoneTableDetail,
  getTable = () => {},
  zones,
  activeZone = {},
}) => {
  const dispatch = useDispatch();
  const { table } = useSelector((state) => state.createOrder);

  //   Calculating the total table length for the zone
  // const totalTable =
  //   vacant_table + reserved_table + occupied_table + disabled_table;
  let allActiveArray = [];
  // useMemo(() => {
  //   if (zoneTableDetail && zoneTableDetail?.name && !zoneDetailLoading) {
  //     allActiveArray = [
  //       ...zoneTableDetail?.vacant_table,
  //       ...zoneTableDetail?.occupied_table,
  //       ...zoneTableDetail?.reserved_table,
  //       ...zoneTableDetail?.disabled_table,
  //     ];
  //   }
  // }, [zoneTableDetail, zoneDetailLoading]);
  if (zoneTableDetail && zoneTableDetail?.name) {
    allActiveArray = [
      ...zoneTableDetail?.vacant_table,
      ...zoneTableDetail?.occupied_table,
      ...zoneTableDetail?.reserved_table,
      ...zoneTableDetail?.disabled_table,
    ];
  }

  const onSelectTableHandler = (tableId, tableName, status) => {
    if (status === "occupied") {
      toast.warning(`${tableName} table is currently occupied`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      if (table?.id === tableId) {
        dispatch(unSelectSelectedTable(-1));
        toast.success(`${tableName} has been unselected successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
      dispatch(setSelectedTable(tableId));
      toast.success(`${tableName} has been selected successfully`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <div className="w-full h-full px-[1rem] lg:px-[2rem] py-[1rem]">
      <div className="w-full ">
        {/* Loading view */}
        {zoneDetailLoading && !zoneDetailError && (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <Loader />
          </div>
        )}
        {/* Error view */}
        {!zoneDetailLoading && zoneDetailError && (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <p className="text-2xl lg:text-2xl font-Inter font-medium text-red-300 text-center">
              Error occurred while fetching your tables
            </p>
            <div className="mt-10 w-full flex flex-row items-center justify-center">
              <PrimaryButton
                text="Try Again"
                onClicked={() => {
                  getTable();
                }}
              />
            </div>
          </div>
        )}
        {/* Empty view */}
        {!zoneDetailError &&
          !zoneDetailLoading &&
          allActiveArray?.length === 0 && (
            <div className="w-full h-full flex flex-col items-center justify-center mt-40">
              <p className="pt-5 text-2xl font-Inter font-medium text-textColor">
                <span>No table available for this zone.</span>
              </p>
            </div>
          )}
        {/* Tables view */}
        {!zoneDetailError &&
          !zoneDetailLoading &&
          allActiveArray?.length > 0 && (
            <div className="w-full grid gap-4 lg:grid-cols-4 2xl:grid-cols-6 md:grid-cols-4">
              {allActiveArray?.map((item, index) => (
                <button
                  key={index}
                  onClick={() =>
                    onSelectTableHandler(
                      item?.id,
                      item?.table_number,
                      item?.status
                    )
                  }
                  className={cn(
                    "flex flex-col items-center bg-white justify-center px-[1rem] py-[2rem] xl:py-[3rem] rounded-[4px]",
                    [
                      item?.status === "available" &&
                        "border-b-[2px] border-b-[#29CC6A]",
                    ],
                    [
                      item?.status === "disabled" &&
                        "bg-transparent opacity-40",
                    ],
                    [
                      item?.status === "occupied" &&
                        "border-b-[2px] border-b-[#F3781F]",
                    ],
                    [table?.id === item?.id && "border-[1px] border-[#29CC6A]"]
                  )}
                >
                  <p className="text-[1.2rem] lg:text-[1.5rem] font-bold text-gray-700">
                    {item?.table_number}
                  </p>
                  <p className="pt-2 text-[12px] font-medium">
                    seats {item?.no_of_seats}
                  </p>
                </button>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

export default ZoneTables;
