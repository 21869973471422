import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import useToggle from "hooks/useToggle";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  // increaseItemInCartInput,
  updateItemDiscountInCart,
} from "redux/cart";
import { FaPercent } from "react-icons/fa";
import styled from "styled-components";
import { MdOutlineCheck } from "react-icons/md";
import { IoChevronDown } from "react-icons/io5";
import { cn } from "utillity/utils";
import { orderServiceDiscountType } from "utillity/productionData";

const SingleDishItem = ({
  item,
  dishView,
  discountClicked,
  changeClickedNote,
  openNoteView,
  // noteClicked,
  // index,
  // onRemove,
  // onReduceItemQuantity,
  // onIncreaseItemQuantity,
}) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const { currency, decimal_preference } = useGetActiveLocation();
  const { orderDiscountType } = useSelector((state) => state.createOrder);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const cartState = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  // const mainQuantity = useMemo(() => {
  //   if (Number(item?.quantity) === 0) {
  //     return 1;
  //   } else {
  //     return Number(item?.quantity);
  //   }
  // }, [item?.quantity]);

  // const returnedQuantity = (val) => {
  //   if (Number(val) === 0) {
  //     return 1;
  //   } else {
  //     return Number(val);
  //   }
  // };

  const itemTotal = () => {
    const tot = item?.quantity * item?.price;
    if (item?.discount_type === "fixed") {
      return Number(tot) - Number(item?.discount_value);
    } else {
      const discountTot = (Number(tot) * Number(item?.discount_value)) / 100;
      return Number(tot) - discountTot;
    }
  };

  return (
    <div className="w-full  full   rounded-md bg-white">
      <div className="w-full grid grid-cols-[1fr_auto] items-center border border-gray-200 px-4 xl:py-5 py-4">
        <button
          onClick={toggleOpen}
          className="flex flex-row items-center space-x-4"
        >
          {isOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke="#222222"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.5 15L12.5 10L7.5 5"
                stroke="#222222"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <div className="">
            <p className="text-[1.4rem] text-textColor font-normal break-words text-left">
              {item?.item_name}
            </p>
            {item.translations &&
              item.translations?.ar &&
              item.translations?.ar?.name && (
                <p className="text-[1.4rem] text-gray-500 italic font-normal break-words capitalize text-left pt-1">
                  {item.translations?.ar?.name}
                </p>
              )}
            {item?.sizes && item?.sizes.length > 0 && (
              <p className="text-[1.4rem] text-gray-500 italic font-normal break-words capitalize text-left pt-1">
                {item?.sizes[0]?.size}
              </p>
            )}
          </div>
        </button>
        {/* {orderDiscountType === "discount_by_item" && ( */}
        <div className="w-full flex flex-row items-center space-x-4">
          <div>
            {dishView === "discount" ? (
              <div>
                {orderDiscountType === "discount_by_item" && (
                  <button
                    onClick={discountClicked}
                    className="text-[1.2rem] text-textColor font-normal"
                  >
                    Discount
                  </button>
                )}
              </div>
            ) : (
              <button
                onClick={() => {
                  changeClickedNote(item);
                  openNoteView();
                  // noteClicked();
                }}
                className="text-[1.2rem] text-textColor font-normal"
              >
                Click to add note
              </button>
            )}
          </div>
          {dishView === "discount" &&
            orderDiscountType === "discount_by_item" && (
              <div className="h-14 w-[70px] rounded border border-gray-200 flex flex-row items-center relative">
                <InputContainer className="w-[50%] h-full pl-2 ">
                  <input
                    className="outline-none focus:outline-none bg-transparent w-full h-full text-xl font-Inter text-primary"
                    type="number"
                    id="item-discount"
                    value={item ? item?.discount_value : 0}
                    onChange={(e) => {
                      dispatch(
                        updateItemDiscountInCart({
                          type: cartState?.activeCart,
                          name: item?.item_name,
                          discount_value: Number(e.target.value),
                          discount_type: item?.discount_type,
                        })
                      );
                    }}
                    required={true}
                    // onFocus={onFocus}
                    // autoFocus={true}
                    min={0}
                  />
                </InputContainer>
                <div
                  onClick={() => setToggleDropdown(!toggleDropdown)}
                  className={cn(
                    "w-[40%] h-full flex flex-row items-center cursor-pointer space-x-2",
                    [
                      item &&
                        item?.discount_type !== "percentage" &&
                        "justify-end",
                    ]
                  )}
                >
                  {item && item?.discount_type === "percentage" && (
                    <FaPercent className="text-primary text-7xl block" />
                  )}

                  {/* <p className="text-2xl text-primary">%</p> */}
                  <IoChevronDown
                    className={cn("text-gray-800 text-[80px] block", [
                      item &&
                        item?.discount_type !== "percentage" &&
                        "text-[16px]",
                    ])}
                  />
                </div>
                {/* Dropdown container */}
                {toggleDropdown && (
                  <DiscountDropdownContainer className="absolute right-0  rounded-md h-[90px] w-[250px] z-[999]">
                    {orderServiceDiscountType.map((discount, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          dispatch(
                            updateItemDiscountInCart({
                              type: cartState?.activeCart,
                              name: item?.item_name,
                              discount_value: item?.discount_value,
                              discount_type: discount?.slug,
                            })
                          );
                          setToggleDropdown(!toggleDropdown);
                        }}
                        className={
                          item?.discount_type === discount?.slug
                            ? "w-full h-[45px] flex flex-row items-center justify-between bg-gray-100 hover:bg-gray-100 cursor-pointer px-4"
                            : "w-full h-[45px] flex flex-row items-center justify-between hover:bg-gray-100 cursor-pointer px-4"
                        }
                      >
                        <p className="text-2xl text-gray-600 font-Inter">
                          {discount?.title}
                        </p>
                        {item?.discount_type === discount?.slug && (
                          <MdOutlineCheck className="text-4xl text-primary" />
                        )}
                      </div>
                    ))}
                  </DiscountDropdownContainer>
                )}
              </div>
            )}
        </div>
        {/* )} */}
      </div>
      {isOpen && (
        <DropdownContainer className="w-full py-5 px-6">
          {/* Item price detail */}
          <div className="w-full flex flex-row items-center justify-between">
            <span className="text-gray-500 text-[1.2rem]">Item Price</span>
            <span className="text-gray-600 font-medium font-Inter text-[1.2rem]">
              {currency}&nbsp;{Number(item?.price).toFixed(decimal_preference)}
            </span>
          </div>
          {/* ITEM QUANTITY */}
          <div className="w-full flex flex-row items-center justify-between mt-2">
            <span className="text-gray-500 text-[1.2rem]">Item Quantity</span>
            <span className="text-gray-600 font-medium font-Inter text-[1.2rem]">
              {Number(item?.quantity)}
            </span>
          </div>
          {/* Discount value */}
          {item?.discount_value > 0 && (
            <div className="w-full flex flex-row items-center justify-between mt-2">
              <span className="text-gray-500 text-[1.2rem]">
                Discount ({item?.discount_type})
              </span>
              <span className="text-gray-600 font-medium font-Inter text-[1.2rem]">
                {item?.discount_type !== "percentage" && (
                  <span> {currency}&nbsp;</span>
                )}

                {Number(item?.discount_value)}
              </span>
            </div>
          )}
          {/* Note value */}
          {item?.notes && item?.notes !== "" && (
            <div className="w-full flex flex-row items-center justify-between mt-2">
              <span className="text-gray-500 text-[1.2rem]">Note</span>
              <span className="text-gray-600 font-medium font-Inter text-[1.2rem] text-right break-words w-[90%]">
                {/* {item?.notes} */}
                {item?.notes?.split(/\r?\n/).map((item, index) => (
                  <p
                    key={index}
                    className="text-[1.1rem] font-sans text-gray-600"
                  >
                    {item}
                  </p>
                ))}
              </span>
            </div>
          )}
          {/* Total */}
          <div className="w-full flex flex-row items-center justify-between mt-4 border-t border-t-gray-200 pt-2">
            <span className="text-gray-500 text-[1.2rem]">Total</span>
            <span className="text-gray-600 font-semibold font-Inter text-[1.2rem]">
              {currency}&nbsp;
              {itemTotal().toFixed(decimal_preference)}
            </span>
          </div>
        </DropdownContainer>
      )}
    </div>
  );
};

export default SingleDishItem;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

const DropdownContainer = styled.div`
  background: #fff;

  /* Shadow 3 */
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 5px 0px rgba(0, 0, 0, 0.06);
`;

const DiscountDropdownContainer = styled.div`
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  bottom: -90px;
`;

//  <div className="h-[4rem] w-[8rem] bg-gray-200 rounded grid grid-cols-[auto_1fr_auto] px-4">
//    <button
//      onClick={
//        item?.quantity === 1
//          ? () => onRemove(index - 1, item?.item_id, item?.cat_id)
//          : () => onReduceItemQuantity(index, item?.item_id, item?.cat_id)
//      }
//      className=" text-3xl block "
//    >
//      &#8722;
//    </button>
//    <InputContainer className=" h-full">
//      <input
//        type="number"
//        aria-label="quantity-input"
//        value={mainQuantity}
//        min="1"
//        onChange={(e) =>
//          dispatch(
//            increaseItemInCartInput({
//              itemIndex: index,
//              value: returnedQuantity(e.target.value),
//            })
//          )
//        }
//        className="w-full h-full focus:outline-none bg-transparent font-bold font-Inter text-[1.3rem] text-center flex flex-row items-center justify-center"
//      />
//    </InputContainer>
//    <button
//      onClick={() =>
//        onIncreaseItemQuantity(index, item?.item_id, item?.cat_id)
//      }
//      className="text-3xl block "
//    >
//      {" "}
//      &#43;
//    </button>
//  </div>
