import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import classes from "./Layout.module.scss";
import { useWindowSize } from "hooks//useWindowSize";
import Drawer from "./Drawer/Drawer";
import { useSelector } from "react-redux";
import ToggleDrawerButton from "components//ToggleDrawerButton/ToggleDrawerButton";
import noSupportImg from "assets//img/responsive-website-no-support.png";
import Icon from "components//Icon/Icon";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader/Loader";
import { useContext, useMemo } from "react";
import { AuthContext } from "context/authContext";
import { useHideNavigation } from "hooks/useHideNavigation";

const POSLayout = ({ children }) => {
  const { t } = useTranslation();
  let size = useWindowSize();
  const { isOpen, hideSideBar, hideHeader } = useSelector(
    (state) => state.drawer
  );
  const { logoutLoading } = useContext(AuthContext);
  useHideNavigation();

  const screenNotSupported = (
    <div className={classes.NoSupportScreen}>
      <ToggleDrawerButton />
      <br />
      <div className={classes.NoSupportScreenContent}>
        <div className={classes.NoSupportScreenContentImg}>
          <img src={noSupportImg} alt={t("VIEW_NOT_SUPPORTED")} />
          <Icon name="cross-with-circle" />
        </div>
        <h2>{t("VIEW_NOT_SUPPORTED")}</h2>
        <p>{t("VIEW_NOT_AVAILABLE")}</p>
      </div>
    </div>
  );

  const containerClasses = useMemo(() => {
    if (!isOpen) {
      if (hideSideBar) {
        return classes.hideSideBar_Container;
      }
      return classes.Container;
    } else if (isOpen) {
      if (hideSideBar) {
        return classes.hideSideBar_Collapsed;
      }
      return classes.Collapsed_Container;
    }
  }, [isOpen, hideSideBar]);

  const mainContainerClasses = useMemo(() => {
    if (!isOpen) {
      if (hideSideBar) {
        return classes.hideSidebar_Main;
      }
      return classes.Main;
    } else if (isOpen) {
      if (hideSideBar) {
        return classes.hide_Sidebar_Collapsed_Main;
      }
      return classes.Collapsed_Main;
    }
  }, [isOpen, hideSideBar]);

  if (logoutLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.POSLayout}>
      {!hideSideBar && (
        <div>
          {size.width > 850 && <Sidebar />}
          {size.width <= 850 && <Drawer show={isOpen} />}
        </div>
      )}
      {/* <div>
        {size.width > 850 && <Sidebar />}
        {size.width <= 850 && <Drawer show={isOpen} />}
      </div> */}

      <div isOpen={isOpen} className={containerClasses}>
        {!hideHeader && <Header isOpen={isOpen} hideSideBar={hideSideBar} />}

        <main className={mainContainerClasses}>
          {/* <div className="w-full h-full overflow-hidden"> */}
          {size.width >= 768 && children}
          {size.width < 768 && screenNotSupported}
          {/* </div> */}
        </main>
      </div>
    </div>
  );
};

export default POSLayout;
