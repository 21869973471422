import React, { useMemo } from "react";
import { orderTypeData } from "utillity/productionData";
import SingleDesktopOrderFilter from "./SingleDesktopOrderFilter";
import SingleAllOrdersFilterTab from "./SingleAllOrdersFilterTab";

const OrderServiceTypeFilter = ({
  changeOrderServiceType,
  filterOrderType,
  dropDownFilterData,
  deleteOrderService,
  addOrderServiceFilter,
  resetOrderStatusFilter,
}) => {
  const filteredData = useMemo(
    () => dropDownFilterData.filter((item) => item?.value !== "all-orders"),
    [dropDownFilterData]
  );

  return (
    <div className="w-full">
      <div className="flex flex-row items-center space-x-6">
        <SingleAllOrdersFilterTab
          label="All Orders"
          iconName="takeaway"
          value="all-orders"
          filterOrderType={filterOrderType?.value === "all-orders"}
          onClick={() => {
            changeOrderServiceType(orderTypeData[0]);
            resetOrderStatusFilter();
            localStorage.setItem(
              "activeServiceType",
              JSON.stringify(orderTypeData[0])
            );
          }}
          dropDownFilterData={dropDownFilterData}
          addOrderServiceFilter={addOrderServiceFilter}
        />
        <SingleDesktopOrderFilter
          iconName="delivery"
          hideIcon={true}
          label="Live Orders"
          value="live-orders"
          filterOrderType={filterOrderType?.value === "live-orders"}
          onClick={() => {
            localStorage.setItem(
              "activeServiceType",
              JSON.stringify(orderTypeData[orderTypeData.length - 1])
            );
            changeOrderServiceType(orderTypeData[orderTypeData.length - 1]);
          }}
        />
        {filteredData &&
          filteredData.length > 0 &&
          filteredData.map((item) => (
            <SingleDesktopOrderFilter
              {...item}
              key={item.value}
              item={item}
              filterOrderType={filterOrderType?.value === item?.value}
              onClick={() => {
                localStorage.setItem("activeServiceType", JSON.stringify(item));
                changeOrderServiceType(item);
              }}
              deleteItem={deleteOrderService}
            />
          ))}
        {/* {orderTypeData.map((item) => (
          <SingleDesktopOrderFilter
            {...item}
            key={item.label}
            filterOrderType={filterOrderType?.value === item?.value}
            onClick={() => changeOrderServiceType(item)}
          />
        ))} */}
      </div>
    </div>
  );
};

export default OrderServiceTypeFilter;
