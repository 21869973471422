import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosWithLocationV2 from "../config/axiosWithLocationV2";

const initialState = {
  services: [],
  translations: null,
  isLoading: true,
  error: null,
};

export const fetchServices = createAsyncThunk(
  "service/fetchServices",
  async (payload, thunkAPI) => {
    const response = await axiosWithLocationV2
      .get("/restaurant/services")
      .then((res) => {
        if (res.data.data.data.en) {
          return {
            services: Object.keys(res.data.data.data.en),
            translations: { ...res.data.data.data },
          };
        } else {
          return {
            services: [],
          };
        }
      })
      .catch((err) => {
        return thunkAPI.rejectWithValue(err?.response?.data?.message);
      });
    return response;
  }
);

export const servicesSlice = createSlice({
  name: "service",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      const { translations, services } = action.payload;

      state.isLoading = false;
      state.error = null;
      state.translations = translations;
      state.services = services;
    });
    builder.addCase(fetchServices.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setServices } = servicesSlice.actions;

export default servicesSlice.reducer;
