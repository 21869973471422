import Icon from "components/Icon/Icon";
import useClickOutside from "hooks/useOutsideClick";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { orderTypeData } from "utillity/productionData";
import { activeFilterServiceIcon } from "utillity/utils";
import SingleAllOrderDropdown from "./SingleAllOrderDropdown";

const SingleAllOrdersFilterTab = ({
  label,
  filterOrderType,
  onClick,
  iconName,
  dropDownFilterData,
  addOrderServiceFilter,
  //   value,
}) => {
  const [show, setShow] = useState(false);
  const dropDownRef = useRef(null);
  useClickOutside(dropDownRef, () => setShow(false));
  return (
    <DarkBtn
      filterOrderType={filterOrderType}
      className="flex flex-row items-center space-x-4 relative"
      ref={dropDownRef}
    >
      <button onClick={onClick} className="flex flex-row items-center ">
        <span className="pr-2">
          {filterOrderType ? (
            activeFilterServiceIcon(iconName)
          ) : (
            <Icon name={iconName} />
          )}
        </span>
        <p>{label}</p>
      </button>
      <button onClick={() => setShow(!show)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke={filterOrderType ? "#fff" : "#222"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {show && (
        <DropdownContainer className="absolute w-[238px] px-6 py-2 bg-white top-[110%] left-0">
          {orderTypeData.slice(0, orderTypeData.length - 1).map((item) => (
            <SingleAllOrderDropdown
              {...item}
              key={item.value}
              dropDownFilterData={dropDownFilterData}
              onClick={() => addOrderServiceFilter(item)}
            />
          ))}
        </DropdownContainer>
      )}
    </DarkBtn>
  );
};

export default SingleAllOrdersFilterTab;

const DarkBtn = styled.div`
  border-radius: 100px;
  border: 1px solid #222;
  background: ${(props) =>
    props.filterOrderType
      ? "linear-gradient(108deg, #222 46.55%, #F3781F 204.7%)"
      : "transparent"};
  padding: 1rem;
  p {
    color: ${(props) => (props.filterOrderType ? "#fff" : "#000")};
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 70% */
  }
`;

const DropdownContainer = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);

  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
`;
