import { useContext, useMemo, useRef, useState } from "react";
import Icon from "components/Icon/Icon";
import classes from "./Header.module.scss";
import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";
import LocationSwitcher from "./LocationSwitcher/LocationSwitcher";
import TimeBox from "./TimeBox/TimeBox";
import { AuthContext } from "context/authContext";
// import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import useClickOutside from "hooks/useOutsideClick";
import { ResturantService } from "services/ResturantService";
import MainLoader from "components/Loader/MainLoader";
import { useDispatch, useSelector } from "react-redux";
import { updateScreenState } from "redux/restaurant";
import { MdRefresh } from "react-icons/md";
import { toggleConfig } from "redux/app";
import { cn } from "utillity/utils";

const Header = ({
  customClasses,
  onExitPOS = () => {},
  isOpen,
  hideSideBar,
}) => {
  // const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();
  const { activeLocation } = useSelector((state) => state.location);
  const { refetchConfig } = useSelector((state) => state.app);
  const { selectedRestaurant } = useSelector((state) => state.restaurant);
  const dropDownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  useClickOutside(dropDownRef, () => setShowDropdown(!showDropdown));

  let stylClasses = [classes.Header, customClasses].join(" ");

  const lockPosScreen = () => {
    setLoading(true);
    ResturantService.lockScreen(
      {
        restaurant_id: activeLocation?.restaurant_id,
        outlet_id: activeLocation?.id,
        state: `${location?.pathname}`,
      },
      (res) => {
        setLoading(false);
        dispatch(updateScreenState(res?.data?.data));
        localStorage.setItem(
          "jalebi_pos_screen_lock_state",
          res?.data?.data?.is_locked
        );
        toast.success(res?.data?.message || "Screen Locked Successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setShowDropdown(false);
        navigate(location?.pathname);
        console.log(res, "Res");
        console.log(location.pathname, "Path");
      },
      (err) => {
        setLoading(false);
        toast.error(err?.response?.data?.message || "Unable to lock screen!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    );
  };

  const refreshSetting = () => {
    dispatch(toggleConfig(true));
  };
  const containerClasses = useMemo(() => {
    if (!isOpen) {
      if (hideSideBar) {
        return classes.hideSideBar_Container;
      }
      return stylClasses;
    } else if (isOpen) {
      if (hideSideBar) {
        return classes.hideSideBar_Collapsed;
      }
      return classes.Header_Collapsed;
    }
  }, [isOpen, hideSideBar, stylClasses]);

  return (
    // <header className={!isOpen ? stylClasses : classes.Header_Collapsed}>
    <header className={containerClasses}>
      <div
        className={cn("w-full flex flex-row items-center justify-between", [
          !isOpen && "md:w-full lg:w-[85%]",
        ])}
      >
        <LocationSwitcher />
        <TimeBox />
        {/* <div className={classes.Notifications}>
        <button className={classes.NotificationsToggler}>
          <Icon name="bell" width={2.4} height={2.4} />
          <span>3</span>
        </button>
      </div> */}
        <LanguageSwitcher />
        {selectedRestaurant &&
          selectedRestaurant?.roles &&
          selectedRestaurant.roles?.length > 0 && (
            <div className="flex flex-row items-center justify-center px-5 h-[5rem] bg-gray-100 rounded-md space-x-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M13.0904 10.5917C13.9074 9.94891 14.5038 9.06746 14.7965 8.06997C15.0892 7.07249 15.0637 6.00858 14.7236 5.02625C14.3835 4.04391 13.7457 3.19202 12.8988 2.58907C12.052 1.98612 11.0383 1.66211 9.99878 1.66211C8.95923 1.66211 7.94554 1.98612 7.09872 2.58907C6.2519 3.19202 5.61406 4.04391 5.27395 5.02625C4.93384 6.00858 4.90837 7.07249 5.20108 8.06997C5.49378 9.06746 6.09012 9.94891 6.90711 10.5917C5.50717 11.1526 4.28568 12.0828 3.37285 13.2833C2.46002 14.4838 1.89008 15.9094 1.72378 17.4084C1.71174 17.5178 1.72137 17.6285 1.75213 17.7342C1.78289 17.8399 1.83417 17.9386 1.90304 18.0245C2.04213 18.1979 2.24443 18.309 2.46544 18.3334C2.68646 18.3577 2.90808 18.2932 3.08155 18.1541C3.25502 18.015 3.36613 17.8127 3.39044 17.5917C3.57343 15.9627 4.35018 14.4582 5.57229 13.3657C6.7944 12.2732 8.37619 11.6692 10.0154 11.6692C11.6547 11.6692 13.2365 12.2732 14.4586 13.3657C15.6807 14.4582 16.4575 15.9627 16.6404 17.5917C16.6631 17.7965 16.7608 17.9856 16.9147 18.1225C17.0686 18.2595 17.2678 18.3346 17.4738 18.3334H17.5654C17.7839 18.3082 17.9835 18.1978 18.1209 18.0261C18.2583 17.8544 18.3222 17.6353 18.2988 17.4167C18.1317 15.9135 17.5587 14.4842 16.6412 13.2819C15.7236 12.0795 14.4962 11.1496 13.0904 10.5917ZM9.99878 10C9.33951 10 8.69504 9.80453 8.14688 9.43825C7.59871 9.07198 7.17147 8.55139 6.91918 7.9423C6.66689 7.33321 6.60087 6.66299 6.72949 6.01639C6.85811 5.36979 7.17558 4.77584 7.64175 4.30967C8.10793 3.84349 8.70187 3.52602 9.34848 3.39741C9.99508 3.26879 10.6653 3.3348 11.2744 3.58709C11.8835 3.83938 12.4041 4.26662 12.7703 4.81479C13.1366 5.36295 13.3321 6.00742 13.3321 6.66669C13.3321 7.55074 12.9809 8.39859 12.3558 9.02371C11.7307 9.64883 10.8828 10 9.99878 10Z"
                  fill="#4B5563"
                />
              </svg>
              <div>
                <span className="text-gray-600 font-Inter text-[1.4rem] capitalize">
                  User - {selectedRestaurant?.roles[0]?.split("-").join(" ")}
                </span>
              </div>
            </div>
          )}

        <button
          className={classes.LogoutBtn}
          onClick={() => {
            setShowDropdown(true);
          }}
          type="button"
        >
          <Icon name="logout" width={2.4} height={2.4} />
          {showDropdown && (
            <DropdownWrapper
              className="absolute  -bottom-40 right-10  w-[200px] bg-white"
              ref={dropDownRef}
            >
              <button
                className="flex flex-row items-center  h-[45px] px-5 w-full hover:bg-gray-200 transition-all duration-150 ease-in-out"
                onClick={lockPosScreen}
              >
                <Icon name="padlock" width={1.8} height={1.8} />
                <span className="text-gray-600 font-Inter text-lg px-4">
                  Lock Screen
                </span>
                {loading && <MainLoader size={5} />}
              </button>
              <button
                className="flex flex-row items-center  h-[45px] px-5 w-full hover:bg-gray-200 transition-all duration-150 ease-in-out"
                onClick={refreshSetting}
              >
                <MdRefresh className="text-3xl text-gray-600" />
                <span className="text-gray-600 font-Inter text-lg px-4">
                  Refresh Settings
                </span>
                {refetchConfig && <MainLoader size={5} />}
              </button>
              <button
                onClick={() => {
                  setShowDropdown(false);
                  logout();
                }}
                className="flex flex-row items-center  h-[45px] px-5 w-full hover:bg-gray-200 transition-all duration-150 ease-in-out"
              >
                <Icon name="power" width={1.8} height={1.8} />
                <span className="text-gray-600 font-Inter text-lg pl-4">
                  Logout
                </span>
              </button>
            </DropdownWrapper>
          )}

          {/* <span>{t("LOGOUT")}</span> */}
        </button>
      </div>
    </header>
  );
};

export default Header;

export const DropdownWrapper = styled.div`
  z-index: 1000;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 4px;
`;
