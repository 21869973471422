import { useState, useEffect } from "react";
import Icon from "components/Icon/Icon";
import classes from "./TimeBox.module.scss";
import moment from "../../../../../node_modules/moment/moment";

const TimeBox = () => {
  const [time, setTime] = useState(moment().format("hh:mm, DD MMMM yyyy"));

  useEffect(() => {
    let interval = setInterval(() => {
      // setTime(new Date().toISOString());
      setTime(moment().format("hh:mm A , ll"));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={classes.TimeBox}>
      <Icon name="clock" />
      <p className={classes.Time}>{time}</p>
    </div>
  );
};

export default TimeBox;
