import React from "react";
import DuplicateReceiptFooterLayout from "./DuplicateReceiptFooterLayout";

const DuplicateReceiptLayout = ({ children, handlePrint }) => {
  return (
    <div className="w-full h-full grid grid-rows-[1fr_auto] overflow-y-hidden overflow-x-hidden bg-white">
      {children}
      <DuplicateReceiptFooterLayout handlePrint={handlePrint} />
    </div>
  );
};

export default DuplicateReceiptLayout;
