import { STATUS } from "components/Status/Status";
import useClickOutside from "hooks/useOutsideClick";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ORDERTYPES } from "utillity/productionData";

const OrderStatusSingleFilter = ({ setQueryObj, queryObj }) => {
  const [show, setShow] = useState(false);
  const dropDownRef = useRef(null);
  useClickOutside(dropDownRef, () => setShow(false));

  return (
    <div className="relative" ref={dropDownRef}>
      <button
        onClick={() => setShow(!show)}
        className="flex flex-row items-center justify-center border border-gray-200 rounded-full px-[1.5rem] py-4 font-semibold space-x-2"
      >
        <p className="text-[1.4rem] capitalize">
          {queryObj?.status?.value ?? "status"}
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#222222"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {show && (
        <DropdownContainer className="absolute w-[238px]  py-2 bg-white top-[110%] left-0">
          {ORDERTYPES?.map((item) => (
            <button
              key={item.value}
              onClick={() => {
                setShow(false);
                setQueryObj({ ...queryObj, status: item });
              }}
              className="py-4 block transition-all duration-500 hover:bg-gray-100 w-full px-6"
            >
              <div className="flex flex-row items-center space-x-4">
                <span
                  className="w-[0.6rem] h-[0.6rem] rounded-full inline-block"
                  style={{ backgroundColor: STATUS[item.value].dotColor }}
                ></span>
                <span className="font-Inter font-medium text-[1.4rem]">
                  {STATUS[item.value].text}
                </span>
              </div>
            </button>
          ))}
        </DropdownContainer>
      )}
    </div>
  );
};

export default OrderStatusSingleFilter;

const DropdownContainer = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);

  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
`;
