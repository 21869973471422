import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { cn } from "utillity/utils";
import { fetchAllChannels } from "redux/channel";
// import { useDefaultSetting } from "hooks/useDefaultSetting";
// import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
// import { ImSpinner2 } from "react-icons/im";

const OutletChannelView = ({
  selectedChannel,
  changeChannelHandler,
  // defaultLoading,
  // chooseDefaultChannel,
  // makeDefaultChannel,
}) => {
  const { channels, error, isLoading } = useSelector((state) => state.channel);
  const dispatch = useDispatch();
  // const { default_channel } = useDefaultSetting();
  return (
    <div className="w-full mt-10">
      <div className="w-full flex flex-row items-center justify-between">
        <h5 className="text-gray-800 font-Inter font-semibold">Channels</h5>
        <h5 className="text-gray-800 font-Inter font-semibold">طرق الدفع</h5>
      </div>
      <div className="w-full ">
        {isLoading && !error && (
          <div className="w-full flex flex-row mt-10">
            <Skeleton
              count={5}
              width="100%"
              height={100}
              containerClassName="flex-1 flex flex-row w-full space-x-5"
              className="h-[8rem] flex-row flex w-full"
            />
          </div>
        )}
        {!isLoading && error && (
          <div className="w-full flex flex-col items-center justify-center mt-10">
            <p className="text-red-500 font-Inter text-2xl">{error}</p>
            <p
              onClick={() => dispatch(fetchAllChannels())}
              className="text-blue-500 font-semibold underline pt-8 cursor-pointer text-[1.4rem] font-Inter"
            >
              Try Again
            </p>
          </div>
        )}
        {!isLoading && channels && channels?.length === 0 && (
          <div className="w-full flex flex-row items-center justify-center mt-5">
            <p className="text-red-500 font-Inter text-[1.4rem]">
              No channels available now at the moment. Please contact Manager to
              add channel.
            </p>
          </div>
        )}
        {!isLoading && channels && channels?.length > 0 && (
          <div className="w-full grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mt-10">
            {channels.map((item) => (
              <button
                onClick={() => changeChannelHandler(item)}
                key={item?.id}
                className={cn(
                  "rounded bg-white flex flex-row items-center justify-center h-[10rem]",
                  [selectedChannel?.id === item?.id && "border border-gray-800"]
                )}
              >
                <span className="text-[1.2rem] text-gray-700 font-Inter font-semibold lg:text-[1.4rem] capitalize">
                  {item?.name}
                </span>
              </button>
            ))}
          </div>
        )}
      </div>
      {/* <div className="w-full">
        {(!default_channel || default_channel == null) &&
          !isLoading &&
          !error && (
            <div className="flex flex-row items-center">
              <CustomCheckbox
                name="channel"
                value="makeDefaultChannel"
                text="Set as default channel"
                isChecked={makeDefaultChannel}
                onChanged={chooseDefaultChannel}
              />
              {defaultLoading.channelLoading && (
                <ImSpinner2 className="animate-spin ml-2 text-primary" />
              )}
            </div>
          )}
      </div> */}
    </div>
  );
};

export default OutletChannelView;
