import React from "react";
import styles from "./Table.module.scss";

const TableHeader = ({ items }) => {
  return (
    <thead>
      <tr className={styles.table__header_row}>
        {items.map((item, index) => (
          <th className={styles.table__header_cell} key={index}>
            <p className="font-semibold">{item}</p>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
