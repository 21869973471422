import StatusIndicator from "components/common/statusIndicator/StatusIndicator";
import React from "react";
import moment from "moment";

const OrderDetailHeader = ({ clickedOrder }) => {
  return (
    <div className="w-full bg-white rounded-md px-5 py-4">
      {/* Status */}
      <div className="w-full flex flex-row items-center justify-between">
        {clickedOrder && clickedOrder?.order_type !== "dine in" && (
          <p className="text-[2rem] font-Inter font-semibold text-gray-700 capitalize">
            {clickedOrder && clickedOrder?.order_type}
          </p>
        )}
        {clickedOrder && clickedOrder?.order_type === "dine in" && (
          <div>
            {clickedOrder &&
            clickedOrder?.outletTable &&
            clickedOrder &&
            clickedOrder?.outletTable?.table_number ? (
              <p>
                <p className="text-[2rem] font-Inter font-semibold text-gray-700 capitalize">
                  {clickedOrder && clickedOrder?.outletTable?.table_number}
                </p>
              </p>
            ) : (
              <p>
                <p className="text-[2rem] font-Inter font-semibold text-gray-700 capitalize">
                  {clickedOrder && clickedOrder?.order_type}
                </p>
              </p>
            )}
          </div>
        )}
        <StatusIndicator status={clickedOrder && clickedOrder?.status} />
      </div>
      {/* Invoice */}
      <div className="w-full flex flex-row items-center justify-between mt-4">
        <p className="text-[1.4rem] text-gray-700 font-medium">
          #{clickedOrder && clickedOrder?.invoice_number}
        </p>
        <p className="text-[1.4rem] text-gray-700 font-Inter font-medium">
          <span>
            {moment(clickedOrder && clickedOrder?.created_at).format(
              "hh:mm:ss A"
            )}
          </span>
        </p>
      </div>
      {/* Date */}
      <div className="w-full mt-4">
        <p className="text-[1.4rem] text-gray-700 font-Inter font-medium">
          <span>
            {moment(clickedOrder && clickedOrder?.created_at).format("ll")}
          </span>
        </p>
      </div>
    </div>
  );
};

export default OrderDetailHeader;
