import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import React from "react";

const DuplicateReceiptOrderPriceDetails = ({
  getSubTotalPrice,
  getChannelPrice,
  getOrderTotalPrice,
  getTotalTax,
  taxEnabled,
  showChannel,
  showDiscount,
  discountPrice,
  showTenderTax,
  tenderFee,
  mainOrder,
}) => {
  const { currency, decimal_preference } = useGetActiveLocation();

  const totalWithoutChannelCommission =
    Number(getTotalTax()) + Number(getSubTotalPrice());

  return (
    <div className="w-full">
      {/* Single Detail */}
      <div className="w-full flex flex-row items-center justify-between mb-4">
        <p className="text-[1.4rem] text-textColor font-medium font-Inter">
          Subtotal
        </p>
        <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
          {currency} {getSubTotalPrice()}
        </p>
      </div>
      {/* Single Detail  Tax detail*/}
      {taxEnabled && (
        <div className="w-full flex flex-row items-center justify-between mb-2 border-b border-b-gray-200 pb-2">
          <p className="text-[1.4rem] text-textColor font-medium font-Inter">
            Tax
          </p>
          <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
            {currency} {Number(getTotalTax())}
          </p>
        </div>
      )}
      <div className="w-full border-b border-b-gray-200">
        {/* Single Detail */}
        <div className="w-full flex flex-row items-center justify-between mb-4">
          <p className="text-[1.4rem] text-textColor font-medium font-Inter">
            {taxEnabled ? (
              <span>Payable amount after tax</span>
            ) : (
              <span>Payable amount without tax</span>
            )}
          </p>
          <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
            {currency}{" "}
            {totalWithoutChannelCommission?.toFixed(decimal_preference)}
          </p>
        </div>
        {/* Single Detail */}
        {showChannel && (
          <div className="w-full flex flex-row items-center justify-between mb-4">
            <p className="text-[1.4rem] text-textColor font-medium font-Inter">
              {mainOrder &&
              mainOrder?.delivery_channel &&
              mainOrder?.delivery_channel?.commission_type === "percentage" ? (
                <span>Channel Commission (percentage)</span>
              ) : (
                <span>Channel Commission (fixed)</span>
              )}
            </p>
            <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
              {currency} {Number(getChannelPrice())}
            </p>
          </div>
        )}

        {/* Single Detail */}
        {showTenderTax && (
          <div className="w-full flex flex-row items-center justify-between mb-4">
            <p className="text-[1.4rem] text-textColor font-medium font-Inter">
              {mainOrder &&
              mainOrder?.tender &&
              mainOrder?.tender?.tax_type === "percentage" ? (
                <span>Tender Commission (percentage)</span>
              ) : (
                <span>Tender Commission (fixed)</span>
              )}
            </p>
            <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
              {currency} {tenderFee?.toFixed(decimal_preference)}
            </p>
          </div>
        )}
        {showDiscount && (
          <div className="w-full flex flex-row items-center justify-between mb-4">
            <p className="text-[1.4rem] text-textColor font-medium font-Inter">
              {mainOrder &&
              mainOrder?.discount_type &&
              mainOrder?.discount_type === "percentage" ? (
                <span>Discount (percentage)</span>
              ) : (
                <span>Discount (fixed)</span>
              )}
            </p>
            <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
              {currency} {discountPrice?.toFixed(decimal_preference)}
            </p>
          </div>
        )}
      </div>
      {/* Single Detail */}
      <div className="w-full flex flex-row items-center justify-between  pt-2">
        <p className="text-[1.4rem] text-textColor font-medium font-Inter">
          Total Payable
        </p>
        <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
          {currency} {getOrderTotalPrice()}
        </p>
      </div>
    </div>
  );
};

export default DuplicateReceiptOrderPriceDetails;
