import { useCartHooks } from "hooks/useCartHooks";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import { useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useGetTenderTaxHook } from "hooks/useGetTenderTaxHook";
import { AuthContext } from "context/authContext";

export const useReceipt = ({ selectedChannel, selectedTender }) => {
  const cartState = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const receiptRef = useRef();
  const { restaurantId, locationId, decimal_preference } =
    useGetActiveLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isPaid, setIsPaid] = useState(false);
  const [qrCode, setQrCode] = useState("");
  //   const editOrder = useSelector((state) => state.editOrder);
  const { token, manualLogout } = useContext(AuthContext);

  const {
    discountPriceReceipt,
    showDiscountReceipt,
    // discountTypeReceipt,
    // discountValue,
    vatReceipt,
    bohDiscount,
  } = useCartHooks();

  // Calculating the total cart price
  const getAllSubTotalPrice = () => {
    let price = 0;
    const allItems = cartState?.checkoutOrder?.orderItems;

    allItems?.forEach((el) => {
      price += +el?.price * el?.quantity;
    });

    return price.toFixed(decimal_preference);
  };

  //   Choose amount
  const chooseAmount = (val) => {
    setAmount(val);
  };
  // set paid
  const markAsPaid = () => {
    setIsPaid(true);
  };

  const getSubTotalPrice = () => {
    let subTotalPrice = 0;
    if (
      cartState &&
      cartState?.cart &&
      cartState?.cart[cartState?.activeCart]
    ) {
      for (const item of cartState?.cart[cartState?.activeCart]) {
        subTotalPrice += Number(item?.price) * Number(item?.quantity);
      }
    }

    return Number(subTotalPrice);
    // return numberFormat(subTotalPrice, decimal_preference);
  };

  const { showTenderTax, tenderFee } = useGetTenderTaxHook({
    tax: selectedTender?.tax,
    tax_type: selectedTender?.tax_type,
    order_total: getAllSubTotalPrice(),
  });
  // Price without any tax or commissions
  const totalCartPriceWithoutAnyCommission = getAllSubTotalPrice();
  //   Order price with only tax
  //   const totalWithoutChannelCommission =
  //     Number(totalCartPriceWithoutAnyCommission) +
  //     Number(editOrder.orderTaxes[cartState?.activeCart]);

  // Channel fee
  const getChannelFee = () => {
    if (selectedChannel && selectedChannel?.commission) {
      if (selectedChannel?.commission_type === "percentage") {
        return (
          (Number(selectedChannel?.commission) *
            Number(getAllSubTotalPrice())) /
          100
        );
      } else {
        return Number(selectedChannel?.commission);
      }
    }
    return 0;
  };
  //   const channelFee = getChannelFee();

  const showChannel =
    cartState?.checkoutOrder && cartState?.checkoutOrder?.type === "dine in"
      ? false
      : true;

  //   Order total price
  const getTotalPrice = () => {
    let totalPrice = 0;
    if (showChannel && showDiscountReceipt) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(vatReceipt);
      totalPrice += getChannelFee();
      totalPrice += tenderFee;
      totalPrice -= bohDiscount();
      // totalPrice -= discountPriceReceipt();
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
    } else if (!showChannel && showDiscountReceipt) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(vatReceipt);
      totalPrice += tenderFee;
      totalPrice -= bohDiscount();
      // totalPrice -= discountPriceReceipt();
      // return numberFormat(totalPrice, decimal_preference);
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
    } else if (showChannel && !showDiscountReceipt) {
      totalPrice += getSubTotalPrice();
      totalPrice += getChannelFee();
      totalPrice += Number(vatReceipt);
      totalPrice += tenderFee;
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
    } else if (!showChannel && !showDiscountReceipt) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(vatReceipt);
      totalPrice += tenderFee;
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
    } else {
      totalPrice += getSubTotalPrice();
      //   totalPrice += Number(activeTab?.order_price);
      totalPrice += Number(vatReceipt);
      totalPrice += tenderFee;
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
      // return numberFormat(totalPrice, decimal_preference);
    }
  };
  // Return change when customer is paying with cash
  const return_change = amount - getTotalPrice();

  // Complete order order: ;
  const handleCompleteOrder = () => {
    setIsLoading(true);
    setIsPaid(true);
    // handlePrient();
    const otherBody = {
      restaurant_id: restaurantId,
      location_id: locationId,
      status: "complete",
      order_no: cartState?.checkoutOrder?.order_no,
      // receipt_amount: getTotalPrice(),
      receipt_amount: getTotalPrice(),
      // payable_amount: getTotalPrice(),
      paid_amount: Number(amount),
      return_change: Number(Number(return_change).toFixed(decimal_preference)),
      delivery_channel_id:
        selectedChannel && selectedChannel?.id ? selectedChannel?.id : null,
      tender_id: selectedTender && selectedTender?.id,
    };
    const dineInBody = {
      restaurant_id: restaurantId,
      location_id: locationId,
      status: "complete",
      order_no: cartState?.checkoutOrder?.order_no,
      tender_id: selectedTender && selectedTender?.id,
      receipt_amount: getTotalPrice(),
      paid_amount: Number(amount),
      return_change: Number(Number(return_change).toFixed(decimal_preference)),
      // receipt_amount: amount,
    };
    const mainBody =
      cartState && cartState?.activeCart && cartState?.activeCart !== "dine in"
        ? otherBody
        : dineInBody;
    // const mainBody =
    //    selectedChannel && checkoutChannel?.id ? otherBody : dineInBody;
    axios
      .post("/order/status", mainBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          Location: locationId,
        },
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(
          res.data.message ||
            `${cartState?.checkoutOrder?.type} order ${cartState?.checkoutOrder?.order_no} is completed`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setQrCode(res?.data?.data?.qr_code);
        setTimeout(() => {
          handlePrient();
        }, 1000);

        setTimeout(() => {
          navigate("/orders/all");
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err?.response && err.response.status === 401) {
          manualLogout();
        } else {
          toast.error(
            err?.response?.data?.message ||
              "An error occurred while completing your order!",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      });
  };

  const handlePrient = useReactToPrint({
    content: () => receiptRef.current,
  });

  return {
    getAllSubTotalPrice,
    getSubTotalPrice,
    totalCartPriceWithoutAnyCommission,
    discountPriceReceipt,
    getTotalPrice,
    isLoading,
    isPaid,
    qrCode,
    chooseAmount,
    handleCompleteOrder,
    return_change,
    amount,
    showDiscountReceipt,
    showChannel,
    getChannelFee,
    receiptRef,
    showTenderTax,
    tenderFee,
    handlePrient,
    markAsPaid,
  };
};
