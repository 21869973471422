import React from "react";
import MainDishBottomBar from "./navigationBar/MainDishBottomBar";
import MainDishTopNav from "./navigationBar/MainDishTopNav";

const MainDishSelectionNavigationLayout = ({
  children,
  addServices,
  dishView,
  changeDishView,
  placeOrder,
  changeDishSelectionView,
  dishSelectionView,
}) => {
  return (
    <div className="w-full h-screen max-h-screen grid grid-rows-[auto_1fr_auto] overflow-hidden">
      <MainDishTopNav
        addServices={addServices}
        changeDishSelectionView={changeDishSelectionView}
        dishSelectionView={dishSelectionView}
      />
      {children}
      <MainDishBottomBar
        dishView={dishView}
        changeDishView={changeDishView}
        placeOrder={placeOrder}
      />
    </div>
  );
};

export default MainDishSelectionNavigationLayout;
