import React from "react";
import { IoAddSharp, IoRemove } from "react-icons/io5";

const PartySizeControl = ({ increaseSize, decreaseSize }) => {
  return (
    <div className=" flex flex-col items-end w-full lg:space-y-5 space-y-4">
      <button
        onClick={increaseSize}
        className="w-full flex flex-row items-center justify-center h-[45px] lg:h-[60px] border border-gray-300 rounded-[4px]"
      >
        <IoAddSharp className="text-[24px]" />
      </button>
      <button
        onClick={decreaseSize}
        className="w-full flex flex-row items-center justify-center  h-[45px] lg:h-[60px] border border-gray-300 rounded-[4px]"
      >
        <IoRemove className="text-[24px]" />
      </button>
    </div>
  );
};

export default PartySizeControl;
