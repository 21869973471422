import React from "react";

const AllocationStepIndicator = ({ active }) => {
  return (
    <div className="w-full flex flex-col">
      {/* Single */}
      <div className="flex flex-col items-center ">
        <div
          className={
            active === "party-size"
              ? "w-5 h-5 rounded-full border border-primary bg-gray-200 "
              : "w-5 h-5 rounded-full border border-#E5E7EB bg-[#E5E7EB] "
          }
        />
        <div className="xl:h-[14rem] h-[16rem] border-r-gray-400 border-r border-dashed my-2" />
      </div>
      {/* Single */}
      <div className="flex flex-col items-center ">
        <div
          className={
            active === "table-allocation"
              ? "w-5 h-5 rounded-full border border-primary bg-gray-200 "
              : "w-5 h-5 rounded-full border border-#E5E7EB bg-[#E5E7EB] "
          }
        />
        <div className="xl:h-[14rem] h-[16rem] border-r-gray-400 border-r border-dashed my-2" />
      </div>
      {/* Single */}
      <div className="flex flex-col items-center ">
        <div className="w-5 h-5 rounded-full border border-#E5E7EB bg-[#E5E7EB] " />
      </div>
    </div>
  );
};

export default AllocationStepIndicator;
