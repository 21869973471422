import React, { useEffect, useMemo, useState } from "react";
import CategoryNavigationLayout from "./components/CategoryNavigationLayout";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  setSelectedCategory,
  setSelectedCategoryName,
} from "redux/category";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";

const MainCategorySelection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, []);
  const { categories, isLoading, error, extra } = useSelector(
    (state) => state.category
  );
  // For pagination
  const [page, setPage] = useState(1);
  // const offset = (page - 1) * LIMIT;
  const total_pages = (extra && extra?.total_pages) || 1;

  useEffect(() => {
    dispatch(fetchCategories({ page }));
  }, [dispatch, page]);

  const goToDishes = (id, name) => {
    dispatch(setSelectedCategory(id));
    dispatch(setSelectedCategoryName(name));
    navigate("/orders/create/dish-selection");
  };

  return (
    <CategoryNavigationLayout>
      <div className="w-full h-full bg-gray-200 px-6 pt-8">
        <div className="w-full flex flex-row justify-between items-center">
          <h2 className="text-textColor font-Inter text-[1.6rem] font-semibold">
            {t("CATEGORIES")}
          </h2>
          <h2 className="text-textColor font-Inter text-[1.6rem] font-semibold">
            فئات
          </h2>
        </div>
        <div className="w-full mt-14">
          {isLoading && !error && (
            <div className="w-full flex flex-row mt-10">
              <Skeleton
                count={5}
                width="100%"
                height={100}
                containerClassName="flex-1 flex flex-row w-full space-x-5"
                className="h-[8rem] flex-row flex w-full"
              />
            </div>
          )}
          {!isLoading && error && (
            <div className="w-full  flex flex-col items-center justify-center mt-[4rem]">
              <p className="text-3xl lg:text-4xl font-Inter font-medium text-red-300">
                {error}
              </p>
              <div className="mt-16 w-full flex flex-row items-center justify-center">
                <PrimaryButton
                  text="Try Again"
                  onClicked={() => {
                    dispatch(fetchCategories({ page }));
                    // dispatch(fetchAllOrders(page));
                  }}
                />
              </div>
            </div>
          )}
          {!isLoading && !error && categories && categories.length === 0 && (
            <div className="mt-16 w-full flex flex-row items-center justify-center">
              <h2 className="text-[1.6rem] text-center font-Inter font-semibold text-textColor">
                No categories found.
              </h2>
            </div>
          )}
          {!isLoading && !error && categories && categories.length > 0 && (
            <div className="w-full">
              <div className="w-full grid grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-5 mt-10">
                {categories.map((category) => (
                  <button
                    className="w-full bg-white flex flex-col items-center justify-center rounded-md h-[10rem]"
                    key={category?.id}
                    onClick={() => {
                      goToDishes(category?.id, category?.name);
                    }}
                  >
                    <p className="text-textColor font-Inter font-medium">
                      {category?.name}
                    </p>
                    <p className="text-textColor font-Inter font-medium pt-2">
                      {category?.translations?.ar?.name}
                    </p>
                  </button>
                ))}
              </div>
              {/* Pagination */}
              <div className="w-full">
                {!isLoading && categories && categories?.length >= 1 && (
                  <div className="flex mt-10 pb-6">
                    <div className="mx-auto">
                      {total_pages && total_pages > 1 && (
                        <p className="text-sm text-gray-400 font-semibold font-Inter text-center  my-3">
                          Page {page} of&nbsp;
                          {total_pages}
                        </p>
                      )}
                      <Pagination
                        count={total_pages}
                        page={page}
                        onChange={(event, val) => {
                          setPage(val);
                        }}
                        renderItem={(item) => (
                          <PaginationItem
                            {...item}
                            shape="rounded"
                            size="small"
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </CategoryNavigationLayout>
  );
};

export default MainCategorySelection;
