import Modal from "components/Modal/Modal";
import React, { useMemo, useState } from "react";
import classes from "./OrderDiscount.module.scss";
// import { useCartHooks } from "hooks/useCartHooks";
import styled from "styled-components";
import { MdArrowDropDown, MdOutlineCheck } from "react-icons/md";
import { FaPercent } from "react-icons/fa";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";

const discountType = [
  { title: "Fixed", slug: "fixed" },
  { title: "Percentage", slug: "percentage" },
];

const OrderDiscountModal = ({
  showModal,
  toggleModal,
  onProceed = () => {},
  onFocus = () => {},
  // type = "text",
  label = "",
  // placeholder,
  value,
  onChange,
  // error = false,
  // errorText = "",
  disabled = true,
  discount_type,
  changeDiscountType = () => {},
  resetDiscount = () => {},
  activeItem,
}) => {
  const id = label + Math.random();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const cartState = useSelector((state) => state.cart);
  const { decimal_preference } = useGetActiveLocation();

  const activeItemPrice = useMemo(() => {
    const item = cartState?.cart[cartState?.activeCart]?.filter(
      (item) => item?.item_name === activeItem?.item_name
    )[0];
    return Number(item?.quantity) * Number(item?.price);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);
  const activeItemVat = useMemo(() => {
    if (activeItem) {
      const item = cartState?.cart[cartState?.activeCart]?.filter(
        (item) => item?.item_name === activeItem?.item_name
      )[0];
      return Number(item?.vatvalue)
        ? Number(item?.vatvalue) * Number(item?.quantity)
        : Number(item?.dishVat) * Number(item?.quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  const totalPayable = () => {
    let total = 0;
    if (!value || value === 0) {
      total = activeItemPrice + activeItemVat;
      return total;
    } else {
      if (discount_type === "fixed") {
        const discountedPrice = activeItemPrice - Number(value);
        total += discountedPrice;
        total += activeItemVat;
        return total;
      } else {
        const mainDiscount = (Number(value) * activeItemPrice) / 100;
        const mainPrice = activeItemPrice - mainDiscount;
        total += mainPrice;
        total += activeItemVat;
        return total;
      }
    }
  };
  const proceed = () => {
    if (!value) {
      toast.success("Discount applied successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        toastId: Math.floor(Math.random() * 990) + 10,
      });
      onProceed();
    } else if (value) {
      if (discount_type === "fixed") {
        if (Number(value) >= 1 && Number(value) <= activeItemPrice) {
          toast.success("Discount applied successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            toastId: Math.floor(Math.random() * 990) + 10,
          });
          onProceed();
        } else {
          toast.warning(
            `Discount value should be between 1 and ${activeItemPrice} for fixed discount`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: Math.floor(Math.random() * 990) + 10,
            }
          );
          resetDiscount();
        }
      } else {
        if (Number(value) >= 1 && Number(value) <= 100) {
          toast.success("Discount applied successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            toastId: Math.floor(Math.random() * 990) + 10,
          });
          onProceed();
        } else {
          toast.warning(
            `Discount value should be between 1 and 100 for percentage discount`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: Math.floor(Math.random() * 990) + 10,
            }
          );
          resetDiscount();
        }
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onClickOutside={toggleModal}
      customClasses={classes.CustomModal}
    >
      <div className={classes.ModalHeader}>
        <p>Discount</p>
        <p>تخفيض</p>
      </div>
      {/* Inputs */}
      <div className={classes.content}>
        {/* Sub Total */}
        <div className="flex flex-row items-center justify-between mb-8">
          <p className="font-Inter text-xl font-normal text-gray-600">
            Subtotal
          </p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {activeItemPrice.toFixed(decimal_preference)}
          </p>
        </div>
        {/* Tax */}
        <div className="flex flex-row items-center justify-between mb-8">
          <p className="font-Inter text-xl font-normal text-gray-600">Tax</p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {activeItemVat?.toFixed(decimal_preference)}
          </p>
        </div>
        {/* Payable amount */}
        <div className="flex flex-row items-center justify-between mb-10">
          <p className="font-Inter text-xl font-normal text-gray-600">
            Payable Amount
          </p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {Number(activeItemPrice + activeItemVat)?.toFixed(
              decimal_preference
            )}
          </p>
        </div>
        {/* Discount */}
        <div className="flex flex-row items-center justify-between mb-8">
          <p className="font-Inter text-xl font-normal text-primary">
            Discount
          </p>
          <div className="h-14 w-[70px] rounded border border-gray-200 flex flex-row items-center relative">
            <InputContainer className="w-[50%] h-full pl-2 ">
              <input
                className="outline-none focus:outline-none bg-transparent w-full h-full text-xl font-Inter text-primary"
                type="number"
                id={id}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                required={true}
                onFocus={onFocus}
                autoFocus={true}
              />
            </InputContainer>
            <div
              onClick={() => setToggleDropdown(!toggleDropdown)}
              className="w-[40%] h-full flex flex-row items-center cursor-pointer"
            >
              {discount_type === "percentage" && (
                <FaPercent className="text-primary text-7xl block" />
              )}

              {/* <p className="text-2xl text-primary">%</p> */}
              <MdArrowDropDown className="text-primary text-[120px] block" />
            </div>
            {/* Dropdown container */}
            {toggleDropdown && (
              <DropdownContainer className="absolute right-0  rounded-md h-[90px] w-[250px]">
                {discountType.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      changeDiscountType(item.slug);
                      setToggleDropdown(!toggleDropdown);
                    }}
                    className={
                      discount_type === item?.slug
                        ? "w-full h-[45px] flex flex-row items-center justify-between bg-gray-100 hover:bg-gray-100 cursor-pointer px-4"
                        : "w-full h-[45px] flex flex-row items-center justify-between hover:bg-gray-100 cursor-pointer px-4"
                    }
                  >
                    <p className="text-2xl text-gray-600 font-Inter">
                      {item?.title}
                    </p>
                    {discount_type === item.slug && (
                      <MdOutlineCheck className="text-4xl text-primary" />
                    )}
                  </div>
                ))}
              </DropdownContainer>
            )}
          </div>
        </div>
        {/* Divider */}
        <div className="w-full h-[0.2px] mb-8 bg-gray-300" />
        {/* Payable amount */}
        <div className="flex flex-row items-center justify-between mb-10">
          <p className="font-Inter text-xl font-normal text-gray-600">
            Total Payable
          </p>
          <p className="font-Inter text-xl font-semibold text-greyText">
            {totalPayable().toFixed(decimal_preference)}
          </p>
        </div>
        {/* Buttons */}
        <div className="w-full mt-20 flex flex-row  justify-end ">
          <div className="w-1/2 flex flex-row items-center justify-end justify-self-end">
            {/* Cancel btn */}
            <button
              tabIndex="0"
              onClick={toggleModal}
              className="py-4 w-[45%] flex-row items-center justify-center cursor-pointer"
            >
              <p className="text-[#1F2937] lg:text-xl text-base text-center">
                Cancel <span>يلغي</span>
              </p>
            </button>
            {/* Cancel Order btn */}
            <button
              tabIndex="0"
              onClick={disabled ? () => null : () => proceed()}
              className={
                disabled
                  ? "flex-row w-[45%] items-center justify-center py-4 bg-[#9BA3AF] rounded "
                  : "flex-row w-[45%] items-center justify-center py-4 bg-[#F3781F] rounded cursor-pointer"
              }
            >
              <p className="text-white lg:text-xl text-base text-center">
                Save <span>يحفظ</span>
              </p>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderDiscountModal;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

const DropdownContainer = styled.div`
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  bottom: -90px;
`;

// const totalPayable = () => {
//   let total = 0;
//   if (!value || value === 0) {
//     total = activeItemPrice + activeItemVat;
//     return total;
//   } else {
//     if (discount_type === "fixed") {
//       const discountedPrice = getActiveCartSubTotalPrice() - value;
//       total += discountedPrice;
//       total += getActiveCartTax();
//       return total;
//     } else {
//       const mainDiscount =
//         (Number(value) * getActiveCartSubTotalPrice()) / 100;
//       const mainPrice = getActiveCartSubTotalPrice() - mainDiscount;
//       total += mainPrice;
//       total += getActiveCartTax();
//       return total;
//     }
//   }
// };
