import React from "react";
// import SingleServices from "../components/SingleServices";
import { updateString } from "utillity/utils";
import MainSingleServices from "../components/MainSingleService";

const InstantTab = ({
  services = [],
  onClicked,
  orderTypes,
  previousLySelectedTypes = [],
  isAddingService,
  servicesTranslations,
  singleOrderSelection,
}) => {
  return (
    <div className="w-full">
      {services.map((item, index) => (
        <MainSingleServices
          {...item}
          key={index}
          icon={updateString(item, " ", "-")}
          engText={item}
          arabicText={servicesTranslations.ar[item]}
          onClick={onClicked}
          isSelected={orderTypes.includes(item)}
          isLocked={isAddingService && previousLySelectedTypes.includes(item)}
          singleOrderSelection={singleOrderSelection}
        />
      ))}
    </div>
  );
};

export default InstantTab;
