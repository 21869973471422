import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { defaultOrderTypeData } from "utillity/productionData";
import OrderStatusFilter from "./OrderStatusFilter";
import OrderServiceTypeFilter from "./OrderServiceTypeFilter";
import PosApplyFilter from "./PosApplyFilter";

const PosHeader = ({
  createNewOrder,
  changeOrderServiceType,
  filterOrderType,
  selectedOption,
  onChangeFilter,
  showFilter,
  toggleShowFilter,
  setQueryObj,
  queryObj,
  applyFilter,
  toggleTimeChanged,
  clearFilter,
  resetOrderStatusFilter,
  chosenQuery,
}) => {
  const [dropDownFilterData, setDropDownFilterData] = useState([]);

  const saveDropDownFilterData = useCallback(() => {
    const savedData = JSON.parse(localStorage.getItem("dropDownFilterData"));
    if (savedData) {
      setDropDownFilterData(savedData);
      return;
    }
    localStorage.setItem(
      "dropDownFilterData",
      JSON.stringify(defaultOrderTypeData)
    );
    setDropDownFilterData(defaultOrderTypeData);
  }, []);

  useEffect(() => {
    saveDropDownFilterData();
  }, [saveDropDownFilterData]);

  // Delete order service type from list and update local storage
  const deleteOrderService = (service) => {
    const filteredData = dropDownFilterData.filter(
      (item) => item.value !== service?.value
    );
    // Setting another active state if the active one is deleted
    if (filterOrderType?.value !== "all-orders") {
      changeOrderServiceType(filteredData[filteredData.length - 1]);
      localStorage.setItem(
        "activeServiceType",
        JSON.stringify(filteredData[filteredData.length - 1])
      );
    }
    localStorage.setItem("dropDownFilterData", JSON.stringify(filteredData));
    setDropDownFilterData(filteredData);
  };

  const addOrderServiceFilter = (service) => {
    if (service.value === "all-orders") return;
    const alreadySelected = dropDownFilterData.find(
      (item) => item.value === service?.value
    );
    const filteredData = dropDownFilterData.filter(
      (item) => item?.value !== service?.value
    );
    if (alreadySelected) {
      setDropDownFilterData(filteredData);
      localStorage.setItem("dropDownFilterData", JSON.stringify(filteredData));
      return;
    }
    const newData = [...filteredData, service];
    setDropDownFilterData(newData);
    localStorage.setItem("dropDownFilterData", JSON.stringify(newData));
  };

  return (
    <div className="w-full border-b pb-5 border-b-gray-300">
      <div className="w-full flex flex-row items-center justify-between">
        <h1 className="text-[2.5rem] lg:text-[3rem] font-medium text-textColor font-Inter">
          Your Orders
        </h1>
        <Btn>
          <PrimaryButton
            text="Create a New Order"
            onClicked={createNewOrder}
            // iconName={updateString("dine in", " ", "-")}
            iconName="white-dine-in"
            variant="iconBtn"
          />
        </Btn>
      </div>
      {/* Filter section */}
      <div className="w-full mt-10">
        {/* Desktop view */}
        {!showFilter && (
          <div className="w-full grid lg:grid-cols-[1fr_auto] grid-cols-1 lg:items-center lg:justify-between">
            <OrderServiceTypeFilter
              dropDownFilterData={dropDownFilterData}
              changeOrderServiceType={changeOrderServiceType}
              filterOrderType={filterOrderType}
              deleteOrderService={deleteOrderService}
              addOrderServiceFilter={addOrderServiceFilter}
              resetOrderStatusFilter={resetOrderStatusFilter}
            />

            <div className="w-full flex flex-row lg:justify-end justify-end lg:mt-0 mt-5">
              <div className=" flex flex-row justify-end   w-[8rem] ">
                <OrderStatusFilter
                  selectedOption={selectedOption}
                  onChangeFilter={onChangeFilter}
                  toggleShowFilter={toggleShowFilter}
                  chosenQuery={chosenQuery}
                />
              </div>
            </div>
          </div>
        )}
        {showFilter && (
          <PosApplyFilter
            toggleShowFilter={toggleShowFilter}
            onChangeFilter={onChangeFilter}
            setQueryObj={setQueryObj}
            queryObj={queryObj}
            applyFilter={applyFilter}
            toggleTimeChanged={toggleTimeChanged}
            clearFilter={clearFilter}
            selectedOption={selectedOption}
          />
        )}
      </div>
      {/* Status indicator */}
      <div className="w-full mt-10 flex flex-row items-center justify-between">
        <div></div>
        {/* <div className="flex flex-row items-center space-x-10">
          {ORDERTYPES.filter((item) => item.value !== "all").map((item) => (
            <div key={item.value}>
              <div className="flex flex-row items-center space-x-2">
                <span
                  className="w-[0.6rem] h-[0.6rem] rounded-full inline-block"
                  style={{ backgroundColor: STATUS[item.value].dotColor }}
                ></span>
                <span className="font-Inter font-medium text-[1.4rem]">
                  {STATUS[item.value].text}
                </span>
              </div>
            </div>
          ))}
        </div> */}
        <div className="">
          <p className="font-medium font-Inter text-[1.4rem] text-gray-700">
            Tap a card to see its details
          </p>
        </div>
      </div>
    </div>
  );
};

export default PosHeader;

const Btn = styled.div`
  svg {
    color: #fff;
  }
`;
