import styled from "styled-components";

export const DropdownContainer = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);

  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
`;
