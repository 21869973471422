import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateItemNoteInCart } from "redux/cart";

const MainDishNoteView = ({ clickedNoteItem, closeNoteView }) => {
  const cartState = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);
  const [note, setNote] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    toast.success("Item note saved successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    dispatch(
      updateItemNoteInCart({
        type: cartState?.activeCart,
        note: note,
        name: clickedNoteItem?.item_name,
      })
    );
    closeNoteView();
    setNote("");
  };
  useEffect(() => {
    setNote(clickedNoteItem?.notes);
  }, [clickedNoteItem?.notes]);

  return (
    <div className="w-full h-full pr-8">
      <div className="w-full flex flex-row items-center justify-between p-[2.5rem] bg-gray-200">
        <p className="text-gray-700 font-Inter font-semibold text-[1.6rem]">
          {clickedNoteItem && clickedNoteItem?.item_name}
        </p>
      </div>
      <div className="w-full mt-4">
        <form onSubmit={onSubmit}>
          <div
            className={
              focused
                ? "w-full h-[30rem] rounded-lg border border-primary"
                : "w-full h-[30rem] rounded-lg border border-gray-300"
            }
          >
            <textarea
              id="item-note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Write your note for the item"
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              autoFocus={true}
              className="w-full border-0 outline-none focus:outline-none bg-transparent h-full p-[1.6rem]"
              maxLength={400}
            />
          </div>
          {/* Text length */}
          <div className="w-full flex flex-row justify-end mt-5">
            {note.length} / 450
          </div>
          {/* Buttons */}
          <div className="w-full mt-20 flex flex-row  justify-end ">
            <div className="w-1/2 flex flex-row items-center justify-end justify-self-end">
              {/* Cancel btn */}
              <button
                tabIndex="0"
                onClick={closeNoteView}
                className="py-4 w-[45%] flex-row items-center justify-center cursor-pointer"
              >
                <span className="text-[#1F2937] lg:text-xl text-base text-center">
                  Cancel <span>يلغي</span>
                </span>
              </button>
              {/* Cancel Order btn */}
              <button
                tabIndex="0"
                type="submit"
                className="flex-row w-[45%] items-center justify-center py-4 bg-[#222] rounded cursor-pointer"
                // className={
                //   !note
                //     ? "flex-row w-[45%] items-center justify-center py-4 bg-[#9BA3AF] rounded "
                //     : "flex-row w-[45%] items-center justify-center py-4 bg-[#F3781F] rounded cursor-pointer"
                // }
              >
                <span className="text-white lg:text-xl text-base text-center">
                  Save <span>يحفظ</span>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MainDishNoteView;
