import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { skipPartySize, toggleOrderTypes } from "redux/createOrder";
import { changeActiveCart, toggleOrderTypesInCart } from "redux/cart";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { fetchServices } from "redux/service";
import Loader from "components/Loader/Loader";
import { servicesHeaderTabData } from "utillity/productionData";
import InstantTab from "./tabs/InstantTab";
// import DeferredTab from "./tabs/DeferredTab";
// import BulkTab from "./tabs/BulkTab";
import RoundedButton from "components/common/button/RoundedButton";
import { RemoveScroll } from "react-remove-scroll";
import { updateSelectedOrders } from "redux/editingOrder";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";
import { useDefaultSetting } from "hooks/useDefaultSetting";
// import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
// import { toast } from "react-toastify";
// import { ResturantService } from "services/ResturantService";
// import { toggleConfig } from "redux/app";
// import { ImSpinner2 } from "react-icons/im";

// const SERVICESWITHSEATS = ["dine in", "banquet", "catering"];

const OrderTypePage = () => {
  const { t } = useTranslation();
  const { orderTypes, isAddingService, partySize, table } = useSelector(
    (state) => state.createOrder
  );
  const [
    selectedServiceType,
    //  setSelectedServicesType
  ] = useState(servicesHeaderTabData[0]);
  // const [makeDefault, setMakeDefault] = useState(false);
  const { selectedLocation } = useSelector((state) => state.location);
  const {
    services,
    translations: servicesTranslations,
    isLoading,
    error,
  } = useSelector((state) => state.service);
  const { skip_customer_info } = useDefaultSetting();
  // const [loading, setLoading] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [previousLySelectedTypes] = useState(orderTypes);

  const orderTypeChangeHandler = (newOrderType) => {
    dispatch(changeActiveCart(newOrderType));
    dispatch(toggleOrderTypes(newOrderType));
    dispatch(toggleOrderTypesInCart(newOrderType));
    dispatch(
      updateSelectedOrders({
        type: newOrderType,
        status: "draft",
        order_no: "",
        id: Math.floor(Math.random() * 990) + 10,
        outlet_table_id: newOrderType === "dine in" ? 1 : null,
        orderItems: [],
        vatvalue: null,
        total: null,
        order_price: null,
        comment: "",
        discount_type: "percentage",
        discount_value: 0,
      })
    );
  };

  const startOrder = () => {
    updateOrder();
  };

  const singleOrderSelection = (orderType) => {
    if (!isAddingService) {
      dispatch(changeActiveCart(orderType));
      orderTypeChangeHandler(orderType);
      if (orderType === "dine in" && skip_customer_info) {
        dispatch(skipPartySize());
        return navigate("/orders/create/allocation-steps", {
          state: {
            step: "table-allocation",
          },
        });
      } else if (orderType === "dine in" && !skip_customer_info) {
        return navigate("/orders/create/allocation-steps", {
          state: {
            step: "party-size",
          },
        });
      } else {
        return navigate("/orders/create/dish-selection");
      }
    }
  };

  // Changing the tabs
  // const onChangeFilter = useCallback((newValue) => {
  //   setSelectedServicesType(newValue);
  // }, []);

  // const noServiceWithSeats = () => {
  //   return SERVICESWITHSEATS.every((service) => !orderTypes.includes(service));
  // };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const newOrderType = useMemo(() => [...orderTypes], []);

  const updateOrder = (seats) => {
    // dispatch(changeActiveCart(orderTypes[0]));
    if (!orderTypes.includes("dine in") && isAddingService)
      return navigate("/orders/create/dish-selection");
    if (
      !newOrderType.includes("dine in") &&
      orderTypes.includes("dine in") &&
      isAddingService &&
      !partySize.updated
    ) {
      if (skip_customer_info && table.id === -1) {
        dispatch(skipPartySize());
        return navigate("/orders/create/allocation-steps", {
          state: {
            step: "table-allocation",
          },
        });
        // return navigate("/orders/create/table-allocation");
      } else if (skip_customer_info && table.id !== -1) {
        dispatch(skipPartySize());
        return navigate("/orders/create/dish-selection");
      }
      return navigate("/orders/create/allocation-steps", {
        state: {
          step: "party-size",
        },
      });
      // return navigate("/orders/create/allocation-steps");
    }
    if (
      orderTypes.includes("dine in") &&
      !partySize.updated &&
      !isAddingService
    ) {
      if (skip_customer_info && table.id === -1) {
        dispatch(skipPartySize());
        return navigate("/orders/create/allocation-steps", {
          state: {
            step: "table-allocation",
          },
        });
        // return navigate("/orders/create/table-allocation");
      } else if (skip_customer_info && table.id !== -1) {
        dispatch(skipPartySize());
        return navigate("/orders/create/dish-selection");
      }
      return navigate("/orders/create/allocation-steps", {
        state: {
          step: "party-size",
        },
      });
      // return navigate("/orders/create/allocation-steps");
    }
    if (
      newOrderType.includes("dine in") &&
      orderTypes.includes("dine in") &&
      isAddingService
    ) {
      return navigate("/orders/create/dish-selection");
      // if (skip_customer_info && table.id === -1) {
      //   dispatch(skipPartySize());
      //   return navigate("/orders/create/allocation-steps", {
      //     state: {
      //       step: "table-allocation",
      //     },
      //   });
      // } else if (skip_customer_info && table.id !== -1) {
      //   dispatch(skipPartySize());
      //   return navigate("/orders/create/dish-selection");
      // }
      // return navigate("/orders/create/allocation-steps", {
      //   state: {
      //     step: "party-size",
      //   },
      // });
      // return navigate("/orders/create/allocation-steps");
    }

    if (!orderTypes.includes("dine in") && !isAddingService)
      return navigate("/orders/create/dish-selection");

    return navigate("/orders/create/allocation-steps", {
      state: {
        step: "party-size",
      },
    });
    // return navigate("/orders/create/dish-selection");
  };

  /* Fetching Restaurant services */
  useEffect(() => {
    if (selectedLocation) {
      dispatch(fetchServices());
    }
  }, [dispatch, selectedLocation]);

  // Default service type handler
  // const checkboxChangeHandler = () => {
  //   if (orderTypes && orderTypes.length > 1) {
  //     toast.error("You can only set one service type as default", {
  //       position: toast.POSITION.BOTTOM_RIGHT,
  //     });
  //   } else {
  //     setLoading(true);
  //     ResturantService.chooseDefaultServiceType(
  //       {
  //         type: "service_type",
  //         value: `${orderTypes[0]}`,
  //       },
  //       (res) => {
  //         setLoading(false);
  //         dispatch(toggleConfig(true));
  //         setMakeDefault(true);
  //         toast.success(
  //           res?.response?.data?.message ||
  //             `${orderTypes[0]} has been set as your default service type. Please proceed`,
  //           {
  //             position: toast.POSITION.BOTTOM_RIGHT,
  //           }
  //         );
  //       },
  //       (err) => {
  //         setLoading(false);
  //         toast.error(
  //           err?.response?.data?.message ||
  //             `Unable to set ${orderTypes[0]} as your default service type.`,
  //           {
  //             position: toast.POSITION.BOTTOM_RIGHT,
  //           }
  //         );
  //       }
  //     );
  //   }
  // };

  if (isLoading) {
    return (
      <RemoveScroll>
        <div className="h-[70vh]">
          <Loader />
        </div>
      </RemoveScroll>
    );
  } else if (!isLoading && !error && services?.length === 0) {
    return (
      <RemoveScroll>
        <div className="w-full flex flex-col  justify-center h-[80vh]">
          <p className="text-textColor font-Inter text-center text-2xl">
            No service available now at the moment. Please contact Manager to
            enable service.
          </p>
        </div>
      </RemoveScroll>
    );
  } else if (error && !isLoading) {
    return (
      <RemoveScroll>
        <div className=" flex flex-col  items-center justify-center h-[80vh]">
          <p className="text-red-400 font-Inter text-center text-2xl mb-10">
            {error}
          </p>
          <PrimaryButton
            // index={4}
            text="Try Again"
            customClasses="lg:w-[10%] w-[15%]"
            onClicked={() => dispatch(fetchServices())}
          />
        </div>
      </RemoveScroll>
    );
  }

  return (
    <GlobalPadding>
      <div className="w-full mt-10 h-screen">
        <div className="w-full flex flex-row items-center justify-between">
          <h2 className="font-Inter text-[2rem] text-textColor font-semibold">
            {t("CREATE_A_NEW_ORDER")}
          </h2>
        </div>
        <div className="w-full mt-10">
          <div className="w-full mt-16 flex flex-row items-center justify-between">
            {/* Tab type */}
            <div>
              <h6 className="font-Inter font-semibold text-2xl text-textColor capitalize">
                {selectedServiceType?.value} Orders
              </h6>
              <p className="text-placeholder font-Inter text-xl">
                You can choose any one or all order types
              </p>
            </div>
            <div>
              <h6 className="font-Inter font-semibold text-2xl text-textColor capitalize text-right">
                أوامر فورية
              </h6>
              <p className="text-placeholder font-Inter text-xl text-right">
                يمكنك اختيار أي واحد أو كل أنواع الطلبات
              </p>
            </div>
          </div>
        </div>
        {/* Content */}
        <div className="w-full mt-10 ">
          <InstantTab
            services={services}
            onClicked={orderTypeChangeHandler}
            orderTypes={orderTypes}
            isAddingService={isAddingService}
            previousLySelectedTypes={previousLySelectedTypes}
            servicesTranslations={servicesTranslations}
            singleOrderSelection={singleOrderSelection}
          />
        </div>
        {/* Footer */}
        {selectedServiceType?.value === "instant" && orderTypes.length >= 1 && (
          <div
            className={`w-full flex flex-row items-center justify-end  mt-14 
        
            `}
          >
            {/* {(!default_service_type || default_service_type == null) && (
              <div className="flex flex-row items-center">
                <CustomCheckbox
                  name="serviceType"
                  value="makeDefault"
                  text="Set as default service type"
                  isChecked={makeDefault}
                  onChanged={checkboxChangeHandler}
                />
                {loading && (
                  <ImSpinner2 className="animate-spin ml-2 text-primary" />
                )}
              </div>
            )} */}
            <div className="flex flex-row items-center">
              {/* {orderTypes.length === 1 && (
                <p className="mr-10 text-2xl font-Inter font-medium text-greyText">
                  {orderTypes?.length} service selected
                 
                </p>
              )} */}
              {orderTypes?.length > 1 && (
                <p className="mr-10 text-2xl font-Inter font-medium text-greyText">
                  Multiple services selected
                </p>
              )}
              {/* <RoundedButton
                enText="Clear"
                arText=" صافي"
                secondaryBtn={true}
                onClick={() => navigate("/orders/all")}
              />

              <div className="mx-2" /> */}
              <RoundedButton
                enText="Proceed"
                arText="تقدم"
                onClick={startOrder}
                dark
              />
            </div>
          </div>
        )}
      </div>
    </GlobalPadding>
  );
};

export default OrderTypePage;
