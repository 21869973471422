import ErrorImg from "assets/img/errorOne.webp";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Loader from "components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "context/authContext";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
// import classes from "./AllOrdersPage.module.scss";
import { useNavigate } from "react-router-dom";
import useToggle from "hooks/useToggle";
import { clearCheckoutChannel } from "redux/channel";
import {
  clearOrder,
  setSelectedTable,
  setTableZone,
  switchToCartEditing,
  // toggleOrderTypes,
} from "redux/createOrder";
import {
  changeActiveCart,
  // changeActiveCart,
  clearCart,
  toggleCheckoutOrder,
  // toggleOrderTypesInCart,
} from "redux/cart";
import {
  addEditingOrder,
  addSelectedOrder,
  clearEditingOrder,
  removeSelectedOrders,
} from "redux/editingOrder";
// import { fetchServices } from "redux/service";
import { clearCategoryError } from "redux/category";
import { clearDishError } from "redux/dish";
import { orderTypeData, ORDERTYPES } from "utillity/productionData";
import styled from "styled-components";
// import NoOrderScreen from "./components/NoOrderScreen";
import axiosWithLocationV2 from "config/axiosWithLocationV2";
import useEventListener from "hooks/useEventListener";
import MainLoader from "components/Loader/MainLoader";
import { toast } from "react-toastify";
// import { RemoveScroll } from "react-remove-scroll";
import { clearCheckoutTender } from "redux/tenders";
import { ORDER_CREATE_PERMISSION } from "constants/constants";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import { useDefaultSetting } from "hooks/useDefaultSetting";
import PosHeader from "./components/posRevamp/PosHeader";
import SinglePosOrder from "./components/posRevamp/SinglePosOrder";
import GeneralSideModal from "components/common/sideModal/GeneralSideModal";
import OrderDetailModalView from "components/orderDetail/OrderDetailModalView";
import { addSelectedZone } from "redux/zone";

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const defaultServiceType = JSON.parse(
    localStorage.getItem("activeServiceType")
  )
    ? JSON.parse(localStorage.getItem("activeServiceType"))
    : orderTypeData[0];
  const { token, manualLogout } = useContext(AuthContext);
  const { selectedLocation } = useSelector((state) => state.location);
  const { order } = useSelector((state) => state.editOrder);
  const [mainOrders, setMainOrders] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState(ORDERTYPES[0]);
  const cartState = useSelector((state) => state.cart);
  const [filterOrderType, setFilterOrderType] = useState(defaultServiceType);
  const [showDrawer, toggleDrawer] = useToggle(false);
  // const [showDeliverectDrawer, toggleDeliverectDrawer] = useToggle(false);
  // const [editingOrder, setEditingOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [moreDataLoading, setMoreDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [clickedOrder, setClickedOrder] = useState(null);
  const { paymentChannelDestinationHelper } = useDefaultSetting();
  const [showFilter, setShowFilter] = useState(false);
  const [timeChanged, setTimeChanged] = useState(false);
  const [perPage, setPerPage] = useState(15);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // The query object to update
  const [queryObj, setQueryObj] = useState({
    status: ORDERTYPES[0],
    date: null,
    startTime: null,
    endTime: null,
  });
  // Storing without afftect the current queries
  const [storeQueryObj, setStoreQueryObj] = useState({
    status: selectedOrderType,
    date: null,
    startTime: null,
    endTime: null,
  });

  // To determine if the logged in user can create a new order
  const { canView: canCreateOrder } = usePermissionHooks(
    ORDER_CREATE_PERMISSION
  );

  //Reset the filter status to all status when the All orders filter is clicked
  const resetAllStatus = () => {
    setQueryObj({
      ...queryObj,
      status: ORDERTYPES[ORDERTYPES.length - 1],
    });
    setStoreQueryObj({
      ...queryObj,
      status: ORDERTYPES[ORDERTYPES.length - 1],
    });
  };

  // console.log(queryObj, "ALRIGHT");

  // Check for default setting
  const { default_service_type, chooseOrderServiceType, skip_customer_info } =
    useDefaultSetting();

  // Apply filter
  const applyFilter = () => {
    if (timeChanged) {
      setQueryObj(storeQueryObj);
      setPage(1);
    } else {
      setPage(1);
      setQueryObj({
        startTime: null,
        endTime: null,
        status: storeQueryObj?.status,
        date: storeQueryObj?.date,
      });
    }
  };

  // toggle time changed
  const toggleTimeChanged = (val) => {
    setTimeChanged(val);
  };

  // Toggle show filter
  const toggleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  // Change order type
  const changeOrderServiceType = (val) => {
    setPage(1);
    setFilterOrderType(val);
  };
  // Clear filter
  const clearFilter = () => {
    toggleTimeChanged(false);
    setQueryObj({
      status: ORDERTYPES[0],
      date: null,
      startTime: null,
      endTime: null,
    });
    setStoreQueryObj({
      status: ORDERTYPES[0],
      date: null,
      startTime: null,
      endTime: null,
    });
  };

  // console.log(
  //   window.innerHeight + document.documentElement.scrollTop + 1,
  //   document.documentElement.scrollHeight,
  //   paginationDetails
  // );

  const handleScroll = () => {
    if (
      // window.innerHeight + document.documentElement.scrollTop ===
      // document.documentElement.offsetHeight
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (
        paginationDetails &&
        paginationDetails?.next_page &&
        paginationDetails?.current_page <= paginationDetails?.total_pages
      ) {
        setPage(paginationDetails?.next_page);
        setMoreDataLoading(true);
        setIsLoading(false);
      }
    }
  };
  useEventListener("scroll", handleScroll, window);

  useMemo(() => {
    if (screenWidth > 1500) {
      setPerPage(50);
    }
  }, [screenWidth]);

  const screenWidthChange = () => {
    setScreenWidth(window.innerWidth);
  };

  useEventListener("resize", screenWidthChange, window);

  useMemo(() => {
    if (showDrawer) {
      window.scrollTo({ top: 0 });
    }
  }, [showDrawer]);

  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //     clearCookies();
  //   }
  // }, [token]);

  const onChangeFilter = useCallback((newValue) => {
    setSelectedOrderType(newValue);
  }, []);

  const showOrder = (order) => {
    // Setting the table for the order if dine in
    if (order && order?.order_type === "dine in") {
      if (order?.outlet_table_id) {
        dispatch(
          setSelectedTable(order?.outlet_table_id ?? order?.outletTable?.id)
        );
      }
      if (order?.zone_id) {
        dispatch(setTableZone(order?.zone_id));
        dispatch(addSelectedZone(order?.zone_id));
      }
    }
    toggleDrawer();
    dispatch(addEditingOrder(order));
    // setEditingOrder(order);
    setClickedOrder(order);
  };

  // Function that is called when the create order button is clicked
  const createNewOrder = () => {
    if (canCreateOrder) {
      clearAll();
      if (
        !default_service_type ||
        default_service_type === null ||
        default_service_type === ""
      ) {
        navigate("/orders/create/order-type");
      } else {
        chooseOrderServiceType(default_service_type);
        dispatch(changeActiveCart(default_service_type));
        if (default_service_type !== "dine in") {
          navigate("/orders/create/category-selection");
        } else {
          if (skip_customer_info) {
            navigate("/orders/create/table-allocation");
          } else {
            navigate("/orders/create/allocation-steps");
          }
        }
      }
    } else {
      toast.warning("You don not have permission to perform this action", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const liveOrderUrl = `/live-orders?page=${page}`;

  const orderApiUrl = useMemo(() => {
    if (
      filterOrderType?.value !== "all-orders" &&
      filterOrderType?.value !== "live-orders"
    ) {
      let mainQueryUrl = `/orders?page=${page}&order_type=${filterOrderType?.value}&perPage=${perPage}`;
      if (
        queryObj &&
        queryObj.status &&
        queryObj.status.value !== "all" &&
        queryObj.status.value !== "on-hold"
      ) {
        if (queryObj && queryObj?.date !== null && queryObj?.date) {
          mainQueryUrl += `&date=${queryObj?.date}`;
        }
        if (queryObj?.startTime || queryObj?.endTime) {
          mainQueryUrl += `&startTime=${queryObj?.startTime}&endTime=${queryObj?.endTime}`;
        }

        mainQueryUrl += `&status=${queryObj.status.value}`;
      } else {
        if (queryObj && queryObj?.date !== null && queryObj?.date) {
          mainQueryUrl += `&date=${queryObj?.date}`;
        }
        if (queryObj?.startTime || queryObj?.endTime) {
          mainQueryUrl += `&startTime=${queryObj?.startTime}&endTime=${queryObj?.endTime}`;
        }
      }
      return mainQueryUrl;
    } else {
      let mainQueryUrl = `/orders?page=${page}&perPage=${perPage}`;

      if (filterOrderType?.value === "live-orders") {
        mainQueryUrl += `&order_type=${filterOrderType?.value}`;
      }
      if (queryObj && queryObj?.date !== null && queryObj?.date) {
        mainQueryUrl += `&date=${queryObj?.date}`;
      }
      if (queryObj?.startTime || queryObj?.endTime) {
        mainQueryUrl += `&startTime=${queryObj?.startTime}&endTime=${queryObj?.endTime}`;
      }
      if (
        filterOrderType?.value !== "live-orders" &&
        queryObj?.status?.value !== "all" &&
        queryObj.status.value !== "on-hold"
      ) {
        mainQueryUrl += `&status=${queryObj.status.value}`;
      }
      return mainQueryUrl;
    }
  }, [filterOrderType?.value, page, queryObj, perPage]);

  // Fetching orders
  const fetchOrders = useCallback(() => {
    if (
      token &&
      selectedLocation !== null &&
      // selectedOrderType.value !== "live-orders"
      filterOrderType?.value !== "live-orders"
    ) {
      setIsLoading(true);
      setError("");
      axiosWithLocationV2
        .get(orderApiUrl)
        .then((res) => {
          if (page === 1) {
            setMainOrders([]);
          }
          setMainOrders((prev) => [...prev, ...res?.data?.data?.data]);
          setPaginationDetails(res?.data?.data?.extra);
          setMoreDataLoading(false);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err?.response && err.response?.status === 401) {
            toast.error(
              err?.response?.data?.message ||
                "Session expired.Please login again",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            manualLogout();
          }
          setMoreDataLoading(false);
          setIsLoading(false);
          setError(
            err?.response?.data?.message ||
              "Error fetching your orders. Please try again later."
          );
        });
    } else if (
      token &&
      selectedLocation !== null &&
      filterOrderType?.value === "live-orders"
    ) {
      setIsLoading(true);
      setError("");
      axiosWithLocationV2
        .get(liveOrderUrl)
        .then((res) => {
          // console.log(res, "Live Orders");
          if (page === 1) {
            setMainOrders([]);
          }
          setMainOrders((prev) => [...prev, ...res?.data?.data?.data]);
          setPaginationDetails(res?.data?.data?.extra);
          setMoreDataLoading(false);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err?.response && err.response?.status === 401) {
            toast.error(
              err?.response?.data?.message ||
                "Session expired.Please login again",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            manualLogout();
          }
          setMoreDataLoading(false);
          setIsLoading(false);
          setError(
            err?.response?.data?.message ||
              "Error fetching your orders. Please try again later."
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    selectedLocation,
    page,
    selectedOrderType.value,
    liveOrderUrl,
    manualLogout,
    orderApiUrl,
    filterOrderType?.value,
  ]);

  useEffect(() => {
    fetchOrders();
    dispatch(removeSelectedOrders());
  }, [fetchOrders, dispatch]);

  const clearAll = useCallback(() => {
    dispatch(clearCart());
    dispatch(clearOrder());
    dispatch(clearCategoryError());
    dispatch(clearDishError());
    dispatch(clearCheckoutChannel());
    dispatch(clearCheckoutTender());
    dispatch(clearEditingOrder());
    // dispatch(removeSelectedOrders());
  }, [dispatch]);

  /* Clear order and cart if went back to this page from order creation */
  useEffect(() => {
    clearAll();
  }, [clearAll]);

  /**
   * *Note to change the selected type here to draft when you done
   */
  // const orderAvailable =
  //   mainOrders &&
  //   mainOrders?.length === 0 &&
  //   selectedOrderType?.value === "live-orders"
  //     ? false
  //     : true;
  // const orderAvailable = mainOrders && mainOrders?.length >= 1 ? true : false;

  const onProceedCart = () => {
    if (order && order?.order_type !== "dine in") {
      paymentChannelDestinationHelper(order?.id);
      // Set checkout order
      dispatch(
        toggleCheckoutOrder({
          type: cartState?.activeCart,
          orderItems: cartState?.cart
            ? cartState?.cart[cartState?.activeCart]
            : [],
          status: order?.status,
          order_no: order?.order_no,
          id: order?.id,
          outlet_table_id: order?.outlet_table_id,
          vatvalue: order?.vatvalue,
          order_price: order?.order_price,
          total: order?.total,
          discount_type: order?.discount_type,
          discount_value: order?.discount,
          outletTable: order?.outletTable,
          client_name: order?.client_name,
          created_at: order?.created_at,
          uuid: order?.uuid,
        })
      );
    } else {
      navigate("/orders/checkout/payment-channel", {
        state: {
          order_id: `${order?.id}`,
        },
      });
      dispatch(
        toggleCheckoutOrder({
          type: cartState?.activeCart,
          orderItems: cartState?.cart
            ? cartState?.cart[cartState?.activeCart]
            : [],
          status: order?.status,
          order_no: order?.order_no,
          id: order?.id,
          outlet_table_id: order?.outlet_table_id,
          vatvalue: order?.vatvalue,
          order_price: order?.order_price,
          total: order?.total,
          discount_type: order?.discount_type,
          discount_value: order?.discount,
          outletTable: order?.outletTable,
          client_name: order?.client_name,
          created_at: order?.created_at,
          uuid: order?.uuid,
        })
      );
    }
    // navigate("/orders/checkout/payment-channel");
  };

  /* Adding dummy data as there is no order data in the API */
  const onEditCart = async () => {
    const checkoutOrder = {
      type: clickedOrder.order_type,
      status: clickedOrder?.status,
      order_no: clickedOrder.order_no,
      orderItems: clickedOrder?.orderItems,
      id: clickedOrder?.id,
      outlet_table_id: clickedOrder?.outlet_table_id,
      outletTable: clickedOrder?.outletTable,
      vatvalue: clickedOrder?.vatvalue,
      order_price: clickedOrder?.order_price,
      total: clickedOrder?.total,
      discount_type: clickedOrder?.discount_type,
      discount_value: clickedOrder?.discount,
      discount_by: clickedOrder?.discount_by,
      client_name: clickedOrder?.client_name,
      created_at: clickedOrder?.created_at,
      total_discount: clickedOrder?.total_discount,
    };
    dispatch(addSelectedOrder([checkoutOrder]));
    dispatch(switchToCartEditing(true));
    navigate("/orders/create/dish-selection");
  };

  // console.log(mainOrderUrl, "URL", queryObj);

  return (
    <div className="w-full grid grid-rows-[auto_1fr] overflow-y-auto h-full">
      <PosHeader
        createNewOrder={createNewOrder}
        filterOrderType={filterOrderType}
        changeOrderServiceType={changeOrderServiceType}
        selectedOption={selectedOrderType}
        resetOrderStatusFilter={resetAllStatus}
        onChangeFilter={(val) => {
          setPage(1);
          onChangeFilter(val);
        }}
        showFilter={showFilter}
        toggleShowFilter={toggleShowFilter}
        setQueryObj={setStoreQueryObj}
        queryObj={storeQueryObj}
        applyFilter={applyFilter}
        toggleTimeChanged={toggleTimeChanged}
        clearFilter={clearFilter}
        chosenQuery={queryObj}
      />
      <div className="w-full">
        {/* Loading state */}
        {!error && isLoading && !moreDataLoading && mainOrders.length === 0 && (
          <div className="w-full flex flex-row items-center justify-center h-full">
            <Loader />
          </div>
        )}
        {!error &&
          isLoading &&
          !moreDataLoading &&
          mainOrders.length > 0 &&
          page === 1 && (
            <div className="w-full flex flex-row items-center justify-center">
              <Loader />
            </div>
          )}
        {/* Error state */}
        {error && !isLoading && (
          <div className="w-full  flex flex-col items-center justify-center mt-[5rem]">
            <img
              src={ErrorImg}
              alt="Error Illustration"
              className="block w-[200px] h-[200px]"
            />
            <p className="text-3xl lg:text-4xl font-Inter font-medium text-red-300">
              An error occurred loading your orders.
            </p>
            <div className="mt-16 w-full flex flex-row items-center justify-center">
              <PrimaryButton
                text="Try Again"
                onClicked={() => {
                  fetchOrders();
                }}
              />
            </div>
          </div>
        )}
        {/* Empty state */}
        {!error && !isLoading && mainOrders && mainOrders.length === 0 && (
          <div className="flex flex-row mt-40 justify-center">
            <p className="text-3xl font-Inter font-medium text-greyText text-center">
              No Record found
            </p>
          </div>
        )}

        {/* Data view */}
        {!error && !isLoading && mainOrders && mainOrders.length > 0 && (
          <div className="w-full">
            <ContainerDiv className="w-full grid md:grid-cols-2 xl:grid-cols-3 gap-5 mt-[2rem]">
              {mainOrders.map((item) => (
                <SinglePosOrder
                  key={item?.id + Math.random() + 100}
                  selectedOrderType={selectedOrderType}
                  filterOrderType={filterOrderType}
                  item={item}
                  showOrder={showOrder}
                  clickedOrder={clickedOrder}
                />
              ))}
            </ContainerDiv>
          </div>
        )}
      </div>
      {moreDataLoading && (
        <div className="w-full flex flex-row items-center justify-center mt-10 pb-10">
          <MainLoader />
        </div>
      )}
      <GeneralSideModal
        isOpen={showDrawer}
        handleCloseModal={() => {
          setClickedOrder(null);
          toggleDrawer();
          clearAll();
        }}
      >
        <OrderDetailModalView
          clickedOrder={clickedOrder}
          onEditCart={onEditCart}
          onProceedCart={onProceedCart}
          fetchOrders={fetchOrders}
          handleCloseModal={() => {
            setClickedOrder(null);
            toggleDrawer();
            clearAll();
          }}
        />
      </GeneralSideModal>
    </div>
  );
};

export default Orders;

const ContainerDiv = styled.div`
  @media (min-width: 1580px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media (min-width: 2000px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;
