import { STATUS } from "components/Status/Status";
import React from "react";

const StatusIndicator = ({ status }) => {
  if (status) {
    return (
      <div className="flex flex-row items-center space-x-2">
        <span
          className="w-[0.6rem] h-[0.6rem] rounded-full inline-block"
          style={{
            backgroundColor: STATUS[status].dotColor,
          }}
        ></span>
        <span className="font-Inter font-medium text-[1.4rem]">
          {STATUS[status]?.text}
        </span>
      </div>
    );
  } else return <></>;
};

export default StatusIndicator;
