import useClickOutside from "hooks/useOutsideClick";
import React, { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

const OrderDateSingleFilter = ({ setQueryObj, queryObj }) => {
  const [openCalendar, setOpenCalendar] = useState(false);
  const dateRef = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  useClickOutside(dateRef, () => setOpenCalendar(false));

  return (
    <div className="flex flex-row relative" ref={dateRef}>
      <button
        onClick={() => setOpenCalendar(!openCalendar)}
        // onClick={() => setShow(!show)}
        className="flex flex-row items-center justify-center border border-gray-200 rounded-full px-[1.5rem] py-4 font-semibold space-x-2 "
      >
        {queryObj && queryObj?.date ? (
          <p className="text-[1.4rem]">{moment(queryObj?.date).format("ll")}</p>
        ) : (
          <p className="text-[1.4rem]">Date</p>
        )}

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#222222"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setOpenCalendar(!openCalendar);
          setStartDate(date);
          setQueryObj({ ...queryObj, date: moment(date).format("YYYY-MM-DD") });
        }}
        className="absolute  w-0 h-0 focus:outline-none hover:outline-none outline-none  bg-red-500 top-0 mt-0 mb-0 -bottom-[4rem]"
        popperPlacement="bottom"
        open={openCalendar}
        showIcon={false}
        popperClassName="-bottom-[4rem] absolute"
      />
    </div>
  );
};

export default OrderDateSingleFilter;
