import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  setSelectedCategory,
  setSelectedCategoryName,
} from "redux/category";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MainDishSelectionDishes from "./MainDishSelectionDishes";

const MainDishSelectionCategory = ({
  // changeDishSelectionView,
  fetchDishes,
  changePage,
  total_pages: dishTotalPages,
  page: dishPage,
  addDishToCartHandler,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { categories, isLoading, error, extra, selectedCategory } = useSelector(
    (state) => state.category
  );
  // Possible values dishes-view || category-selection
  const [categoryView, setCategoryView] = useState("category-selection");

  useEffect(() => {
    if (selectedCategory) {
      setCategoryView("dishes-view");
    }
  }, [selectedCategory]);

  // For pagination
  const [page, setPage] = useState(1);
  // const offset = (page - 1) * LIMIT;
  const total_pages = (extra && extra?.total_pages) || 1;

  useEffect(() => {
    dispatch(fetchCategories({ page }));
  }, [dispatch, page]);

  const goToDishes = (id, name) => {
    dispatch(setSelectedCategory(id));
    dispatch(setSelectedCategoryName(name));
    // changeDishSelectionView("featured");
  };

  const changeCategoryView = (val) => {
    setCategoryView(val);
  };

  return (
    <div className="w-full h-full ">
      {categoryView === "category-selection" && (
        <div className="w-full flex flex-row items-center justify-between">
          <h2 className="text-textColor font-Inter text-[1.6rem] font-semibold">
            {t("CATEGORIES")}
          </h2>
          <h2 className="text-textColor font-Inter text-[1.6rem] font-semibold">
            فئات
          </h2>
        </div>
      )}

      <div className="w-full ">
        {/* Loader */}
        {isLoading && !error && (
          <div className="w-full flex flex-row mt-10">
            <Skeleton
              count={5}
              width="100%"
              height={100}
              containerClassName="flex-1 flex flex-row w-full space-x-5"
              className="h-[8rem] flex-row flex w-full"
            />
          </div>
        )}
        {/* Error */}
        {!isLoading && error && (
          <div className="w-full  flex flex-col items-center justify-center mt-[4rem]">
            <p className="text-3xl lg:text-4xl font-Inter font-medium text-red-300">
              An error occurred get your categories
            </p>
            <div className="mt-16 w-full flex flex-row items-center justify-center">
              <PrimaryButton
                text="Try Again"
                onClicked={() => {
                  dispatch(fetchCategories({ page }));
                }}
              />
            </div>
          </div>
        )}
        {/* Empty categories */}
        {!isLoading && !error && categories && categories.length === 0 && (
          <div className="mt-16 w-full flex flex-row items-center justify-center">
            <h2 className="text-[1.6rem] text-center font-Inter font-semibold text-textColor">
              No categories found.
            </h2>
          </div>
        )}
        {/* Categories */}
        {!isLoading && !error && categories && categories.length > 0 && (
          <>
            {categoryView === "category-selection" ? (
              <div className="w-full">
                <div className="w-full grid grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 gap-5 mt-10">
                  {categories.map((category) => (
                    <button
                      className="w-full bg-white flex flex-col items-center justify-center rounded-md h-[10rem]"
                      key={category?.id}
                      onClick={() => {
                        changeCategoryView("dishes-view");
                        goToDishes(category?.id, category?.name);
                      }}
                    >
                      <p className="text-textColor font-Inter font-medium">
                        {category?.name}
                      </p>
                      <p className="text-textColor font-Inter font-medium pt-2">
                        {category?.translations?.ar?.name}
                      </p>
                    </button>
                  ))}
                </div>
                {/* Pagination */}
                <div className="w-full">
                  {!isLoading && categories && categories?.length >= 1 && (
                    <div className="flex mt-10 pb-6">
                      <div className="mx-auto">
                        {total_pages && total_pages > 1 && (
                          <p className="text-sm text-gray-400 font-semibold font-Inter text-center  my-3">
                            Page {page} of&nbsp;
                            {total_pages}
                          </p>
                        )}
                        <Pagination
                          count={total_pages}
                          page={page}
                          onChange={(event, val) => {
                            setPage(val);
                          }}
                          renderItem={(item) => (
                            <PaginationItem
                              {...item}
                              shape="rounded"
                              size="small"
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <MainDishSelectionDishes
                fetchDishes={fetchDishes}
                changePage={changePage}
                total_pages={dishTotalPages}
                page={dishPage}
                addDishToCartHandler={addDishToCartHandler}
                changeCategoryView={changeCategoryView}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MainDishSelectionCategory;
