import React from "react";
import MainDishSelectionNavigationLayout from "./components/MainDishSelectionNavigationLayout";
import { useDishSelection } from "./useDishSelection";
import MainDishSelectionLeftSide from "./components/MainDishSelectionLeftSide";
import MainDishSelectionRightSide from "./components/MainDishSelectionRightSide";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import MainDishItemQuantityView from "./components/MainDishItemQuantityView";
import DiscountTypeView from "./components/DiscountTypeView";

const MainDishSelection = () => {
  const {
    fetchDishes,
    page,
    changePage,
    total_pages,
    addServices,
    changeDishView,
    dishView,
    addDishToCartHandler,
    addingDish,
    onReduceItemQuantity,
    removeDishFromCart,
    onIncreaseItemQuantity,
    placeOrder,
    orderLoading,
    changeClickedNote,
    clickedNoteItem,
    closeNoteView,
    openNoteView,
    noteView,
    changeDishSelectionView,
    dishSelectionView,
  } = useDishSelection();
  return (
    <MainDishSelectionNavigationLayout
      addServices={addServices}
      dishView={dishView}
      changeDishView={changeDishView}
      placeOrder={placeOrder}
      changeDishSelectionView={changeDishSelectionView}
      dishSelectionView={dishSelectionView}
    >
      <LoadingScreen show={addingDish || orderLoading} />
      <div className="w-full h-full grid lg:grid-cols-[60%_40%] gap-5 overflow-hidden overflow-x-hidden">
        <MainDishSelectionLeftSide
          fetchDishes={fetchDishes}
          changePage={changePage}
          total_pages={total_pages}
          page={page}
          addDishToCartHandler={addDishToCartHandler}
          dishSelectionView={dishSelectionView}
          changeDishSelectionView={changeDishSelectionView}
        />
        {dishView === "quantity" && (
          <MainDishItemQuantityView
            dishView={dishView}
            onRemove={removeDishFromCart}
            onReduceItemQuantity={onReduceItemQuantity}
            onIncreaseItemQuantity={onIncreaseItemQuantity}
          />
        )}
        {(dishView === "discount" || dishView === "note") && (
          <MainDishSelectionRightSide
            dishView={dishView}
            onRemove={removeDishFromCart}
            onReduceItemQuantity={onReduceItemQuantity}
            onIncreaseItemQuantity={onIncreaseItemQuantity}
            changeClickedNote={changeClickedNote}
            clickedNoteItem={clickedNoteItem}
            openNoteView={openNoteView}
            closeNoteView={closeNoteView}
            noteView={noteView}
            changeDishView={changeDishView}
          />
        )}
        {dishView === "discount_selection" && (
          <DiscountTypeView changeDishView={changeDishView} />
        )}
      </div>
    </MainDishSelectionNavigationLayout>
  );
};

export default MainDishSelection;
