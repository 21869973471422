import useClickOutside from "hooks/useOutsideClick";
import React, { useMemo, useRef, useState } from "react";
import { DropdownContainer } from "scss/styled";
import MultiRangeSlider from "multi-range-slider-react";

const OrderTimeSingleFilter = ({
  setQueryObj,
  queryObj,
  toggleTimeChanged,
}) => {
  const [show, setShow] = useState(false);
  const dropDownRef = useRef(null);
  useClickOutside(dropDownRef, () => setShow(false));

  const [minValue, set_minValue] = useState(15);
  const [maxValue, set_maxValue] = useState(120);
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
    toggleTimeChanged(true);
  };

  useMemo(() => {
    setQueryObj({ ...queryObj, startTime: minValue, endTime: maxValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minValue, maxValue]);

  return (
    <div className="relative" ref={dropDownRef}>
      <button
        onClick={() => setShow(!show)}
        className="flex flex-row items-center justify-center border border-gray-200 rounded-full px-[1.5rem] py-4 font-semibold space-x-2"
      >
        <p className="text-[1.4rem]">Time</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#222222"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {show && (
        <DropdownContainer className="dropDownMenu px-[1rem]">
          <MultiRangeSlider
            min={15}
            max={120}
            step={15}
            minValue={minValue}
            maxValue={maxValue}
            ruler={false}
            onInput={(e) => {
              handleInput(e);
            }}
          />
        </DropdownContainer>
      )}
    </div>
  );
};

export default OrderTimeSingleFilter;
