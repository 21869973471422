import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import { AuthContext } from "./context/authContext";
import { useTranslation } from "react-i18next";
import Layout from "components/Layout/Layout";
import OrdersPage from "pages/OrdersPage/OrdersPage";
import LoginPage from "pages/LoginPage/LoginPage";
import AllOrdersPage from "pages/OrdersPage/AllOrdersPage/AllOrdersPage";
import CreateOrdersPage from "pages/OrdersPage/CreateOrdersPage/CreateOrdersPage";
import OrderTypePage from "pages/OrdersPage/CreateOrdersPage/OrderTypePage/OrderTypePage";
import AllocationSteps from "pages/OrdersPage/CreateOrdersPage/AllocationSteps/AllocationSteps";
import TableAllocation from "pages/OrdersPage/CreateOrdersPage/TableAllocation/TableAllocation";
// import CategorySelection from "pages/OrdersPage/CreateOrdersPage/CategorySelection/CategorySelection";
// import DishSelection from "pages/OrdersPage/CreateOrdersPage/DishSelection/DishSelection";
import CheckoutPage from "pages/OrdersPage/CheckoutPage/CheckoutPage";
// import PaymentChannel from "pages/OrdersPage/CheckoutPage/PaymentChannel/PaymentChannel";
import ReceiptPage from "pages/OrdersPage/CheckoutPage/ReceiptPage/ReceiptPage";
import FloorDefaultScreen from "pages/Floor/FloorDefaultScreen/FloorDefaultScreen";
import ShiftDefaultScreen from "pages/Shift/ShiftDefaultScreen/ShiftDefaultScreen";
import Floor from "pages/Floor/Floor";
import Shift from "pages/Shift/Shift";
import AddTable from "pages/Floor/AddTable/AddTable";
import AddZone from "pages/Floor/AddZone/AddZone";
import UpdateTable from "pages/Floor/UpdateTable/UpdateTable";
// import DuplicateReciept from "pages/OrdersPage/CheckoutPage/duplicateReceipt/DuplicateReciept";
import LiveOrdersReceipt from "pages/OrdersPage/CheckoutPage/liveOrderReceipt/LiveOrdersReceipt";
import { AuthContext } from "context/authContext";
// import { useSelector } from "react-redux";
import LockScreenModal from "components/Modal/lockedScreenModal/LockScreen";
import MainPaymentChannel from "pages/OrdersPage/CheckoutPage/PaymentChannel/MainPaymentChannel";
import MainCategorySelection from "pages/OrdersPage/CreateOrdersPage/CategorySelection/MainCategorySelection";
import MainDishSelection from "pages/OrdersPage/CreateOrdersPage/DishSelection/MainDishSelection";
import MainDuplicateReceipt from "pages/OrdersPage/CheckoutPage/duplicateReceipt/MainDuplicateReceipt";
// import LockScreenWelcomeModal from "components/Modal/lockScreenWelcomeModal/LockScreenWelcomeModal";

const MainRoute = () => {
  const { i18n } = useTranslation();
  const authContext = useContext(AuthContext);
  //   const { screenState } = useSelector((state) => state.restaurant);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    document.dir = i18n.dir();
  }, [i18n, i18n.language]);
  const isScreenLocked = localStorage.getItem("jalebi_pos_screen_lock_state");
  const regexPattern = new RegExp("true");
  const mainLock = regexPattern.test(isScreenLocked);

  useEffect(() => {
    if (mainLock) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [mainLock]);

  let routes = (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );

  if (authContext.loggedInUser) {
    routes = (
      <Layout>
        <LockScreenModal
          showModal={showModal}
          toggleModal={() => setShowModal(!showModal)}
        />
        {/* <LockScreenWelcomeModal
          showModal={showModal}
          toggleModal={() => setShowModal(!showModal)}
        /> */}
        <Routes>
          {/* Testing */}
          <Route path="/shift/*" element={<Shift />}>
            <Route index element={<ShiftDefaultScreen />} />
            {/* <Route path="tables/add" element={<AddTable />} />
            <Route path="tables/update/:tableNum" element={<UpdateTable />} />
            <Route path="zones/add" element={<AddZone />} />
            <Route path="*" element={<Navigate to="/floor" />} /> */}
          </Route>
          {/* Orders */}
          <Route path="/orders/*" element={<OrdersPage />}>
            <Route path="all" element={<AllOrdersPage />} />
            {/* Create orders route */}
            <Route path="create/*" element={<CreateOrdersPage />}>
              <Route path="order-type" element={<OrderTypePage />} />
              <Route path="allocation-steps" element={<AllocationSteps />} />
              <Route path="table-allocation" element={<TableAllocation />} />
              <Route
                path="category-selection"
                element={<MainCategorySelection />}
              />
              {/* <Route
                path="category-selection"
                element={<CategorySelection />}
              /> */}
              <Route path="dish-selection" element={<MainDishSelection />} />
              {/* <Route path="dish-selection" element={<DishSelection />} /> */}
              <Route path="*" element={<Navigate to="order-type" />} />
            </Route>
            {/* Checkout */}
            <Route path="checkout/*" element={<CheckoutPage />}>
              <Route path="payment-channel" element={<MainPaymentChannel />} />
              {/* <Route path="payment-channel" element={<PaymentChannel />} /> */}
              <Route path="receipt" element={<ReceiptPage />} />
              <Route
                path="duplicate-receipt"
                element={<MainDuplicateReceipt />}
              />
              {/* <Route path="duplicate-receipt" element={<DuplicateReciept />} /> */}
              <Route
                path="live-order-receipt"
                element={<LiveOrdersReceipt />}
              />
              <Route path="*" element={<Navigate to="payment-channel" />} />
            </Route>
            <Route path="*" element={<Navigate to="all" />} />
          </Route>

          {/* Floor */}
          <Route path="/floor/*" element={<Floor />}>
            <Route index element={<FloorDefaultScreen />} />
            <Route path="tables/add" element={<AddTable />} />
            <Route path="tables/update/:tableNum" element={<UpdateTable />} />
            <Route path="zones/add" element={<AddZone />} />
            <Route path="*" element={<Navigate to="/floor" />} />
          </Route>

          <Route path="*" element={<Navigate to="/shift" />} />
        </Routes>
      </Layout>
    );
  }
  return <div className="App">{routes}</div>;
};

export default MainRoute;
