import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeActiveCart } from "redux/cart";
import { changeActiveService } from "redux/editingOrder";
import { cn } from "utillity/utils";

const MainDishServices = () => {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.cart);
  const createOrder = useSelector((state) => state.createOrder);
  const { selectedOrders } = useSelector((state) => state.editOrder);
  // const { selectedLocation, activeLocation } = useSelector(
  //   (state) => state.location
  // );
  //  Setting the active service on cart page load incase user does not click it
  useMemo(() => {
    if (createOrder?.isEditing) {
      dispatch(changeActiveService(cartState?.activeCart));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrder?.isEditing]);

  const radioChangeHandler = (newValue) => {
    dispatch(changeActiveCart(newValue));
  };
  return (
    <div className="w-full ">
      {selectedOrders && selectedOrders?.length > 0 && (
        <div className="w-full grid grid-cols-3 gap-5">
          {selectedOrders?.map((item, index) => (
            <button
              key={index}
              onClick={() => radioChangeHandler(item?.type)}
              className={cn(
                "w-full rounded-md border border-gray-800 flex flex-row items-center justify-center text-textColor font-Inter font-semibold capitalize h-[4.5rem]",
                [
                  cartState?.activeCart &&
                    cartState?.activeCart === item?.type &&
                    "bg-textColor text-white",
                ]
              )}
            >
              {item?.type}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MainDishServices;
