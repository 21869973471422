import { useCartHooks } from "hooks/useCartHooks";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import { useGetTenderTaxHook } from "hooks/useGetTenderTaxHook";
import React from "react";
import { useSelector } from "react-redux";

const PaymentChannelOrderDetails = ({ selectedChannel, selectedTender }) => {
  const { currency, taxEnabled, decimal_preference } = useGetActiveLocation();
  const { checkoutOrder, activeCart } = useSelector((state) => state.cart);
  const cartState = useSelector((state) => state.cart);
  const editOrder = useSelector((state) => state.editOrder);
  const {
    discountPriceReceipt,
    showDiscountReceipt,
    discountTypeReceipt,
    // discountValue,
    vatReceipt,
    bohDiscount,
  } = useCartHooks();
  // Calculating the total cart price
  const getAllSubTotalPrice = () => {
    let price = 0;
    const allItems = checkoutOrder?.orderItems;

    allItems?.forEach((el) => {
      price += +el?.price * el?.quantity;
    });

    return price.toFixed(decimal_preference);
  };

  const getSubTotalPrice = () => {
    let subTotalPrice = 0;
    if (
      cartState &&
      cartState?.cart &&
      cartState?.cart[cartState?.activeCart]
    ) {
      for (const item of cartState?.cart[cartState?.activeCart]) {
        subTotalPrice += Number(item?.price) * Number(item?.quantity);
      }
    }

    return Number(subTotalPrice);
    // return numberFormat(subTotalPrice, decimal_preference);
  };

  const { showTenderTax, tenderFee } = useGetTenderTaxHook({
    tax: selectedTender?.tax,
    tax_type: selectedTender?.tax_type,
    order_total: getAllSubTotalPrice(),
  });

  // Price without any tax or commissions
  const totalCartPriceWithoutAnyCommission = getAllSubTotalPrice();
  //   Order price with only tax
  const totalWithoutChannelCommission =
    Number(totalCartPriceWithoutAnyCommission) +
    Number(editOrder.orderTaxes[activeCart]);

  // Channel fee
  const getChannelFee = () => {
    if (selectedChannel && selectedChannel?.commission) {
      if (selectedChannel?.commission_type === "percentage") {
        return (
          (Number(selectedChannel?.commission) *
            Number(getAllSubTotalPrice())) /
          100
        );
      } else {
        return Number(selectedChannel?.commission);
      }
    }
    return 0;
  };
  const channelFee = getChannelFee();

  const showChannel =
    checkoutOrder && checkoutOrder?.type === "dine in" ? false : true;

  //   Order total price
  const getTotalPrice = () => {
    let totalPrice = 0;
    if (showChannel && showDiscountReceipt) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(vatReceipt);
      totalPrice += getChannelFee();
      totalPrice += tenderFee;
      totalPrice -= bohDiscount();
      // totalPrice -= discountPriceReceipt();
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
    } else if (!showChannel && showDiscountReceipt) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(vatReceipt);
      totalPrice += tenderFee;
      totalPrice -= bohDiscount();
      // totalPrice -= discountPriceReceipt();
      // return numberFormat(totalPrice, decimal_preference);
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
    } else if (showChannel && !showDiscountReceipt) {
      totalPrice += getSubTotalPrice();
      totalPrice += getChannelFee();
      totalPrice += Number(vatReceipt);
      totalPrice += tenderFee;
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
    } else if (!showChannel && !showDiscountReceipt) {
      totalPrice += getSubTotalPrice();
      totalPrice += Number(vatReceipt);
      totalPrice += tenderFee;
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
    } else {
      totalPrice += getSubTotalPrice();
      //   totalPrice += Number(activeTab?.order_price);
      totalPrice += Number(vatReceipt);
      totalPrice += tenderFee;
      return Number(totalPrice);
      // return Number(totalPrice).toFixed(decimal_preference);
      // return numberFormat(totalPrice, decimal_preference);
    }
  };

  return (
    <div className="w-full ">
      {/* Single Detail */}
      <div className="w-full flex flex-row items-center justify-between mb-4">
        <p className="text-[1.4rem] text-textColor font-medium font-Inter">
          Subtotal
        </p>
        <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
          {currency} {totalCartPriceWithoutAnyCommission}
        </p>
      </div>
      {/* Single Detail  Tax detail*/}
      {taxEnabled && (
        <div className="w-full flex flex-row items-center justify-between mb-2 border-b border-b-gray-200 pb-2">
          <p className="text-[1.4rem] text-textColor font-medium font-Inter">
            Tax
          </p>
          <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
            {currency}{" "}
            {Number(editOrder?.orderTaxes[activeCart])?.toFixed(
              decimal_preference
            )}
          </p>
        </div>
      )}
      <div className="w-full border-b border-b-gray-200">
        {/* Single Detail */}
        <div className="w-full flex flex-row items-center justify-between mb-4">
          <p className="text-[1.4rem] text-textColor font-medium font-Inter">
            {taxEnabled ? (
              <span>Payable amount after tax</span>
            ) : (
              <span>Payable amount without tax</span>
            )}
          </p>
          <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
            {currency}{" "}
            {totalWithoutChannelCommission?.toFixed(decimal_preference)}
          </p>
        </div>
        {/* Single Detail */}
        {showChannel && (
          <div className="w-full flex flex-row items-center justify-between mb-4">
            <p className="text-[1.4rem] text-textColor font-medium font-Inter">
              {selectedChannel?.commission_type === "percentage" ? (
                <span>Channel Commission (percentage)</span>
              ) : (
                <span>Channel Commission (fixed)</span>
              )}
            </p>
            <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
              {currency} {channelFee?.toFixed(decimal_preference)}
            </p>
          </div>
        )}

        {/* Single Detail */}
        {selectedTender && showTenderTax && (
          <div className="w-full flex flex-row items-center justify-between mb-4">
            <p className="text-[1.4rem] text-textColor font-medium font-Inter">
              {selectedTender && selectedTender?.tax_type === "percentage" ? (
                <span>Tender Commission (percentage)</span>
              ) : (
                <span>Tender Commission (fixed)</span>
              )}
            </p>
            <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
              {currency} {tenderFee?.toFixed(decimal_preference)}
            </p>
          </div>
        )}
        {showDiscountReceipt && (
          <div className="w-full flex flex-row items-center justify-between mb-4">
            <p className="text-[1.4rem] text-textColor font-medium font-Inter">
              {discountTypeReceipt === "percentage" ? (
                <span>Discount (percentage)</span>
              ) : (
                <span>Discount (fixed)</span>
              )}
            </p>
            <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
              {currency} {discountPriceReceipt().toFixed(decimal_preference)}
            </p>
          </div>
        )}
      </div>

      {/* Single Detail */}
      <div className="w-full flex flex-row items-center justify-between  pt-2">
        <p className="text-[1.4rem] text-textColor font-medium font-Inter">
          Total Payable
        </p>
        <p className="text-[1.4rem] text-textColor font-semibold font-Inter">
          {currency} {getTotalPrice().toFixed(decimal_preference)}
        </p>
      </div>
    </div>
  );
};

export default PaymentChannelOrderDetails;
