import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addItemToCart,
  changeActiveCart,
  clearCart,
  increaseItemInCart,
  reduceItemInCart,
  removeItemFromCart,
  toggleCheckoutOrder,
} from "redux/cart";
import {
  clearDishes,
  decrementDishUsedStock,
  fetchCategoryDishes,
  incrementDishUsedStock,
  updateDishStock,
  updateDishUsedStock,
} from "redux/dish";
import { DishServices } from "services/DishServices";
import { toast } from "react-toastify";
import { clearOrder, toggleAddingService } from "redux/createOrder";
import { useNavigate } from "react-router-dom";
import {
  addEditingOrder,
  changeActiveService,
  initialUpdateDiscountType,
  saveOrderTaxes,
  toggleActiveCheckoutOrder,
} from "redux/editingOrder";
import { OrderServices } from "services/OrderServices";
import { useDefaultSetting } from "hooks/useDefaultSetting";
import {
  fetchCategories,
  setSelectedCategory,
  setSelectedCategoryName,
} from "redux/category";

export const useDishSelection = () => {
  const [dishView, setDishView] = useState("quantity");
  // Dish selections
  const [dishSelectionView, setDishSelectionView] = useState("featured");
  // const { token } = useContext(AuthContext);
  const [addingDish, setAddingDish] = useState(false);
  const [orderLoading, setOrderLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clickedNoteItem, setClickedNoteItem] = useState(null);
  const [noteView, setNoteView] = useState(false);
  const [orderChequeView, setOrderChequeView] = useState(false);
  const [clickedOrder, setClickedOrder] = useState(null);
  const {
    selectedCategory,
    categories,
    // isLoading: categoriesLoading,
  } = useSelector((state) => state.category);
  const { extra } = useSelector((state) => state.dish);
  const { selectedLocation, activeLocation } = useSelector(
    (state) => state.location
  );
  // For pagination
  const [page, setPage] = useState(1);
  // const offset = (page - 1) * LIMIT;
  const total_pages = (extra && extra?.total_pages) || 1;

  const { cart, activeCart } = useSelector((state) => state.cart);
  const cartState = useSelector((state) => state.cart);
  const createOrder = useSelector((state) => state.createOrder);
  const editOrder = useSelector((state) => state.editOrder);
  const { selectedOrders } = useSelector((state) => state.editOrder);
  const { paymentChannelDestinationHelper } = useDefaultSetting();

  // Change page pagination
  const changePage = (val) => {
    setPage(val);
  };
  // Change the Dish view
  /**
   *
   * @param possible values for  val include ("quantity" | "discount" | "discount_selection")
   */
  const changeDishView = (val) => {
    setDishView(val);
  };

  /**
   *
   * @param possible values for  val include ("featured" | "popular" | "categories")
   */
  const changeDishSelectionView = (val) => {
    setDishSelectionView(val);
  };

  // Add dish to cart
  const addDishToCartHandler = async (dish, catId, cartItemIndex) => {
    const selectedDish =
      cart && cart[activeCart]?.filter((item) => item?.item_id === dish?.id);
    const selectedDishIndexInCart =
      cart && cart[activeCart]?.findIndex((el) => el?.item_id === dish?.id);

    setAddingDish(true);
    DishServices.getSingleItemStock(
      dish,
      (res) => {
        const dishData = res?.data?.data;

        if (res && res?.data?.data?.data?.available_stock >= 1) {
          setAddingDish(false);
          // Checking if the dish is already present in cart increase its quantity  else just add to cart
          if (selectedDish[0]) {
            onIncreaseItemQuantity(
              selectedDishIndexInCart + 1,
              dish?.id,
              dish?.category_id
            );
          } else {
            dispatch(
              updateDishStock({
                ...dishData?.data,
                cat_id: selectedCategory,
              })
            );
            dispatch(addItemToCart(dish));
          }
        } else {
          setAddingDish(false);
          toast.error(
            res.data?.data?.data?.message ||
              "Unable to get the stock of selected item.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      },
      (err) => {
        setAddingDish(false);
        toast.error("Unable to get the stock of selected item.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    );
  };

  /* MOVE THIS BACK TO CART COMPONENT WHEN PUTTING REAL API */
  /* Selected category will be repalced by the category objec that is inside the item */

  const onReduceItemQuantity = (cartItemIndex, itemId, catId) => {
    dispatch(reduceItemInCart(cartItemIndex));
    dispatch(decrementDishUsedStock({ id: itemId, catId: selectedCategory }));
  };

  /* MOVE THIS BACK TO CART COMPONENT WHEN PUTTING REAL API */
  /* Selected category will be repalced by the category objec that is inside the item */
  const onIncreaseItemQuantity = (cartItemIndex, itemId, catId) => {
    dispatch(increaseItemInCart(cartItemIndex));
    dispatch(incrementDishUsedStock({ id: itemId, catId: selectedCategory }));
  };

  /* MOVE THIS BACK TO CART COMPONENT WHEN PUTTING REAL API */
  /* Selected category will be repalced by the category objec that is inside the item */
  const removeDishFromCart = (cartItemIndex, itemId, catId) => {
    const itemIndex = cart[activeCart].findIndex(
      (obj) => obj.item_id === itemId
    );
    dispatch(
      updateDishUsedStock({
        catId,
        id: itemId,
        usedStock: cart[activeCart][itemIndex]?.quantity,
      })
    );
    dispatch(removeItemFromCart(cartItemIndex));
  };

  /* Fetch dish for the selected category if not already */
  const fetchDishes = useCallback(() => {
    if (selectedCategory) {
      dispatch(
        fetchCategoryDishes({
          categoryId: selectedCategory,
          page,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCategory, page]);

  // Fetch categories if not available
  useEffect(() => {
    if (!categories) {
      dispatch(fetchCategories({ page: 1 }));
    }
  }, [dispatch, categories]);

  // Get dishes if there is no selected category choosen
  useEffect(() => {
    if (!selectedCategory && categories && categories?.length > 0) {
      dispatch(setSelectedCategory(categories[0]?.id));
      dispatch(setSelectedCategoryName(categories[0]?.name));
      // dispatch(
      //   fetchCategoryDishes({
      //     categoryId: categories[0]?.id,
      //     page,
      //   })
      // );
    }
  }, [selectedCategory, categories, dispatch, page]);
  // Using this function to set the default discount type to percentage for the services since backends keeps returning amount which is // no a discount type
  const setCartDiscounts = () => {
    selectedOrders.forEach((el) => {
      if (Number(el?.discount_value) === 0) {
        dispatch(
          initialUpdateDiscountType({
            type: el?.type,
            discount_type: "percentage",
          })
        );
      }
    });
  };
  useEffect(() => {
    setCartDiscounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearCartHandler = useCallback(() => {
    dispatch(clearDishes());
    dispatch(clearCart());
    dispatch(
      fetchCategoryDishes({
        categoryId: selectedCategory,
        page,
      })
    );
    dispatch(clearOrder());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCategory]);

  useEffect(() => {
    if (selectedCategory) {
      fetchDishes();
    }
  }, [fetchDishes, selectedCategory]);

  const addServices = () => {
    dispatch(toggleAddingService(true));
    navigate("/orders/create/order-type");
  };

  // Determining the outlet table id to use depending on the action type (create or update)
  const dineInOrder = editOrder?.selectedOrders?.filter(
    (el) => el.type === "dine in"
  );
  const isDineInOrder = dineInOrder && dineInOrder?.length > 0;
  const outletTableId =
    isDineInOrder && createOrder?.table?.id !== -1
      ? createOrder?.table?.id
      : dineInOrder[0]?.outlet_table_id;

  /**
   * @function This function decides where the user is directed to depending on the services they have chossen after the order has been placed
   */

  const pageDestination = () => {
    if (
      createOrder.orderTypes.length === 3 &&
      createOrder.orderTypes.includes("dine in")
    ) {
      return {
        home: false,
        active: "delivery",
      };
    } else if (
      createOrder.orderTypes.length === 2 &&
      !createOrder.orderTypes.includes("dine in")
    ) {
      return {
        home: false,
        active: "delivery",
      };
    } else if (
      createOrder.orderTypes.length === 2 &&
      createOrder.orderTypes.includes("dine in") &&
      createOrder.orderTypes.includes("takeaway")
    ) {
      return {
        home: true,
        active: "dine in",
      };
    } else if (
      createOrder.orderTypes.length === 2 &&
      createOrder.orderTypes.includes("dine in") &&
      createOrder.orderTypes.includes("delivery")
    ) {
      return {
        home: false,
        active: "delivery",
      };
    } else if (
      createOrder.orderTypes.length === 1 &&
      createOrder.orderTypes.includes("dine in")
    ) {
      return {
        home: true,
        active: "dine in",
      };
    } else if (
      createOrder.orderTypes.length === 1 &&
      !createOrder.orderTypes.includes("dine in")
    ) {
      return {
        home: false,
        active: createOrder.orderTypes[0],
      };
    } else {
      return { home: true, active: "dine in" };
    }
  };

  const placeOrder = (type) => {
    //  outlet_table_id: createOrder?.isEditing
    //    ? order.outlet_table_id
    //    : createOrder?.table?.id,
    setOrderLoading(true);
    const orderData = {
      restaurant_id: activeLocation.restaurant_id,
      location_id: selectedLocation,
      outlet_table_id:
        createOrder?.isEditing && isDineInOrder
          ? outletTableId
          : createOrder?.table?.id,
      party_size: createOrder.partySize.value,
      customer_name: createOrder.partySize.customer_name,
      customer_phone: createOrder.partySize.customer_number,
      country_code: createOrder.partySize.customer_calling_code,
      // uuid: selectedOrders,

      // uuid()
    };

    if (type === "create") {
      OrderServices.placeOrders(
        { orderData, orders: cartState?.cart, selectedOrders },
        (res) => {
          const { home, active } = pageDestination();
          if (home) {
            toast.success("Order placed successfully!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // if (home || !canProceedOrder.canView) {
            //   toast.success("Order placed successfully!", {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //   });
            // if (!canProceedOrder.canView) {
            //   toast.error(
            //     "You do not have permission to proceed to checkout!",
            //     {
            //       position: toast.POSITION.BOTTOM_RIGHT,
            //     }
            //   );
            // }
            navigate("/orders/all");
            setOrderLoading(false);
          } else {
            // Data response
            const mainOrderOne = res?.filter(
              (item) => item?.data?.data?.type === active
            );
            const mainOrder = mainOrderOne[0]?.data?.data?.data;
            // This is here to make sure it doesn't break if user doesn't add dish to the order if it contains a delivery services which is not sent to the backend but breaks this flow since its supposes to run for delivery services

            if (!mainOrder) {
              toast.success("Order placed successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              navigate("/orders/all");
              setOrderLoading(false);
              return;
            }
            dispatch(clearCart());
            for (const arr of createOrder.orderTypes) {
              const orderItems = res?.filter(
                (item) => item?.data?.data?.type === arr
              );
              // console.log(orderItems);
              // Saving the taxes from backend to use in calculation
              dispatch(
                saveOrderTaxes({
                  service: arr,
                  value: orderItems[0]?.data?.data?.data?.vatvalue,
                })
              );
              // console.log("response", res, "from success order", orderItems);
              for (const orderItem of orderItems[0]?.data?.data?.data
                ?.orderItems) {
                // console.log(orderItems);
                const item = {
                  category: { id: orderItem.category_id, name: "" },
                  id: orderItem.item.id,
                  name: orderItem.item.name,
                  price: Number(orderItem.item.price),
                  quantity: orderItem.quantity,
                  order_id: orderItem?.order_id,
                  // order_id: orderItems[0]?.data?.data?.data?.id,
                  uuid: orderItems[0]?.data?.data?.data.uuid,
                  sizes: orderItem?.item?.sizes,
                  translations: orderItem?.item?.translations,
                  vatvalue: orderItem?.item?.vat,
                  order_vat: orderItem?.vat,
                  total_discount: orderItem?.total_discount,
                  discount_value: orderItem?.discount,
                  discount_type: orderItem?.discount_type,
                  notes: orderItem?.notes,
                };
                dispatch(addItemToCart({ ...item, cartType: arr }));
              }
            }

            setOrderLoading(false);
            dispatch(addEditingOrder(mainOrder));
            dispatch(changeActiveService(active));
            // Setting the checkout order
            dispatch(
              toggleCheckoutOrder({
                type: active,
                orderItems: cartState?.cart ? cartState?.cart[active] : [],
                status: mainOrder?.status,
                order_no: mainOrder?.order_no,
                id: mainOrder?.id,
                outlet_table_id: mainOrder?.outlet_table_id,
                vatvalue: mainOrder?.vatvalue,
                order_price: mainOrder?.order_price,
                total: mainOrder?.total,
                discount_type: mainOrderOne[0]?.data?.data?.discount_type,
                discount_value: mainOrderOne[0]?.data?.data?.discount_value,
                outletTable: mainOrder?.outletTable,
                client_name: mainOrder?.client_name,
                created_at: mainOrder?.created_at,
                total_discount: mainOrder.total_discount,
              })
            );
            dispatch(
              toggleActiveCheckoutOrder({
                type: active,
                orderItems: cartState?.cart ? cartState?.cart[active] : [],
                status: mainOrder?.status,
                order_no: mainOrder?.order_no,
                id: mainOrder?.id,
                outlet_table_id: mainOrder?.outlet_table_id,
                vatvalue: mainOrder?.vatvalue,
                order_price: mainOrder?.order_price,
                total: mainOrder?.total,
                discount_type: mainOrderOne[0]?.data?.data?.discount_type,
                discount_value: mainOrderOne[0]?.data?.data?.discount_value,
                outletTable: mainOrder?.outletTable,
                client_name: mainOrder?.client_name,
                created_at: mainOrder?.created_at,
                total_discount: mainOrder.total_discount,
              })
            );
            setTimeout(() => {
              paymentChannelDestinationHelper(mainOrder?.id);
              // navigate("/orders/checkout/payment-channel");
            }, 1000);
            dispatch(changeActiveCart(active));
            // dispatch(changeActiveCart(cartState?.checkoutOrder?.type));
          }
          // dispatch(fetchTables());
          // dispatch(fetchZones());
          // console.log(res, "Res");
          // props.onClear();
        },
        (err) => {
          if (err && err?.response?.data?.message) {
            toast.error(err.response.data.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (
            err?.response?.data?.message &&
            Array.isArray(Object.values(err.response.data.message))
          ) {
            console.log("Is working arrOne");
            const errors = Object.values(err.response.data.message);
            toast.error(errors[0][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            // for (const key in err.response.data.message) {
            //   toast.error(err.response.data.message[key][0], {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            //   });
            // }
          } else {
            toast.error("Error occurred when creating your order", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          // else if (err && err.response?.data) {
          //   toast.error("Error occurred while creating your order", {
          //     position: toast.POSITION.BOTTOM_RIGHT,
          //   });
          // }
          setOrderLoading(false);
        }
      );
    } else {
      OrderServices.updateOrders(
        {
          orderData,
          orders: cartState.cart,
          selectedOrders,
        },
        (res) => {
          setOrderLoading(false);
          clearCartHandler();
          toast.success("Order Updated successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          navigate("/orders/all");
        },
        (err) => {
          setOrderLoading(false);
          if (
            err?.response?.data?.message &&
            !Array.isArray(err?.response?.data?.message)
          ) {
            if (err?.response?.data?.message?.outlet_table_id) {
              toast.error(err.response.data.message?.outlet_table_id[0], {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } else if (err?.response?.data?.message["orders.0.item_id"]) {
              for (const key of Object.keys(err.response.data.message)) {
                toast.error(err.response.data.message[key][0], {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
            } else {
              toast.error(err?.response?.data?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
          } else if (
            err?.response?.message &&
            Array.isArray(err?.response?.message)
          ) {
            const errors = Object.values(err?.response?.message);
            toast.error(errors[0][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else if (
            err?.response?.data?.message &&
            Array.isArray(err?.response?.data?.message)
          ) {
            const errors = Object.values(err?.response?.data?.message);
            toast.error(errors[0][0], {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error("Error occurred when updating your order", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setOrderLoading(false);
        }
      );
    }
  };
  // Choosing the item to change ita note
  const changeClickedNote = (item) => {
    if (item) {
      setClickedNoteItem(item);
    } else {
      setClickedNoteItem(null);
    }
  };

  const openNoteView = () => {
    setNoteView(true);
  };
  const closeNoteView = () => {
    setNoteView(false);
    setClickedNoteItem(null);
  };

  const openDiscountChequeView = () => {
    setOrderChequeView(true);
  };
  const closeDiscountChequeView = () => {
    setOrderChequeView(false);
    setClickedOrder(null);
  };
  const chooseClickedOrder = (val) => {
    setClickedOrder(val);
  };

  return {
    addDishToCartHandler,
    addingDish,
    onReduceItemQuantity,
    removeDishFromCart,
    onIncreaseItemQuantity,
    fetchDishes,
    clearCartHandler,
    changePage,
    total_pages,
    page,
    addServices,
    changeDishView,
    dishView,
    orderLoading,
    placeOrder,
    changeClickedNote,
    clickedNoteItem,
    closeNoteView,
    openNoteView,
    noteView,
    openDiscountChequeView,
    closeDiscountChequeView,
    chooseClickedOrder,
    orderChequeView,
    clickedOrder,
    changeDishSelectionView,
    dishSelectionView,
  };
};
