import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../config/axiosWithLocationV2";

export const fetchAllTenders = createAsyncThunk(
  "tender/fetchAllTenders",
  async (payload, thunkApi) => {
    const response = await axios
      .get(`/tenders?order_id=${payload?.order_id}`)
      .then((res) => res.data)
      .catch((error) =>
        thunkApi.rejectWithValue(
          error?.response?.data?.message ||
            "Something went wrong. Please try again later."
        )
      );
    return response;
  }
);

const initialState = {
  tenders: null,
  error: null,
  isLoading: false,
  checkoutTender: null,
};

const tenderSlice = createSlice({
  name: "tender",
  initialState,
  reducers: {
    setCheckoutTender: (state, action) => {
      state.checkoutTender = action.payload;
    },
    clearCheckoutTender: (state) => {
      state.checkoutTender = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllTenders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllTenders.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.tenders = action.payload?.data?.data;
    });
    builder.addCase(fetchAllTenders.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { setCheckoutTender, clearCheckoutTender } = tenderSlice.actions;

export default tenderSlice.reducer;
