import React from "react";
import DuplicateReceiptLayout from "./components/DuplicateReceiptLayout";
import { useDuplicateReceipt } from "./useDuplicateReceipt";
import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import MainReceipt from "components/receipt/MainReceipt";
import DuplicateReceiptOrderPriceDetails from "./components/DuplicateReceiptOrderPriceDetails";

const MainDuplicateReceipt = () => {
  const {
    loading,
    mainOrder,
    qrCode,
    receiptRef,
    error,
    getOrder,
    getSubTotalPrice,
    getChannelPrice,
    getOrderTotalPrice,
    getTotalTax,
    taxEnabled,
    showChannel,
    showDiscount,
    discountPrice,
    showTenderTax,
    tenderFee,
    handlePrint,
  } = useDuplicateReceipt();

  console.log(mainOrder, "mainorder");
  return (
    <DuplicateReceiptLayout handlePrint={handlePrint}>
      {loading && !error && (
        <div className="w-full h-full overflow-y-auto overflow-x-hidden flex flex-col items-center justify-center">
          <Loader />
        </div>
      )}
      {!loading && error && (
        <div className="w-full h-full overflow-y-auto overflow-x-hidden flex flex-col items-center justify-center">
          <p className="text-3xl lg:text-4xl font-Inter font-medium text-red-300">
            Error occurred while fetching order details
          </p>
          <div className="mt-16 w-full flex flex-row items-center justify-center">
            <PrimaryButton
              text="Try Again"
              onClicked={() => {
                getOrder();
              }}
            />
          </div>
        </div>
      )}
      {!loading && !error && mainOrder && mainOrder?.order_type && (
        <div className="w-full h-full grid lg:grid-cols-[60%_40%] gap-5 overflow-y-hidden overflow-x-hidden lg:px-4  lg:pr-4 pr-8 pl-4 bg-gray-200">
          <div className="w-full h-full overflow-y-auto">
            <div className="w-full bg-white rounded" ref={receiptRef}>
              <MainReceipt
                getSubTotalPrice={getSubTotalPrice}
                getChannelPrice={getChannelPrice}
                getTotalPrice={getOrderTotalPrice}
                getTotalTax={getTotalTax}
                mainOrder={mainOrder}
                isDuplicate={true}
                showChannel={showChannel}
                showTax={taxEnabled}
                showDiscount={showDiscount}
                getDiscount={discountPrice}
                qrCode={qrCode}
                showTenderTax={showTenderTax}
                tenderFee={tenderFee}
                orderCreatedAt={mainOrder?.created_at}
              />
            </div>
          </div>
          {/* Order prices details */}
          <div className="w-full flex flex-col items-end justify-end overflow-y-auto pb-[1rem] pr-8">
            {/* Order price details */}
            <div className="w-full bg-white px-4 py-4">
              <DuplicateReceiptOrderPriceDetails
                getSubTotalPrice={getSubTotalPrice}
                getChannelPrice={getChannelPrice}
                getOrderTotalPrice={getOrderTotalPrice}
                getTotalTax={getTotalTax}
                showChannel={showChannel}
                showTax={taxEnabled}
                showDiscount={showDiscount}
                getDiscount={discountPrice}
                showTenderTax={showTenderTax}
                tenderFee={tenderFee}
                taxEnabled={taxEnabled}
                mainOrder={mainOrder}
              />
            </div>
          </div>
        </div>
      )}
    </DuplicateReceiptLayout>
  );
};

export default MainDuplicateReceipt;
