import axios from "../../node_modules/axios/index";
import { v4 as uuidv4 } from "uuid";
import axiosWithLocationV2 from "config/axiosWithLocationV2";

export const OrderServices = {
  getOrder: function (data, success, fail) {
    axiosWithLocationV2
      .get(`/order/${data.orderNumber}/show`)
      .then(success)
      .catch(fail);
  },
  getOrderWithUuidV2: function (data, success, fail) {
    axiosWithLocationV2.post(`/order/show`, data).then(success).catch(fail);
  },
  getOrdersWithNumber: function (data, success, fail) {
    return axios
      .all(
        data?.orders?.map((order) =>
          axiosWithLocationV2.get(`/order/${order}/show`)
        )
      )
      .then(success)
      .catch(fail);
  },
  placeOrders: function (data, success, fail) {
    const uuid = uuidv4();
    let ordersRequests = Object.keys(data.orders)
      .map((order, index) => {
        if (Number(data?.selectedOrders[index]?.discount_value) > 0) {
          console.log(data.orders[order], "Order");
          return {
            ...data.orderData,
            uuid,
            type: order,
            status: "draft",
            // Delete discount from each items since discount is applied on order and not items
            orders: data.orders[order]?.map((item) => {
              const newItem = {
                cat_id: item?.cat_id,
                cat_name: item?.cat_name,
                dishVat: item?.dishVat,
                item_id: item?.item_id,
                item_name: item?.item_name,
                notes: item?.notes,
                order_vat: item?.order_vat,
                price: item?.price,
                quantity: item?.quantity,
                sizes: item?.sizes,
                total_discount: item?.total_discount,
                translations: item?.translations,
                type: item?.type,
                uuid: item?.uuid,
                vatvalue: item?.vatvalue,
              };
              // delete item?.discount_value;
              // delete item?.discount_type;
              return newItem;
            }),
            discount_value: Number(data?.selectedOrders[index]?.discount_value),
            discount_type: data?.selectedOrders[index]?.discount_type,
            comment: data?.selectedOrders[index]?.comment,
          };
        } else {
          return {
            ...data.orderData,
            uuid,
            type: order,
            status: "draft",
            orders: data.orders[order],
            // discount_value: Number(data?.selectedOrders[index]?.discount_value),
            // discount_type: data?.selectedOrders[index]?.discount_type,
            comment: data?.selectedOrders[index]?.comment,
          };
        }
      })
      .filter((order) => order.orders.length > 0)
      .map((order) => {
        if (order.type !== "dine in") {
          delete order.party_size;
          delete order.outlet_table_id;
        }
        return order;
      });
    // console.log(ordersRequests, "OrderRequest");

    axios
      .all(
        ordersRequests.map((order) =>
          axiosWithLocationV2.post("/order_details", order)
        )
      )
      .then(success)
      .catch(fail);
  },
  updateOrders: function (data, success, fail) {
    let uuid = uuidv4();
    // console.log(data, "Data");
    let oldOrders = Object.keys(data.orders)
      .map((orderKey, index) => {
        let order = null;
        if (
          Number(data?.selectedOrders[index]?.discount_value) > 0 &&
          data?.selectedOrders[index]?.discount_by === "by-cheque"
        ) {
          // console.log("NAH ME");
          order = {
            ...data.orderData,
            type: orderKey,
            status: "draft",
            // Delete discount from each items since discount is applied on order and not items
            orders: data.orders[orderKey]?.map((item) => {
              // delete item?.discount_value;
              // delete item?.discount_type;
              return item;
            }),
            discount_value: Number(data?.selectedOrders[index]?.discount_value),
            discount_type: data?.selectedOrders[index]?.discount_type,
            comment: data?.selectedOrders[index]?.comment,
            previousStatus: data?.selectedOrders[index]?.status,
            order_id: data?.selectedOrders[index]?.orderItems[0]?.order_id,
          };
        } else {
          // console.log("NAH THEM");
          order = {
            ...data.orderData,
            type: orderKey,
            status: "draft",
            orders: data.orders[orderKey],
            comment: data?.selectedOrders[index]?.comment,
            previousStatus: data?.selectedOrders[index]?.status,
            order_id: data?.selectedOrders[index]?.orderItems[0]?.order_id,
          };
        }

        if (
          data.orders[orderKey][0] &&
          data.orders[orderKey][0].uuid !== undefined
        ) {
          uuid = data.orders[orderKey][0].uuid;
        }

        return order;
      })
      .filter((order) => order.orders.length > 0)
      .filter((order) => order?.previousStatus !== "complete")
      .map((order) => {
        if (order.type !== "dine in") {
          delete order.party_size;
          delete order.outlet_table_id;
        }
        delete order?.previousStatus;
        order.uuid = uuid;
        return order;
      });

    // console.log(oldOrders, "Old");

    let newOrders = oldOrders.filter((order) => order?.order_id === undefined);
    oldOrders = oldOrders.filter((order) => order?.order_id !== undefined);

    // Removing the unnecessary field order_id used to determine the order and new order

    //  const newOrders = oldOrders.filter(
    //    (order) => order.orders[0].order_id === undefined
    //  );

    //  oldOrders = oldOrders.filter(
    //    (order) => order.orders[0].order_id !== undefined
    //  );
    // console.log(newOrders, "New");
    // console.log(oldOrders, "OldTwo");
    // If we are editing the whole cart with new services

    // From here
    if (oldOrders.length === 0) {
      const uuid = uuidv4();
      newOrders.map((order) => {
        order.uuid = uuid;
        return { ...order };
      });
    }

    axios
      .all([
        ...newOrders.map((order) =>
          axiosWithLocationV2.post("/order_details", order)
        ),
        ...oldOrders.map((order) =>
          axiosWithLocationV2.put("/order/" + order?.order_id, order)
        ),
      ])
      .then(success)
      .catch(fail);
  },
  // To here
  // updateSingleOrder: function (data, success, fail) {
  //   const order = data?.selectedOrders.filter(
  //     (item) => item?.type === data?.order[0]?.type
  //   );
  //   const mainOrder = data?.order.map((item) => {
  //     if (item?.type !== "dine in") {
  //       delete item?.party_size;
  //       delete item?.outlet_table_id;
  //     }
  //     if (item?.type === "dine in") {
  //       item.customer_name = order[0]?.client_name || null;
  //     }

  //     return {
  //       ...item,
  //       uuid: item?.orders[0]?.uuid,
  //       discount_value: Number(order[0]?.discount_value),
  //       discount_type: order[0]?.discount_type,
  //       comment: order[0]?.comment || "",
  //     };
  //   });
  //   // if(mainOrder[0])
  //   console.log(mainOrder);
  //   if (mainOrder[0]?.orders[0]?.order_id) {
  //     axios
  //       .all([
  //         ...mainOrder.map((order) =>
  //           axiosWithLocationV2.put("/order/" + order.orders[0].order_id, order)
  //         ),
  //       ])
  //       .then(success)
  //       .catch(fail);
  //   } else {
  //     axios
  //       .all([
  //         ...mainOrder.map((order) =>
  //           axiosWithLocationV2.post("/order_details", order)
  //         ),
  //       ])
  //       .then(success)
  //       .catch(fail);
  //   }
  //   // axios
  //   //   .all([
  //   //     ...mainOrder.map((order) =>
  //   //       axiosWithLocationV2.post("/order_details", order)
  //   //     ),
  //   //   ])
  //   //   .then(success)
  //   //   .catch(fail);
  // },
};

export const OrderServicesV2 = {
  getOrders: (data, success, fail) => {
    axiosWithLocationV2.post("/order/show", data).then(success).catch(fail);
  },
};
