import ButtonSingleScreenNavBar from "components/common/navigationBar/ButtonSingleScreenNavBar";
import SingleScreenNavBar from "components/common/navigationBar/SingleScreenNavBar";
import React from "react";

const MainDishTopNav = ({
  addServices,
  changeDishSelectionView,
  dishSelectionView,
}) => {
  return (
    <div className="navigationBar">
      <ButtonSingleScreenNavBar
        title="Featured"
        nav={false}
        btn={true}
        active={dishSelectionView === "featured"}
        onClick={() => changeDishSelectionView("featured")}
      />
      <ButtonSingleScreenNavBar
        title="Popular"
        nav={false}
        btn={true}
        onClick={() => changeDishSelectionView("popular")}
        active={dishSelectionView === "popular"}
      />
      <ButtonSingleScreenNavBar
        title="Categories"
        nav={false}
        btn={true}
        onClick={() => changeDishSelectionView("categories")}
        active={dishSelectionView === "categories"}
      />
      <ButtonSingleScreenNavBar btn={true} hide={true} nav={false} />
      <SingleScreenNavBar
        hide={false}
        nav={true}
        title="Table & Zones"
        href="/orders/create/allocation-steps"
      />
      <SingleScreenNavBar
        hide={false}
        nav={true}
        title="Party Size"
        href="/orders/create/allocation-steps"
      />
      <ButtonSingleScreenNavBar
        hide={false}
        nav={false}
        title="Add Service"
        onClick={addServices}
        btn={true}
      />
    </div>
  );
};

export default MainDishTopNav;
