import React from "react";
import ButtonSingleScreenNavBar from "components/common/navigationBar/ButtonSingleScreenNavBar";

const DuplicateReceiptFooterLayout = ({ handlePrint }) => {
  return (
    <div className="w-full grid grid-cols-7">
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={true}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title="Print Receipt"
        hide={false}
        href={null}
        nav={null}
        btn={true}
        onClick={handlePrint}
      />
    </div>
  );
};

export default DuplicateReceiptFooterLayout;
