import React from "react";
import Icon from "components/Icon/Icon";
import styled from "styled-components";

const SingleAllocationStepContainer = ({
  active,
  iconName,
  title,
  arText,
  changeStep,
  slug,
}) => {
  return (
    <Container
      onClick={changeStep}
      active={active === slug}
      className={`w-full border border-[#222] rounded-[4px] flex flex-row items-center justify-center xl:py-[5rem] py-[3.5rem] px-[2rem] xl:space-x-8 space-x-5 mb-5 ${
        active === slug ? "bg-[#222]" : "bg-transparent"
      }`}
    >
      <Icon name={iconName} height={2.4} width={2.4} />
      <div>
        <p
          className={`font-bold  font-Inter lg:text-3xl md:text-xl ${
            active === slug ? "text-white" : "text-[#222]"
          }`}
        >
          {title}
        </p>
        <p
          className={`text-xl  font-Inter font-semibold ${
            active === slug ? "text-white" : "text-[#222]"
          }`}
        >
          {arText}
        </p>
      </div>
    </Container>
  );
};

export default SingleAllocationStepContainer;

const Container = styled.button`
  svg {
    color: ${(props) => (props.active ? "#fff" : "#222")};
  }
`;
