import React from "react";
import styled from "styled-components";

const OrderStatusFilter = ({
  selectedOption,
  onChangeFilter,
  toggleShowFilter,
  chosenQuery,
}) => {
  return (
    <div className="w-full">
      <div className="w-full relative">
        <FilterBtn
          tabIndex={1}
          isFilter={
            (chosenQuery?.status?.value !== "draft" &&
              chosenQuery?.status?.value !== "on-hold") ||
            chosenQuery?.date ||
            chosenQuery?.startTime
          }
          onClick={toggleShowFilter}
          className="w-full border border-gray-400 rounded-[100px] h-16 bg-white flex flex-row items-center justify-between px-4 font-medium text-xl font-Inter text-greyText"
          // className={
          //   showDropdown
          //     ? "w-full border border-primary rounded-[100px] h-20 bg-white flex flex-row items-center justify-between px-4 font-medium text-xl font-Inter text-greyText"
          //     : "w-full border border-gray-400 rounded-[100px] h-20 bg-white flex flex-row items-center justify-between px-4 font-medium text-xl font-Inter text-greyText"
          // }
        >
          <div className="flex flex-row">
            <div className="pr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M16.5 2.25H1.5L7.5 9.345V14.25L10.5 15.75V9.345L16.5 2.25Z"
                  stroke={
                    (chosenQuery?.status?.value !== "draft" &&
                      chosenQuery?.status?.value !== "on-hold") ||
                    chosenQuery?.date ||
                    chosenQuery?.startTime
                      ? "#fff"
                      : "#4B5563"
                  }
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p
              className={
                (chosenQuery?.status?.value !== "draft" &&
                  chosenQuery?.status?.value !== "on-hold") ||
                chosenQuery?.date ||
                chosenQuery?.startTime
                  ? "text-[1.4rem] font-Inter font-medium text-white"
                  : "text-[1.4rem] font-Inter font-medium text-gray-800"
              }
            >
              Filter
            </p>
            {/* <p className="text-[1.6rem] font-Inter font-semibold">
              {" "}
              &nbsp;&nbsp;{t(selectedOption?.textLg1)}
            </p>
            <p className="px-2"> {selectedOption?.textLg2}</p> */}
          </div>
          {/* <Icon name="chevron-down" /> */}
        </FilterBtn>
        {/* Dropdown */}
        {/* {showDropdown && (
          <div
            className={
              showDropdown
                ? "bg-white w-full absolute pb-2 max-h-96 overflow-y-auto pt-2 border border-primary rounded-md left-0 right-0 top-[100%] z-50 px-2"
                : "bg-white w-full absolute pb-2 max-h-96 overflow-y-auto pt-2 border border-gray-100 rounded-md left-0 right-0 top-[100%] z-50 px-2"
            }
          >
            {ORDERTYPES.map((item, index) => (
              <SingleMobileFilterSelect
                item={item}
                key={index}
                active={selectedOption}
                onChangeFilter={onChangeFilter}
                closeDropdown={() => setShowDropdown(false)}
              />
            ))}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default OrderStatusFilter;

const FilterBtn = styled.div`
  border-radius: 100px;
  border: 1px solid #222;
  background: ${(props) =>
    props.isFilter
      ? "linear-gradient(108deg, #222 46.55%, #F3781F 204.7%)"
      : "transparent"};
  padding: 1rem;
`;
