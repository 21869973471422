import React from "react";
import SingleAllocationStepContainer from "./SingleAllocationStepContainer";
import AllocationStepIndicator from "./AllocationStepIndicator";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AllocationStepContainer = ({ active, changeStep }) => {
  const { table } = useSelector((state) => state.createOrder);
  // const { isAddingService } = useSelector((state) => state.createOrder);
  const navigate = useNavigate();

  const createOrder = () => {
    if (table?.id === -1 && !table?.isAllocated) {
      toast.warning("Please select a table for this dine in order", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    navigate("/orders/create/dish-selection");
  };

  return (
    <div className="w-full pt-10">
      <div className="w-full flex flex-row items-center justify-between ">
        <div className="w-[20%]">
          <AllocationStepIndicator active={active} />
        </div>
        {/* Steps */}
        <div className="w-[80%]">
          <SingleAllocationStepContainer
            iconName="party-size"
            title="Party Size"
            arText="حجم الحفلة"
            active={active}
            slug="party-size"
            changeStep={() => changeStep("party-size")}
          />
          <SingleAllocationStepContainer
            iconName="table-allocation"
            title="Table Allocation"
            arText="تخصيص الجدول"
            active={active}
            slug="table-allocation"
            changeStep={() => changeStep("table-allocation")}
          />
          <SingleAllocationStepContainer
            iconName="invoice"
            title="Create Order"
            arText="إنشاء النظام"
            active={active}
            slug="create-order"
            changeStep={createOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default AllocationStepContainer;
