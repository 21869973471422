import SingleScreenNavBar from "components/common/navigationBar/SingleScreenNavBar";
import { categoryTopNavData } from "pages/OrdersPage/CheckoutPage/navData";
import React from "react";

const TopNavigationBar = () => {
  return (
    <div className="navigationBar">
      {categoryTopNavData.map((item, index) => (
        <SingleScreenNavBar key={index} {...item} />
      ))}
    </div>
  );
};

export default TopNavigationBar;
