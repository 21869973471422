import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  isOpen: true,
  deliverectModal: false,
  hideSideBar: false,
  hideHeader: false,
};

const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    toggleDrawer(state) {
      state.isOpen = !current(state).isOpen;
    },
    toggleDeliverectDrawer(state) {
      state.deliverectModal = !current(state).deliverectModal;
    },
    toggleHideSideBar(state, action) {
      state.hideSideBar = action.payload;
    },
    toggleHideHeader(state, action) {
      state.hideHeader = action.payload;
    },

    closeDrawer(state) {
      state.isOpen = true;
    },
  },
});

export const {
  toggleDrawer,
  toggleDeliverectDrawer,
  closeDrawer,
  toggleHideSideBar,
  toggleHideHeader,
} = drawerSlice.actions;
export default drawerSlice.reducer;
