import styled from "styled-components";
import classes from "./PrimaryButton.module.scss";
import Icon from "components/Icon/Icon";

const PrimaryButton = ({
  index,
  text,
  type,
  onClicked,
  customClasses,
  isDisabled,
  variant,
  iconName,
}) => {
  let styleClasses = classes.PrimaryButton;

  switch (type) {
    case "secondary":
      styleClasses = [styleClasses, classes.Secondary].join(" ");
      break;

    default:
      styleClasses = [styleClasses, classes.Primary].join(" ");
      break;
  }

  if (isDisabled) {
    styleClasses = [classes.PrimaryButton, classes.Disabled].join(" ");
  }

  if (customClasses) {
    styleClasses = [styleClasses, customClasses].join(" ");
  }

  return (
    <>
      {variant === "iconBtn" ? (
        <Btn>
          <button
            tabIndex={index}
            onClick={onClicked}
            className={styleClasses}
            disabled={isDisabled}
          >
            <span className="md:pr-2 lg:pr-4">
              <Icon name={iconName} />
            </span>
            {text}
          </button>
        </Btn>
      ) : (
        <Btn>
          <button
            tabIndex={index}
            onClick={onClicked}
            className={styleClasses}
            disabled={isDisabled}
          >
            {text}
          </button>
        </Btn>
      )}
    </>
  );
};

export default PrimaryButton;

const Btn = styled.div`
  svg {
    color: #fff;
  }
`;
