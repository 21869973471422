import Icon from "components/Icon/Icon";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import PrimaryInputField from "components/PrimaryInputField/PrimaryInputField";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TableServices } from "services/TableServices";
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index";
import { toast, ToastContainer } from "react-toastify";
import classes from "./AddTable.module.scss";
import Backdrop from "components/Backdrop/Backdrop";
import Loader from "components/Loader/Loader";
import { useDispatch } from "react-redux";
import { addTable } from "redux/table";
import { addSelectedZone } from "redux/zone";
import SingleZone from "./SingleZone";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";

const AddTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableNumber, setTableNumber] = useState("");
  const [seats, setSeats] = useState(2);
  const [shape, setShape] = useState("round");
  const {
    error: zonesError,
    zones,
    // selectedZone: selectedZoneName,
  } = useSelector((state) => state.zone);
  // const [selectedZone, setSelectedZone] = useState(zones?.[0]?.id);
  const [activeZone, setActiveZone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);

  const onProceedHandler = () => {
    setIsLoading(true);

    const table = {
      table_number: tableNumber,
      status: "",
      zone_id: activeZone?.id,
      no_of_seats: Number(seats),
      shape,
    };

    TableServices.createTable(
      table,
      (res) => {
        toast.success("Table addes successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        navigate("/floor");
        setIsLoading(false);
        dispatch(addTable(res.data.data));
      },
      (err) => {
        for (const key in err.response.data.errors) {
          toast.error(err.response.data.errors[key][0], {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setIsLoading(false);
      }
    );
  };

  const tableShapeChangeHandler = (e) => {
    setShape(e.target.value);
  };

  const verifyForm = () => {
    return (
      tableNumber.trim() !== "" &&
      !isNaN(seats) &&
      shape.trim() !== "" &&
      activeZone
    );
  };

  useEffect(() => {}, []);

  // useMemo(() => {
  //   if (selectedZoneName) {
  //     setSelectedZone(selectedZoneName);
  //   }
  // }, [selectedZoneName]);

  return (
    <GlobalPadding>
      <div className="w-full h-screen">
        {isLoading && (
          <Backdrop type="dark">
            <Loader />
          </Backdrop>
        )}

        <div className={classes.AddTable}>
          <h1 className={classes.Heading}>Add Table</h1>
          <PrimaryInputField
            mainLabel="Table Name"
            placeholder="Name of the table"
            value={tableNumber}
            onChange={(e) => setTableNumber(e.target.value)}
            customClasses={classes.TableNumberInput}
          />
          <div className={classes.TableShapes}>
            <label className={classes.TableRadioButton}>
              <input
                defaultChecked
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="round"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeRounded}></span>
            </label>
            <label className={classes.TableRadioButton}>
              <input
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="rectangle"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeRectangle}></span>
            </label>
            <label className={classes.TableRadioButton}>
              <input
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="square"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeSquare}></span>
            </label>
            <label className={classes.TableRadioButton}>
              <input
                onChange={tableShapeChangeHandler}
                type="radio"
                name="table-shape"
                value="circle"
              />
              <span className={classes.TableRadioButtonIcon}></span>
              <span className={classes.TableRadioButtonShapeCircle}></span>
            </label>
          </div>
          <PrimaryInputField
            type="number"
            mainLabel="How many seats do you want?"
            placeholder="Number of seats"
            value={seats}
            onChange={(e) => {
              e.target.value > 0 &&
                e.target.value < 13 &&
                setSeats(Number(e.target.value));
            }}
            customClasses={classes.SeatsInput}
          />
          <p className="text-gray-400 font-Inter mb-4 text-2xl">
            Select your zone
          </p>
          {!zonesError && zones.length > 0 && (
            <div className="w-full sm:w-[70%] lg:w-[60%] xl:w-[50%] relative border border-gray-200 rounded-md  px-5 h-16 cursor-pointer">
              <div
                onClick={() => setShowDropdown(!showDropdown)}
                className="w-full h-full rounded-md flex flex-row items-center justify-between  "
              >
                <p className="w-[90%] font-Inter text-lg md:text-xl font-medium text-greyText">
                  {activeZone?.name}
                </p>
                <Icon name="chevron-down" />
              </div>
              {showDropdown && (
                <div className="bg-white w-full absolute pb-2 max-h-96 overflow-y-auto pt-2 border border-gray-100 rounded-md left-0 right-0 top-[100%] z-50">
                  {zones.map((item, index) => (
                    <SingleZone
                      key={index}
                      {...item}
                      onClick={() => {
                        setShowDropdown(false);
                        setActiveZone(item);
                        dispatch(addSelectedZone(Number(item.id)));
                      }}
                      active={activeZone}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
          <div className={classes.Actions}>
            <PrimaryButton
              onClicked={() => navigate("/floor")}
              customClasses={classes.CancelBtn}
              text="Cancel"
            />
            <PrimaryButton
              onClicked={onProceedHandler}
              text="Proceed"
              isDisabled={!verifyForm()}
            />
          </div>
        </div>
        <ToastContainer />
      </div>
    </GlobalPadding>
  );
};

export default AddTable;

//  <div className={classes.SelectBox}>
//         <Icon name="chevron-down" />
//         <select
//           onChange={(e) => {
//             dispatch(addSelectedZone(Number(e.target.value)));
//             setActiveZone(Number(e.target.value));
//           }}
//           className={classes.SelectInput}
//           // defaultValue={selectedZone}
//         >
//           <option disabled>Select Zone</option>
//           {zones.map((zone) => {
//             return (
//               <option
//                 key={zone.name}
//                 value={zone.id}
//                 onChange={(e) => {
//                   console.log(e, "Changed");
//                 }}
//               >
//                 {zone.name}
//               </option>
//             );
//           })}
//         </select>
//       </div>
