import React from "react";
import OutletTenderView from "./OutletTenderView";
import OutletChannelView from "./OutletChannelView";

const ChannelsContainer = ({
  selectedTender,
  changeTenderHandler,
  selectedChannel,
  changeChannelHandler,
  dineIn,
  chooseDefaultTender,
  makeDefaultTender,
  defaultLoading,
  chooseDefaultChannel,
  makeDefaultChannel,
}) => {
  return (
    <div className="w-full h-full bg-gray-200 lg:px-4 py-8 lg:pr-4 pr-8 pl-4">
      {/* Tenders */}
      <OutletTenderView
        selectedTender={selectedTender}
        changeTenderHandler={changeTenderHandler}
        chooseDefaultTender={chooseDefaultTender}
        makeDefaultTender={makeDefaultTender}
        defaultLoading={defaultLoading}
      />
      {/* Channels */}
      {!dineIn && (
        <OutletChannelView
          selectedChannel={selectedChannel}
          changeChannelHandler={changeChannelHandler}
          defaultLoading={defaultLoading}
          chooseDefaultChannel={chooseDefaultChannel}
          makeDefaultChannel={makeDefaultChannel}
        />
      )}
    </div>
  );
};

export default ChannelsContainer;
