import React, { useEffect, useMemo, useState } from "react";
import ChannelsContainer from "./component/ChannelsContainer";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import useToggle from "hooks/useToggle";
import { useDefaultSetting } from "hooks/useDefaultSetting";

import { fetchAllChannels, setCheckoutChannel } from "redux/channel";
import { fetchAllTenders, setCheckoutTender } from "redux/tenders";
import OrderChannleScreenDetails from "./component/OrderChannleScreenDetails";
import { ResturantService } from "services/ResturantService";
import { toast } from "react-toastify";
import { toggleConfig } from "redux/app";
import ReceiptView from "./component/ReceiptView";
import ReceiptPageNavigationBarContainer from "components/common/navigationBar/receiptPageNavigationBar/ReceiptPageNavigationBarContainer";
import PaymentChannelNavigationBar from "components/common/navigationBar/receiptPageNavigationBar/PaymentChannelNavigationBar";
import { switchToCartEditing } from "redux/createOrder";
import useToggle from "hooks/useToggle";
import { useReceipt } from "./component/useReceipt";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";

const MainPaymentChannel = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTender, setSelectedTender] = useState({});
  const [selectedChannel, setSelectedChannel] = useState({});
  // const { order } = useSelector((state) => state.editOrder);
  const cartState = useSelector((state) => state.cart);
  const { channels } = useSelector((state) => state.channel);
  const { tenders } = useSelector((state) => state.tender);
  const [dineIn, setDineIn] = useState(false);
  const { default_tender, default_channel } = useDefaultSetting();
  const [defaultLoading, setDefaultLoading] = useState({
    channelLoading: false,
    tenderLoading: false,
  });

  const [makeDefaultChannel, setMakeDefaultChannel] = useState(false);
  const [makeDefaultTender, setMakeDefaultTender] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [showCancelModal, toggleCancelModal] = useToggle(false);
  const [orderChangeModal, toggleOrderChangeModal] = useToggle(false);
  const {
    chooseAmount,
    discountPriceReceipt,
    getAllSubTotalPrice,
    getSubTotalPrice,
    getTotalPrice,
    handleCompleteOrder,
    isLoading,
    isPaid,
    qrCode,
    return_change,
    totalCartPriceWithoutAnyCommission,
    amount,
    showDiscountReceipt,
    showChannel,
    getChannelFee,
    receiptRef,
    showTenderTax,
    tenderFee,
    handlePrient,
    markAsPaid,
  } = useReceipt({ selectedChannel, selectedTender });

  const order_id = useMemo(
    () => location?.state?.order_id,
    [location?.state?.order_id]
  );

  useEffect(() => {
    if (location && location.state?.showReceipt) {
      setShowReceipt(location.state?.showReceipt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.showReceipt]);

  useMemo(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useMemo(() => {
    if (cartState?.checkoutOrder.type === "dine in") {
      setDineIn(true);
    } else {
      setDineIn(false);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [cartState?.checkoutOrder.type]);
  // Getting the tenders and channel taxes when they load from API
  const tenderTax = useMemo(() => {
    if (tenders && tenders.length && default_tender && default_tender?.name) {
      return tenders.filter((item) => item?.name === default_tender?.name)[0]
        ?.order_tender_tax;
    }
  }, [tenders, default_tender]);
  const channelTax = useMemo(() => {
    if (
      channels &&
      channels.length &&
      default_channel &&
      default_channel?.name
    ) {
      return channels.filter((item) => item?.name === default_channel?.name)[0]
        ?.order_delivery_commission;
    }
  }, [channels, default_channel]);

  useEffect(() => {
    setSelectedChannel({
      ...default_channel,
      img: default_channel?.smallimage,
      channel_tax: channelTax ?? null,
    });
    dispatch(
      setCheckoutChannel({
        checkoutChannel: {
          ...default_channel,
          img: default_channel?.smallimage,
          channel_tax: channelTax ?? null,
        },
      })
    );

    setSelectedTender({
      ...default_tender,
      tender_tax: tenderTax ?? null,
    });
    dispatch(setCheckoutTender(default_tender));
    // selectDefault();
  }, [default_channel, default_tender, dispatch, channelTax, tenderTax]);

  const encodedOrderId = encodeURIComponent(order_id);

  // Getting the channel when order type does contain is dine in
  useEffect(() => {
    if (!dineIn) {
      dispatch(fetchAllChannels({ order_id: `${encodedOrderId}` }));
    }
    dispatch(fetchAllTenders({ order_id: `${encodedOrderId}` }));
  }, [dineIn, dispatch, encodedOrderId]);

  //   Change tender
  const changeTenderHandler = (newTender) => {
    if (selectedTender?.name === newTender?.name) {
      dispatch(setCheckoutTender(null));
      setSelectedTender({});
    } else {
      dispatch(setCheckoutTender(newTender));
      setSelectedTender(newTender);
    }
  };

  //   Change channel
  const changeChannelHandler = (newChannel) => {
    if (selectedChannel?.name === newChannel?.name) {
      dispatch(setCheckoutChannel({ checkoutChannel: null }));
      setSelectedChannel({});
    } else {
      dispatch(setCheckoutChannel({ checkoutChannel: newChannel }));
      setSelectedChannel(newChannel);
    }
  };

  const chooseDefaultChannel = () => {
    if (!selectedChannel) {
      toast.error("Please select a channel to set as default", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      setDefaultLoading({ ...defaultLoading, channelLoading: true });
      ResturantService.chooseDefaultServiceType(
        {
          type: "delivery_channel",
          value: `${selectedChannel?.id}`,
        },
        (res) => {
          setDefaultLoading({ ...defaultLoading, channelLoading: false });
          dispatch(toggleConfig(true));
          setMakeDefaultChannel(true);
          toast.success(
            res?.response?.data?.message ||
              `${selectedChannel?.name} has been set as your default channel.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        },
        (err) => {
          setDefaultLoading({ ...defaultLoading, channelLoading: false });
          toast.error(
            err?.response?.data?.message ||
              `Unable to set ${selectedChannel?.name} as your default channel.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      );
    }
  };
  const chooseDefaultTender = () => {
    if (!selectedTender || selectedTender?.tender_tax === null) {
      toast.error("Please select a tender to set as default", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      setDefaultLoading({ ...defaultLoading, tenderLoading: true });
      ResturantService.chooseDefaultServiceType(
        {
          type: "tender",
          value: `${selectedTender?.id}`,
        },
        (res) => {
          setDefaultLoading({ ...defaultLoading, tenderLoading: false });
          dispatch(toggleConfig(true));
          setMakeDefaultTender(true);
          toast.success(
            res?.response?.data?.message ||
              `${selectedTender?.name} has been set as your default tender.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        },
        (err) => {
          setDefaultLoading({ ...defaultLoading, tenderLoading: false });
          toast.error(
            err?.response?.data?.message ||
              `Unable to set ${selectedTender?.name} as your default tender.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      );
    }
  };

  //   Function to change the show receipt to user
  const proceedToPay = () => {
    if (!selectedTender?.name) {
      toast.warning("Please select a tender before proceeding", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (
      cartState?.checkoutOrder.type === "delivery" &&
      !selectedChannel?.name
    ) {
      toast.warning(
        "Please select a channel to deliver the order before proceeding",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
      return;
    }
    setShowReceipt(true);
  };

  //   Editing cart
  const onEditCart = async () => {
    dispatch(switchToCartEditing(true));
    navigate("/orders/create/dish-selection");
  };

  const completeCheckoutOrder = () => {
    if (selectedTender && selectedTender?.name === "cash") {
      markAsPaid();
      toggleOrderChangeModal(true);
      return;
    }
    markAsPaid();
    setTimeout(() => {
      handleCompleteOrder();
    }, 900);
  };

  return (
    <div className="w-full h-full grid grid-rows-[1fr_auto] overflow-y-auto overflow-x-hidden">
      <LoadingScreen show={isLoading} />
      <div className="w-full h-full grid lg:grid-cols-[60%_40%] gap-5 overflow-y-auto overflow-x-hidden">
        {!showReceipt && (
          <ChannelsContainer
            selectedTender={selectedTender}
            changeTenderHandler={changeTenderHandler}
            selectedChannel={selectedChannel}
            changeChannelHandler={changeChannelHandler}
            dineIn={dineIn}
            chooseDefaultTender={chooseDefaultTender}
            makeDefaultTender={makeDefaultTender}
            defaultLoading={defaultLoading}
            chooseDefaultChannel={chooseDefaultChannel}
            makeDefaultChannel={makeDefaultChannel}
          />
        )}
        {showReceipt && (
          <ReceiptView
            goBack={() => setShowReceipt(false)}
            toggleCancelModal={toggleCancelModal}
            showCancelModal={showCancelModal}
            selectedChannel={selectedChannel}
            selectedTender={selectedTender}
            orderChangeModal={orderChangeModal}
            toggleOrderChangeModal={toggleOrderChangeModal}
            // From here
            getAllSubTotalPrice={getAllSubTotalPrice}
            discountPriceReceipt={discountPriceReceipt}
            chooseAmount={chooseAmount}
            getSubTotalPrice={getSubTotalPrice}
            getTotalPrice={getTotalPrice}
            handleCompleteOrder={handleCompleteOrder}
            isLoading={isLoading}
            isPaid={isPaid}
            qrCode={qrCode}
            return_change={return_change}
            totalCartPriceWithoutAnyCommission={
              totalCartPriceWithoutAnyCommission
            }
            amount={amount}
            showDiscountReceipt={showDiscountReceipt}
            showChannel={showChannel}
            getChannelFee={getChannelFee}
            receiptRef={receiptRef}
            showTenderTax={showTenderTax}
            tenderFee={tenderFee}
          />
        )}

        <OrderChannleScreenDetails
          selectedChannel={selectedChannel}
          selectedTender={selectedTender}
          showReceipt={showReceipt}
        />
      </div>
      {location &&
        location?.pathname === "/orders/checkout/payment-channel" &&
        !showReceipt && (
          <PaymentChannelNavigationBar proceedToPay={proceedToPay} />
        )}
      {location &&
        location?.pathname === "/orders/checkout/payment-channel" &&
        showReceipt && (
          <ReceiptPageNavigationBarContainer
            onEditCart={onEditCart}
            onCancelModal={toggleCancelModal}
            printReceipt={handlePrient}
            completeCheckoutOrder={completeCheckoutOrder}
          />
        )}
    </div>
  );
};

export default MainPaymentChannel;
