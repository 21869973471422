import React from "react";
import OrderDetailHeader from "./component/OrderDetailHeader";
import OrderDetailsItems from "./component/OrderDetailsItems";
import OrderDetailFooter from "./component/OrderDetailFooter";

const OrderDetailModalView = ({
  clickedOrder,
  onEditCart,
  onProceedCart,
  fetchOrders,
  handleCloseModal,
}) => {
  return (
    <div className="w-full h-full bg-gray-100 px-[1.5rem] py-[1rem] grid grid-rows-[auto_1fr_auto]">
      <div className="w-full">
        <OrderDetailHeader clickedOrder={clickedOrder} />
        <div className="w-full flex flex-row items-center justify-center border border-gray-800 rounded-md  my-6 py-3">
          <p className="text-[1.4rem] text-gray-800 font-semibold capitalize">
            {clickedOrder && clickedOrder?.order_type}
          </p>
        </div>
      </div>

      {clickedOrder && <OrderDetailsItems clickedOrder={clickedOrder} />}
      {clickedOrder && (
        <OrderDetailFooter
          clickedOrder={clickedOrder}
          onEditCart={onEditCart}
          onProceedCart={onProceedCart}
          fetchOrders={fetchOrders}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default OrderDetailModalView;
