import SingleOrderDetailItem from "components/orderDetail/component/SingleOrderDetailItem";
import React from "react";
import { useSelector } from "react-redux";
import PaymentChannelOrderDetails from "./PaymentChannelOrderDetails";
import { cn } from "utillity/utils";

const OrderChannleScreenDetails = ({
  selectedChannel,
  selectedTender,
  showReceipt,
}) => {
  const cartState = useSelector((state) => state.cart);
  const editOrder = useSelector((state) => state.editOrder);

  return (
    <div
      className={cn(
        "w-full h-full bg-[#F3F4F6] px-4 py-4 pr-10 grid grid-rows-[auto_1fr]",
        selectedChannel && !selectedChannel?.name && "grid-rows-1 py-4 "
      )}
    >
      {selectedChannel &&
        selectedChannel?.name &&
        !showReceipt &&
        editOrder?.order?.order_type !== "dine in" && (
          <div className="w-full flex flex-center items-center justify-center border border-gray-800 rounded h-[4rem] max-h-[4rem]">
            <img
              src={selectedChannel?.smallimage}
              alt={selectedChannel.name}
              className="block max-h-[80%]"
            />
          </div>
        )}
      {(showReceipt || editOrder?.order?.order_type === "dine in") && (
        <div></div>
      )}
      <div
        className={cn("w-full grid grid-rows-[1fr_auto] h-full", [
          !selectedChannel?.name && "h-full",
          editOrder?.order?.order_type === "dine in" && "h-full",
        ])}
      >
        {!showReceipt ? (
          <div className="w-full mt-5 overflow-y-auto">
            {cartState?.cart &&
              cartState?.cart[cartState?.activeCart].map((item) => (
                <SingleOrderDetailItem key={item?.item_id} {...item} />
              ))}
          </div>
        ) : (
          <div></div>
        )}

        {/* Order price details */}
        <div className="w-full bg-white px-4 py-4">
          <PaymentChannelOrderDetails
            selectedChannel={selectedChannel}
            selectedTender={selectedTender}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderChannleScreenDetails;
