import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import React from "react";
import { useSelector } from "react-redux";
import DiscountCheckInput from "./DiscountCheckInput";

const MainDishFooter = () => {
  const { cart, activeCart } = useSelector((state) => state.cart);
  const { decimal_preference, currency, taxEnabled, tax } =
    useGetActiveLocation();
  const { orderDiscountType } = useSelector((state) => state.createOrder);
  const { selectedOrders } = useSelector((state) => state.editOrder);

  const getActiveTabTotalPrice = () => {
    let price = 0;
    if (cart && cart[activeCart]?.length > 0) {
      for (const item of cart[activeCart]) {
        price += +item?.price * +item?.quantity;
      }
      return price;
    }
    return 0;
  };

  //   Get total discount
  const activeTotalDiscount = () => {
    let discount = 0;
    if (
      cart &&
      cart[activeCart]?.length > 0 &&
      orderDiscountType === "discount_by_item"
    ) {
      cart[activeCart]?.forEach((item) => {
        if (item?.discount_type === "fixed") {
          discount += item?.discount_value;
        } else if (item?.discount_type === "percentage") {
          const tot = item?.quantity * item?.price;
          const discountTot =
            (Number(tot) * Number(item?.discount_value)) / 100;
          discount += discountTot;
        }
      });
      return discount;
    } else if (
      cart &&
      cart[activeCart]?.length > 0 &&
      orderDiscountType === "discount_by_cheque"
    ) {
      console.log("nah me");
      const activeOrder = selectedOrders.filter(
        (item) => item?.type === activeCart
      )[0];
      console.log(activeOrder, "ORDER");
      if (activeOrder?.discount_type === "fixed") {
        discount += activeOrder?.discount_value ?? 0;
      } else {
        const discountTot =
          (Number(getActiveTabTotalPrice()) *
            Number(activeOrder?.discount_value) ?? 0) / 100;
        discount += discountTot;
      }
      return discount;
    }
    return 0;
  };

  console.log(activeTotalDiscount(), "discount");

  const taxPrice = (Number(getActiveTabTotalPrice()) * Number(tax)) / 100;

  const paymentAfterTax = Number(getActiveTabTotalPrice()) + taxPrice;

  const totalPrice =
    taxPrice + Number(getActiveTabTotalPrice()) - activeTotalDiscount();

  return (
    <div className="w-full  py-6 pr-5  xl:pr-8 lg:pr-8">
      <div className="w-full bg-white py-6 px-5">
        {/* Sub total */}
        <div className="w-full flex flex-row items-center justify-between">
          <p className="text-textColor text-[1.4rem] font-Inter font-medium">
            Subtotal
          </p>
          <p className="text-textColor text-[1.4rem] font-semibold">
            {currency}&nbsp;
            {getActiveTabTotalPrice().toFixed(decimal_preference)}
          </p>
        </div>
        {/* Tax */}
        {taxEnabled && (
          <div className="w-full flex flex-row items-center justify-between mt-3">
            <p className="text-textColor text-[1.4rem] font-Inter font-medium">
              Tax
            </p>
            <p className="text-textColor text-[1.4rem] font-semibold">
              {currency}&nbsp;{taxPrice.toFixed(decimal_preference)}
            </p>
          </div>
        )}
        {/* Payment after tax */}
        <div className="w-full flex flex-row items-center justify-between mt-3">
          <p className="text-textColor text-[1.4rem] font-Inter font-medium">
            Payment after Tax
          </p>
          <p className="text-textColor text-[1.4rem] font-semibold">
            {currency}&nbsp;
            {paymentAfterTax.toFixed(decimal_preference)}
          </p>
        </div>
        {/* Discount */}
        {activeTotalDiscount() > 0 &&
          orderDiscountType === "discount_by_item" && (
            <div className="w-full flex flex-row items-center justify-between mt-3">
              <p className="text-textColor text-[1.4rem] font-Inter font-medium">
                Discount
              </p>
              <p className="text-textColor text-[1.4rem] font-semibold">
                {currency}&nbsp;
                {activeTotalDiscount().toFixed(decimal_preference)}
              </p>
            </div>
          )}

        {/* Cheque Discount */}
        {orderDiscountType === "discount_by_cheque" && <DiscountCheckInput />}

        <div className="w-full flex flex-row items-center justify-between mt-3 border-t border-t-gray-200 pt-2">
          <p className="text-textColor text-[1.4rem] font-Inter font-medium">
            Payable Amount
          </p>
          <p className="text-textColor text-[1.4rem] font-semibold">
            {currency}&nbsp;{totalPrice.toFixed(decimal_preference)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainDishFooter;

// {
//   orderDiscountType === "discount_by_cheque" && (
//     <div className="w-full mb-1 bg-[#FFF] rounded py-5 flex flex-row items-center justify-end px-6 ">
//       {/* <span className="font-Inter text-xl text-textColor">Add</span> */}
//       <div className="flex flex-row items-center justify-end">
//         <span className="font-Inter text-xl text-gray-700 font-medium cursor-pointer">
//           Add cheque discount
//         </span>
//       </div>
//     </div>
//   );
// }
