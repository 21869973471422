import React from "react";
import PosOrder from "./PosOrder";
import PosLiveOrder from "./PosLiveOrder";
import { cn } from "utillity/utils";

const SinglePosOrder = ({
  selectedOrderType,
  filterOrderType,
  item,
  showOrder,
  clickedOrder,
}) => {
  return (
    <div
      role="button"
      tabIndex={1}
      onClick={() => showOrder(item)}
      className={cn(
        "w-full border border-gray-200 rounded-md px-7 py-5 h-[20rem] max-h-[23rem]",
        [clickedOrder?.id === item?.id && "border-gray-800"]
      )}
    >
      {filterOrderType?.value !== "live-orders" && (
        <PosOrder
          selectedOrderType={selectedOrderType}
          filterOrderType={filterOrderType}
          item={item}
          clickedOrder={clickedOrder}
        />
      )}
      {filterOrderType?.value === "live-orders" && <PosLiveOrder />}
    </div>
  );
};

export default SinglePosOrder;
