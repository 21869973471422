import React from "react";
import { cn } from "utillity/utils";
import { useLocation, useNavigate } from "react-router-dom";

const ButtonSingleScreenNavBar = ({
  title,
  nav,
  hide,
  href,
  onClick,
  btn,
  active,
}) => {
  const navigate = useNavigate();
  let location = useLocation();

  const onPress = () => {
    if (href && nav) {
      navigate(href);
      return;
    }
    if (!href && !nav && onClick) {
      onClick();
      return;
    }
  };
  return (
    <button
      onClick={onPress}
      className={cn(
        "w-full flex flex-row items-center justify-center border border-gray-800 lg:h-[12rem] h-[8rem]",
        [hide && "border-0"],
        [nav && href && href === location?.pathname && "bg-[#222]"],
        [btn && active && "bg-[#222]"]
      )}
    >
      {title && nav && (
        <span
          className={cn(
            "lg:text-[1.4rem] text-[1.2rem] font-Inter font-semibold text-gray-800 text-center max-w-[70%] inline-block",
            [href && href === location?.pathname && "text-white"]
          )}
        >
          {title}
        </span>
      )}
      {title && btn && (
        <span
          className={cn(
            "lg:text-[1.4rem] text-[1.2rem] font-Inter font-semibold text-gray-800 text-center max-w-[70%] inline-block",
            [btn && active && "text-white"]
          )}
        >
          {title}
        </span>
      )}
      {!title && !nav && !hide && (
        <span className="text-[1.4rem] font-Inter font-semibold text-gray-800">
          ...
        </span>
      )}
    </button>
  );
};

export default ButtonSingleScreenNavBar;
