import React from "react";
import Icon from "components/Icon/Icon";
import styled from "styled-components";
// import { useSelector } from "react-redux";
import { MdOutlineAdd, MdOutlineClose } from "react-icons/md";
import { activeServiceIcon } from "utillity/utils";

const MainSingleServices = ({
  icon,
  engText,
  arabicText,
  onClick,
  isSelected,
  singleOrderSelection,
  // isLocked,
}) => {
  let activeStyles = "";
  // const { orderTypes } = useSelector((state) => state.createOrder);

  switch (isSelected) {
    case true:
      activeStyles =
        "w-full rounded border-[2px] border-primary h-48 flex flex-row items-center px-8 cursor-pointer mb-5";
      break;

    default:
      activeStyles =
        "w-full rounded border-[2px] border-gray-400 h-48 flex flex-row items-center px-8 cursor-pointer mb-5";
      break;
  }

  return (
    <Container
      isSelected={isSelected}
      className={activeStyles}
      onKeyDown={(e) => {
        if (e.code === "Enter") {
          onClick(engText);
        }
      }}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <div className="xl:w-[50%] md:w-[60%] flex flex-row items-center justify-between ">
          {!isSelected && (
            <button
              onClick={() => onClick(engText)}
              className="w-[60px] h-[60px] rounded-full flex flex-row items-center justify-center cursor-pointer border border-gray-400"
            >
              <MdOutlineAdd className="text-5xl text-gray-700" />
            </button>
          )}

          <button
            onClick={() => singleOrderSelection(engText)}
            className="flex flex-row items-center"
          >
            {isSelected ? (
              <> {activeServiceIcon(icon)}</>
            ) : (
              <Icon
                name={icon}
                height={1.6}
                width={1.6}
                className="fill-primary text-primary"
              />
            )}

            <div className="ml-5 ">
              <p
                className={`font-Inter text-3xl font-semibold capitalize ${
                  isSelected ? "text-primary" : "text-gray-600"
                }`}
              >
                {engText}
              </p>
              <p
                className={`font-Inter text-xl font-regular pt-1 ${
                  isSelected ? "text-primary" : "text-gray-600"
                }`}
              >
                {arabicText}
              </p>
            </div>
          </button>
        </div>
        {isSelected && (
          <button
            onClick={() => onClick(engText)}
            className="w-[60px] h-[60px] rounded-full flex flex-row items-center justify-center cursor-pointer border border-gray-400"
          >
            <MdOutlineClose className="text-5xl text-gray-700" />
          </button>
        )}
      </div>
    </Container>
  );
};

export default MainSingleServices;
const Container = styled.div`
  /* svg {
    color: ${(props) => (props.isSelected ? "#f3781f" : "#4B5563")};
  } */
`;

//   <div className="w-12 h-12 rounded-full bg-white flex flex-row items-center justify-center">
//     {orderTypes.includes(engText) ? (
//       <Icon
//         name="check"
//         height={1.6}
//         width={1.6}
//         className="fill-primary text-primary"
//       />
//     ) : (
//       <Icon
//         name={icon}
//         height={1.6}
//         width={1.6}
//         className="fill-primary text-primary"
//       />
//     )}
//   </div>;
