import React from "react";
import { cn } from "utillity/utils";
import { useLocation, useNavigate } from "react-router-dom";

const SingleScreenNavBar = ({ title, nav, hide, href, hideDot }) => {
  // const { title, nav, hide, href } = item;
  const navigate = useNavigate();
  let location = useLocation();
  return (
    <button
      onClick={href && nav ? () => navigate(href) : null}
      className={cn(
        "w-full flex flex-row items-center justify-center border border-gray-800 lg:h-[12rem] h-[8rem]",
        [hide && "border-0"],
        [href && href === location?.pathname && "bg-[#222]"]
      )}
    >
      {title && nav && (
        <span
          className={cn(
            "lg:text-[1.4rem] text-[1.2rem] font-Inter font-semibold text-gray-800",
            [href && href === location?.pathname && "text-white"]
          )}
        >
          {title}
        </span>
      )}
      {!title && !nav && !hide && !hideDot && (
        <span className="text-[1.4rem] font-Inter font-semibold text-gray-800">
          ...
        </span>
      )}
    </button>
  );
};

export default SingleScreenNavBar;
