import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleDishItem from "./SingleDishItem";
import OrderDiscountModal from "components/Cart/orderDiscountModal/OrderDiscountModal";
import OrderItemNoteModal from "components/Cart/orderItemNoteModal/OrderItemNoteModal";
import { updateItemDiscountInCart, updateItemNoteInCart } from "redux/cart";

const MainDishItems = ({
  dishView,
  onRemove,
  onReduceItemQuantity,
  onIncreaseItemQuantity,
  changeClickedNote,
  clickedNoteItem,
  openNoteView,
}) => {
  const cartState = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    noteModal: false,
    discountModal: false,
  });
  const [discount, setDiscount] = useState({
    type: "percentage",
    value: "",
    order_note: "",
    activeItem: null,
  });
  const [itemNote, setItemNote] = useState({
    activeItem: null,
    showItemNoteModal: false,
    itemNote: "",
  });
  useEffect(() => {
    if (itemNote && itemNote.activeItem) {
      setItemNote({
        ...itemNote,
        itemNote: itemNote.activeItem?.notes ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemNote.activeItem]);
  return (
    <div className="w-full mt-4 xl:pr-8 lg:pr-8">
      {cartState && cartState?.cart[cartState?.activeCart]?.length > 0 && (
        <div className="w-full space-y-3 mt-2">
          {cartState?.cart[cartState?.activeCart]?.map((item, index) => (
            <SingleDishItem
              key={index}
              onClick={() => null}
              item={item}
              dishView={dishView}
              index={index + 1}
              openNoteView={openNoteView}
              onIncreaseItemQuantity={onIncreaseItemQuantity}
              onReduceItemQuantity={onReduceItemQuantity}
              onRemove={onRemove}
              changeClickedNote={changeClickedNote}
              noteClicked={() => {
                setItemNote({
                  ...itemNote,
                  activeItem: item,
                  showItemNoteModal: true,
                });
              }}
              discountClicked={() => {
                setModal({
                  ...modal,
                  discountModal: !modal.discountModal,
                });
                setDiscount({
                  ...discount,
                  activeItem: item,
                  value: item?.discount_value,
                  type: item?.discount_type,
                });
              }}
            />
          ))}
        </div>
      )}

      <OrderItemNoteModal
        showModal={itemNote.showItemNoteModal}
        onProceed={() => {
          dispatch(
            updateItemNoteInCart({
              type: cartState?.activeCart,
              note: itemNote?.itemNote,
              name: itemNote?.activeItem?.item_name,
            })
          );
          setItemNote({
            ...itemNote,
            activeItem: "",
          });
          setItemNote({
            ...itemNote,
            showItemNoteModal: !itemNote.showItemNoteModal,
          });
        }}
        toggleModal={() =>
          setItemNote({
            ...itemNote,
            showItemNoteModal: !itemNote.showItemNoteModal,
          })
        }
        value={itemNote.itemNote}
        onChange={(val) => setItemNote({ ...itemNote, itemNote: val })}
        placeholder="Write your note for the item"
        disabled={!itemNote.itemNote}
      />
      <OrderDiscountModal
        showModal={modal.discountModal}
        toggleModal={() =>
          setModal({ ...modal, discountModal: !modal.discountModal })
        }
        disabled={!discount.value}
        value={discount.value}
        onChange={(val) => setDiscount({ ...discount, value: val })}
        discount_type={discount.type}
        changeDiscountType={(val) => setDiscount({ ...discount, type: val })}
        activeItem={discount?.activeItem}
        onProceed={() => {
          setModal({ ...modal, discountModal: !modal.discountModal });
          dispatch(
            updateItemDiscountInCart({
              type: cartState?.activeCart,
              name: discount?.activeItem?.item_name,
              discount_value: discount?.value,
              discount_type: discount?.type,
            })
          );
          setDiscount({ ...discount, type: "", value: "", activeItem: null });
        }}
      />
    </div>
  );
};

export default MainDishItems;
