import React from "react";
import ButtonSingleScreenNavBar from "../ButtonSingleScreenNavBar";

const PaymentChannelNavigationBar = ({ proceedToPay }) => {
  return (
    <div className="w-full grid grid-cols-7">
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={true}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title="Proceed to pay"
        hide={false}
        href={null}
        nav={null}
        btn={true}
        onClick={proceedToPay}
      />
    </div>
  );
};

export default PaymentChannelNavigationBar;
