import CancelOrderModal from "components/cancelOrderModal/CancelOrderModal";
import { AuthContext } from "context/authContext";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import { MdOutlineChevronLeft } from "react-icons/md";
import axios from "axios";
import { toast } from "react-toastify";
import { clearCheckoutChannel } from "redux/channel";
import { useDispatch, useSelector } from "react-redux";
import MainReceipt from "components/receipt/MainReceipt";
import CheckoutReceiptChangeModal from "components/orderChangeCalcModal/CheckoutReceiptChangeModal";

const ReceiptView = ({
  goBack,
  toggleCancelModal,
  showCancelModal,
  selectedChannel,
  // selectedTender,
  toggleOrderChangeModal,
  orderChangeModal,
  handleCompleteOrder,
  qrCode,
  isPaid,
  amount,
  chooseAmount,
  // getAllSubTotalPrice,
  getSubTotalPrice,
  discountPriceReceipt,
  showDiscountReceipt,
  getTotalPrice,
  showChannel,
  getChannelFee,
  return_change,
  receiptRef,
  showTenderTax,
  tenderFee,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const { restaurantId, locationId, decimal_preference, taxEnabled } =
    useGetActiveLocation();
  const cartState = useSelector((state) => state.cart);
  const [orderCancelReason, setOrderCancelReason] = useState("");
  const { token, manualLogout } = useContext(AuthContext);
  const [errorReason, setErrorReason] = useState({
    error: false,
    errorText: "",
  });

  // Main cancel order function
  const handleCancelOrder = () => {
    setErrorReason({
      ...errorReason,
      error: false,
      errorText: "",
    });
    if (!orderCancelReason) {
      setErrorReason({
        ...errorReason,
        error: true,
        errorText: "Please provide reason for cancelling",
      });
    } else {
      // toggleCancelModal();
      setIsCancelLoading(true);
      const body = {
        restaurant_id: restaurantId,
        location_id: locationId,
        status: "cancel",
        order_no: cartState?.checkoutOrder?.order_no,
        reason: orderCancelReason,
        receipt_amount: getTotalPrice(),
        paid_amount: Number(amount),
        return_change: Number(
          Number(return_change).toFixed(decimal_preference)
        ),
      };
      axios
        .post("/order/status", body, {
          headers: {
            Authorization: `Bearer ${token}`,
            Location: locationId,
          },
        })
        .then((res) => {
          setOrderCancelReason("");
          toggleCancelModal();
          setIsCancelLoading(false);
          toast.success(res.data.message || "Order has been canceled.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setTimeout(() => {
            navigate("/orders");
            dispatch(clearCheckoutChannel());
          }, 1000);
        })
        .catch((err) => {
          setIsCancelLoading(false);
          toggleCancelModal();
          if (err && err?.response && err.response.status === 401) {
            manualLogout();
          } else if (
            err &&
            err?.response &&
            err?.response?.data?.message?.order_no
          ) {
            const message = err?.response?.data?.message?.order_no[0];
            toast.error(message || "Unable to cancel order!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error(
              err.response.data.message || "Unable to cancel order!",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }
        });
    }
  };

  return (
    <div className="w-full h-full bg-gray-200 lg:px-4 py-8 lg:pr-4 pr-8 pl-4 overflow-y-auto">
      <button
        className="flex flex-row w-full items-center mb-4"
        onClick={goBack}
      >
        <MdOutlineChevronLeft className="text-[2rem] text-gray-800" />
        <span className="text-[1.3rem] text-gray-700">Back to channels</span>
      </button>
      <div className="w-full bg-white rounded" ref={receiptRef}>
        <MainReceipt
          // activeTab={activeTab}
          getSubTotalPrice={getSubTotalPrice}
          getChannelPrice={getChannelFee}
          getTotalPrice={getTotalPrice}
          isPaid={isPaid}
          showChannel={showChannel}
          showTax={taxEnabled}
          showDiscount={showDiscountReceipt}
          getDiscount={discountPriceReceipt}
          qrCode={qrCode}
          isDuplicate={false}
          showTenderTax={showTenderTax}
          tenderFee={tenderFee}
        />
      </div>

      {/* Cancel order modal */}
      <CancelOrderModal
        showModal={showCancelModal}
        toggleModal={toggleCancelModal}
        value={orderCancelReason}
        onChange={(val) => setOrderCancelReason(val)}
        placeholder="Give us your reason for cancelling order"
        selectedChannel={selectedChannel && selectedChannel}
        onProceed={() => {
          handleCancelOrder();
        }}
        error={errorReason.error}
        errorText={errorReason.errorText}
        onFocus={() => setErrorReason({ error: false, errorText: "" })}
        isLoading={isCancelLoading}
      />
      {/* Change calculator modal */}
      <CheckoutReceiptChangeModal
        toggleModal={() => toggleOrderChangeModal(!orderChangeModal)}
        showModal={orderChangeModal}
        orderTotal={getTotalPrice()}
        completeOrder={handleCompleteOrder}
        amount={amount}
        changeUserAmount={(val) => chooseAmount(val)}
      />
    </div>
  );
};

export default ReceiptView;
