import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { increaseItemInCartInput } from "redux/cart";
import styled from "styled-components";

const SingleDishItemQuantityView = ({
  item,
  index,
  // dishView,
  // noteClicked,
  // discountClicked,
  onRemove,
  onReduceItemQuantity,
  onIncreaseItemQuantity,
}) => {
  const dispatch = useDispatch();

  const mainQuantity = useMemo(() => {
    if (Number(item?.quantity) === 0) {
      return 1;
    } else {
      return Number(item?.quantity);
    }
  }, [item?.quantity]);

  const returnedQuantity = (val) => {
    if (Number(val) === 0) {
      return 1;
    } else {
      return Number(val);
    }
  };
  return (
    <div className="w-full">
      <div className="w-full border-b border-b-gray-400 px-4 grid grid-rows-[1fr_auto] pb-3 pt-2">
        <div className="">
          <p className="text-[1.8rem] text-gray-600 font-semibold break-words text-left">
            {item?.item_name}
          </p>
          {item.translations &&
            item.translations?.ar &&
            item.translations?.ar?.name && (
              <p className="text-[1.4rem] text-gray-500 italic font-normal break-words capitalize text-left pt-1">
                {item.translations?.ar?.name}
              </p>
            )}
          {item?.sizes && item?.sizes.length > 0 && (
            <p className="text-[1.4rem] text-gray-500 italic font-normal break-words capitalize text-left pt-1">
              {item?.sizes[0]?.size}
            </p>
          )}
        </div>
        <div className=" flex flex-row items-center justify-end">
          <div className="flex flex-row items-center">
            <button
              onClick={
                item?.quantity === 1
                  ? () => onRemove(index - 1, item?.item_id, item?.cat_id)
                  : () =>
                      onReduceItemQuantity(index, item?.item_id, item?.cat_id)
              }
              className="bg-gray-200 rounded border border-gray-500 flex flex-row items-center justify-center w-[3.6rem] h-[3rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="2"
                viewBox="0 0 16 2"
                fill="none"
              >
                <path
                  d="M15 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2H15C15.2652 2 15.5196 1.89464 15.7071 1.70711C15.8946 1.51957 16 1.26522 16 1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0Z"
                  fill="#222222"
                />
              </svg>
            </button>
            <InputContainer className="w-[5rem] h-full">
              <input
                type="number"
                aria-label="quantity-input"
                value={mainQuantity}
                min="1"
                onChange={(e) =>
                  dispatch(
                    increaseItemInCartInput({
                      itemIndex: index,
                      value: returnedQuantity(e.target.value),
                    })
                  )
                }
                className="w-full h-full focus:outline-none bg-transparent font-bold font-Inter text-[2.5rem] text-center flex flex-row items-center justify-center"
              />
            </InputContainer>
            <button
              onClick={() =>
                onIncreaseItemQuantity(index, item?.item_id, item?.cat_id)
              }
              className="bg-gray-200 rounded border border-gray-500 flex flex-row items-center justify-center w-[3.6rem] h-[3rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M15 7H9V1C9 0.734784 8.89464 0.48043 8.70711 0.292893C8.51957 0.105357 8.26522 0 8 0C7.73478 0 7.48043 0.105357 7.29289 0.292893C7.10536 0.48043 7 0.734784 7 1V7H1C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C0 8.26522 0.105357 8.51957 0.292893 8.70711C0.48043 8.89464 0.734784 9 1 9H7V15C7 15.2652 7.10536 15.5196 7.29289 15.7071C7.48043 15.8946 7.73478 16 8 16C8.26522 16 8.51957 15.8946 8.70711 15.7071C8.89464 15.5196 9 15.2652 9 15V9H15C15.2652 9 15.5196 8.89464 15.7071 8.70711C15.8946 8.51957 16 8.26522 16 8C16 7.73478 15.8946 7.48043 15.7071 7.29289C15.5196 7.10536 15.2652 7 15 7Z"
                  fill="#222222"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDishItemQuantityView;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
