import { Outlet } from "../../../../node_modules/react-router-dom/dist/index";
import CheckoutNavigationLayout from "./CheckoutNavigationLayout";

const CheckoutPage = () => {
  return (
    <CheckoutNavigationLayout>
      <Outlet />
    </CheckoutNavigationLayout>
  );
};

export default CheckoutPage;
