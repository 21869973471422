import { SHIFT_VIEW_PERMISSION } from "constants/constants";
import NoViewPermission from "pages/OrdersPage/AllOrdersPage/components/NoViewPermission";
import MainShift from "./MainShift";
import { usePermissionHooks } from "hooks/usePermissionHooks";

const DefaultScreen = () => {
  const { canView: Shift } = usePermissionHooks(SHIFT_VIEW_PERMISSION);

  return (
    <div className="w-full h-full">
      {!Shift ? <NoViewPermission /> : <MainShift />}
    </div>
  );
};

export default DefaultScreen;
