import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleDiscountType } from "redux/createOrder";
import { discountTypeData } from "utillity/productionData";
import { cn } from "utillity/utils";
import { MdOutlineChevronLeft } from "react-icons/md";
import { resetItemDiscountInCart } from "redux/cart";
import { updateSelectedOrdersDiscountBy } from "redux/editingOrder";

const DiscountTypeView = ({ changeDishView }) => {
  const { orderDiscountType, orderTypes } = useSelector(
    (state) => state.createOrder
  );
  const { activeCart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  return (
    <div className="w-full bg-[#F3F4F6] grid grid-rows-[auto_1fr] h-full lg:px-4 py-8 lg:pr-10 pr-8 pl-4  overflow-y-auto pb-[0.5rem] xl:pb-[1.5rem]">
      {/* Header */}
      <div className="w-full">
        <button
          className="flex flex-row w-full items-center mb-4"
          onClick={() => changeDishView("quantity")}
        >
          <MdOutlineChevronLeft className="text-[2rem] text-gray-500" />
          <span className="text-[1.3rem] text-gray-500">
            Back Item quantity
          </span>
        </button>
        <div className="w-full flex flex-row items-center justify-between">
          <h4 className="text-textColor font-Inter text-[1.6rem] font-semibold">
            Select Discount Type
          </h4>
          <h4 className="text-textColor font-Inter text-[1.6rem] font-semibold">
            فئات
          </h4>
        </div>
      </div>
      {/* Discount Types */}
      <div className="w-full grid lg:grid-cols-2 grid-cols-2 xl:grid-cols-2 gap-5 mt-5">
        {discountTypeData.map((item) => (
          <button
            key={item?.value}
            onClick={() => {
              changeDishView("discount");
              dispatch(toggleDiscountType(item?.value));
              if (item?.value === "discount_by_cheque") {
                dispatch(
                  resetItemDiscountInCart({ arrOrderTypes: orderTypes })
                );
                dispatch(
                  updateSelectedOrdersDiscountBy({
                    discount_by: item?.slug,
                    orderType: activeCart,
                  })
                );
              }
            }}
            className={cn(
              "w-full rounded-md bg-white flex flex-col items-center justify-center h-[12rem] font-medium text-gray-800 text-[1.4rem]",
              [orderDiscountType === item.value && "bg-black text-white"]
            )}
          >
            {item?.label}
            <span className="pt-4">{item.ar}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default DiscountTypeView;
