import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaPercent } from "react-icons/fa";
import styled from "styled-components";
import { MdOutlineCheck } from "react-icons/md";
import { IoChevronDown } from "react-icons/io5";
import { cn } from "utillity/utils";
import { updateSelectedOrdersForDiscount } from "redux/editingOrder";
import { orderServiceDiscountType } from "utillity/productionData";

const DiscountCheckInput = () => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const { selectedOrders } = useSelector((state) => state.editOrder);
  const { activeCart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const activeOrder = useMemo(() => {
    return selectedOrders.filter((item) => item?.type === activeCart)[0];
  }, [activeCart, selectedOrders]);

  return (
    <div className="w-full flex flex-row items-center justify-between mt-3">
      <p className="text-textColor text-[1.4rem] font-Inter font-medium">
        Discount ({activeOrder?.discount_type})
      </p>
      <div className="h-14 w-[70px] rounded border border-gray-200 flex flex-row items-center relative">
        <InputContainer className="w-[50%] h-full pl-2 ">
          <input
            className="outline-none focus:outline-none bg-transparent w-full h-full text-xl font-Inter text-primary"
            type="number"
            id="item-discount"
            value={activeOrder ? activeOrder?.discount_value : 0}
            onChange={(e) => {
              dispatch(
                updateSelectedOrdersForDiscount({
                  type: activeCart,
                  discount_value: Number(e.target.value),
                  discount_type: activeOrder?.discount_type,
                })
              );
            }}
            required={true}
            // onFocus={onFocus}
            // autoFocus={true}
            min={0}
          />
        </InputContainer>
        <div
          onClick={() => setToggleDropdown(!toggleDropdown)}
          className={cn(
            "w-[40%] h-full flex flex-row items-center cursor-pointer space-x-2",
            [
              activeOrder &&
                activeOrder?.discount_type !== "percentage" &&
                "justify-end",
            ]
          )}
        >
          {activeOrder && activeOrder?.discount_type === "percentage" && (
            <FaPercent className="text-primary text-7xl block" />
          )}

          {/* <p className="text-2xl text-primary">%</p> */}
          <IoChevronDown
            className={cn("text-gray-800 text-[80px] block", [
              activeOrder &&
                activeOrder?.discount_type !== "percentage" &&
                "text-[16px]",
            ])}
          />
        </div>
        {/* Dropdown container */}
        {toggleDropdown && (
          <DiscountDropdownContainer className="absolute right-0  rounded-md h-[90px] w-[250px] z-[999]">
            {orderServiceDiscountType.map((discount, index) => (
              <div
                key={index}
                onClick={() => {
                  dispatch(
                    updateSelectedOrdersForDiscount({
                      type: activeCart,
                      discount_value: activeOrder?.discount_value,
                      discount_type: discount?.slug,
                    })
                  );
                  setToggleDropdown(!toggleDropdown);
                }}
                className={
                  activeOrder?.discount_type === discount?.slug
                    ? "w-full h-[45px] flex flex-row items-center justify-between bg-gray-100 hover:bg-gray-100 cursor-pointer px-4"
                    : "w-full h-[45px] flex flex-row items-center justify-between hover:bg-gray-100 cursor-pointer px-4"
                }
              >
                <p className="text-2xl text-gray-600 font-Inter">
                  {discount?.title}
                </p>
                {activeOrder?.discount_type === discount?.slug && (
                  <MdOutlineCheck className="text-4xl text-primary" />
                )}
              </div>
            ))}
          </DiscountDropdownContainer>
        )}
      </div>
    </div>
  );
};

export default DiscountCheckInput;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

const DiscountDropdownContainer = styled.div`
  background: #ffffff;
  border: 1px solid #f2f4f7;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  bottom: 40px;
`;
