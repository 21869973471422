import React from "react";
import SinglePosOrderHeader from "./SinglePosOrderHeader";
import SinglePosOrderBody from "./SinglePosOrderBody";
import PosOrderFooter from "./PosOrderFooter";

const PosOrder = ({
  filterOrderType,
  selectedOrderType,
  item,
  clickedOrder,
}) => {
  return (
    <div className="w-full grid grid-rows-[1fr_auto] h-full">
      <div className="w-full border-b border-b-gray-300 pb-4">
        {/* Header */}
        <SinglePosOrderHeader
          selectedOrderType={selectedOrderType}
          filterOrderType={filterOrderType}
          item={item}
        />
        {/* Body */}
        <SinglePosOrderBody
          selectedOrderType={selectedOrderType}
          filterOrderType={filterOrderType}
          item={item}
        />
      </div>
      <PosOrderFooter
        selectedOrderType={selectedOrderType}
        filterOrderType={filterOrderType}
        item={item}
        clickedOrder={clickedOrder}
      />
    </div>
  );
};

export default PosOrder;
