import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ImSpinner2 } from "react-icons/im";
// import { useDebounce } from "use-debounce";
import { ResturantService } from "services/ResturantService";
import { useSelector } from "react-redux";
import { AuthContext } from "context/authContext";
import { toast } from "react-toastify";

// const API_NOT_FOUND_MESSAGE = "No customer found against that number";
const UserInformation = ({
  setUserInput,
  customerDetail,
  setCustomerName,
  setCallingCode,
}) => {
  const { manualLogout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [makeApi, setMakeApi] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [customerFound, setCustomerFound] = useState(false);
  const { partySize } = useSelector((state) => state.createOrder);

  const formattedNumber = useMemo(() => {
    if (
      partySize?.customer_calling_code &&
      partySize?.customer_calling_code !== ""
    ) {
      return customerDetail.customer_number?.slice(
        partySize?.customer_calling_code?.length
      );
    } else {
      return "";
    }
  }, [partySize?.customer_calling_code, customerDetail.customer_number]);

  const searchCustomer = useCallback(() => {
    if (makeApi && customerDetail?.customer_number && formattedNumber !== "") {
      setNotFound(false);
      setLoading(true);
      ResturantService.customerSearch(
        {
          country_code: partySize?.customer_calling_code ?? "",
          customer_phone: formattedNumber,
        },
        (res) => {
          setLoading(false);
          setCustomerFound(true);
          setCustomerName(res?.data?.data?.data?.name);
        },
        (err) => {
          setLoading(false);
          if (err?.response && err.response?.status === 401) {
            toast.error(
              err?.response?.data?.message ||
                "Session expired.Please login again",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            manualLogout();
            return;
          }
          setNotFound(true);
          console.log(err?.response, "ERROR", err?.message, err);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makeApi, customerDetail?.customer_number]);
  useEffect(() => {
    searchCustomer();
  }, [searchCustomer]);

  const addUser = () => {
    if (customerDetail?.customer_name === "") {
      toast.warning("Please provide customer name", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      setAddLoading(true);
      ResturantService.customerAdd(
        {
          country_code: partySize?.customer_calling_code ?? "",
          customer_phone: formattedNumber,
          customer_name: customerDetail?.customer_name,
        },
        (res) => {
          setAddLoading(false);
          setNotFound(false);
          toast.success(
            res?.response?.data?.message || "Customer added successfully",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        },
        (err) => {
          setAddLoading(false);
          if (err?.response && err.response?.status === 401) {
            toast.error(
              err?.response?.data?.message ||
                "Session expired.Please login again",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            manualLogout();
            return;
          }
          toast.error(
            err?.response?.data?.message || "Unable to add customer",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      );
    }
  };

  return (
    <div className="w-full  mt-8">
      {/* Phone Number Details */}
      <div className="w-full mb-10 min-h-[6rem] border-none  rounded-lg relative">
        <PhoneInput
          country={"ae"}
          value={customerDetail.customer_number}
          onChange={(value, data) => {
            setUserInput(value);
            setCallingCode(data?.dialCode);
          }}
          containerClass="min-w-full  min-h-full rounded-lg border-0 border-transparent border-none"
          inputClass="min-w-full min-h-full px-[20px] border-0 border-transparent bg-red-500 border-none"
          enableClickOutside
          enableSearch
          onBlur={() => setMakeApi(true)}
          onFocus={() => setMakeApi(false)}
        />
        <div className="flex flex-row items-center absolute right-4 top-[20%] space-x-4 justify-center">
          {loading ? (
            <ImSpinner2 className="animate-spin" />
          ) : (
            <div>
              {notFound && !customerFound && (
                <p className="text-gray-400 text-[1.5rem] font-medium italic">
                  Not found
                </p>
              )}
              {!notFound && customerFound && (
                <p className="text-gray-400 text-[1.5rem] font-medium italic">
                  Customer found
                </p>
              )}
            </div>
          )}
          {notFound && !customerFound && (
            <button
              onClick={addUser}
              className="btn font-medium text-[16px] text-white bg-primary rounded-md h-[40px] flex flex-row items-center justify-center px-[1rem]"
            >
              {addLoading ? (
                <ImSpinner2 className="animate-spin" />
              ) : (
                <p>Add Customer</p>
              )}
            </button>
          )}
        </div>
      </div>
      {/* Input */}
      {partySize?.customer_number && partySize?.customer_number !== "" && (
        <div className="w-full h-[6rem] bg-white border border-gray-300 rounded-md relative">
          <input
            // aria-label="size-input"
            className="w-full h-full pl-8  bg-transparent outline-none focused:outline-none text-4xl text-gray-500"
            onChange={(e) => setCustomerName(e.target.value)}
            value={customerDetail?.customer_name}
            // min="1"
            type="text"
            placeholder="Customer name"

            // ref={inputReference}
          />
        </div>
      )}
    </div>
  );
};

export default UserInformation;
