import React from "react";
import ButtonSingleScreenNavBar from "../ButtonSingleScreenNavBar";

const ReceiptPageNavigationBarContainer = ({
  onEditCart,
  onCancelModal,
  printReceipt,
  completeCheckoutOrder,
}) => {
  return (
    <div className="w-full grid grid-cols-7">
      <ButtonSingleScreenNavBar
        title="Cancel Order"
        hide={false}
        href={null}
        nav={null}
        btn={true}
        onClick={onCancelModal}
      />
      <ButtonSingleScreenNavBar
        title="Modify Order"
        hide={false}
        href={null}
        nav={null}
        btn={true}
        onClick={onEditCart}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={true}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title={null}
        hide={false}
        href={null}
        nav={null}
      />
      <ButtonSingleScreenNavBar
        title="Print Receipt Only"
        hide={false}
        href={null}
        nav={null}
        btn={true}
        onClick={printReceipt}
      />
      <ButtonSingleScreenNavBar
        title="Confirm Order & Print Receipt"
        hide={false}
        href={null}
        nav={null}
        btn={true}
        onClick={completeCheckoutOrder}
      />
    </div>
  );
};

export default ReceiptPageNavigationBarContainer;
