import ToggleDrawerButton from "components/ToggleDrawerButton/ToggleDrawerButton";
import { useWindowSize } from "hooks/useWindowSize";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import classes from "./GlobalPadding.module.scss";

const GlobalPadding = ({
  children,
  className,
  hideRightPadding,
  hideTopPadding,
  hideLeftPadding,
}) => {
  const { isOpen, hideSideBar, hideHeader } = useSelector(
    (state) => state.drawer
  );
  let size = useWindowSize();
  const getPaddingStatus = () => {
    if (!isOpen && !hideTopPadding) {
      console.log("Calling");
      if (hideHeader) {
        return "mt-[20px] ";
      }
      if (hideSideBar) {
        return "mt-[50px] ";
      }

      return "mt-[150px] ";
    } else if (!isOpen && hideTopPadding) {
      console.log("Calling One");
      if (hideSideBar) {
        return "lg:mt-[0px] lg:pt-[0px] md:mt-[0px] md:pt-[0px]";
      }

      return "lg:mt-[45px] lg:pt-[65px] md:mt-[45px] md:pt-[85px]";
    } else if (isOpen && !hideTopPadding) {
      console.log("Calling Two");
      return "lg:mt-[45px] md:mt-[55px]";
    } else if (!isOpen && hideTopPadding) {
      console.log("Calling Three");
      return "lg:mt-[45px] md:mt-[55px]";
    }
  };

  const getLeftPadding = useMemo(() => {
    if (isOpen && !hideLeftPadding) {
      return classes.containerPadding;
    } else if (isOpen && hideLeftPadding) {
      return classes.containerNoPadding;
    } else if (!isOpen && hideLeftPadding) {
      return classes.containerNoPadding;
    } else if (!isOpen && !hideLeftPadding) {
      return classes.collapsedContainerPadding;
    }
  }, [isOpen, hideLeftPadding]);

  // console.log(isOpen, "Drawer", hideSideBar, "SIDE BAR", hideHeader, "HEADER");

  return (
    <div
      className={`w-full ${
        !hideRightPadding && "pr-[30.2px]"
      } ${getPaddingStatus()} ${className} ${getLeftPadding} h-full overflow-hidden `}
    >
      {!hideSideBar && <div>{size.width >= 768 && <ToggleDrawerButton />}</div>}

      {children}
    </div>
  );
};

export default GlobalPadding;
