import Icon from "components/Icon/Icon";
import React from "react";
import styled from "styled-components";
import { activeFilterServiceIcon } from "utillity/utils";

const SingleDesktopOrderFilter = ({
  label,
  filterOrderType,
  onClick,
  iconName,
  value,
  deleteItem,
  item,
}) => {
  return (
    <DarkBtn
      filterOrderType={filterOrderType}
      className="flex flex-row items-center space-x-4"
    >
      <button onClick={onClick} className="flex flex-row items-center ">
        {value === "live-orders" ? (
          <span className="pr-2">
            <Icon name="red-dot" />
          </span>
        ) : (
          <span className="pr-2">
            {filterOrderType ? (
              activeFilterServiceIcon(iconName)
            ) : (
              <Icon name={iconName} />
            )}
          </span>
        )}

        <p>{label}</p>
      </button>
      {value !== "live-orders" && (
        <button onClick={() => deleteItem(item)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
          >
            <path
              d="M11.4845 9.9995L15.0679 6.4245C15.2248 6.26758 15.3129 6.05475 15.3129 5.83284C15.3129 5.61092 15.2248 5.39809 15.0679 5.24117C14.9109 5.08425 14.6981 4.99609 14.4762 4.99609C14.2543 4.99609 14.0414 5.08425 13.8845 5.24117L10.3095 8.8245L6.73452 5.24117C6.5776 5.08425 6.36477 4.99609 6.14285 4.99609C5.92093 4.99609 5.70811 5.08425 5.55119 5.24117C5.39427 5.39809 5.30611 5.61092 5.30611 5.83284C5.30611 6.05475 5.39427 6.26758 5.55119 6.4245L9.13452 9.9995L5.55119 13.5745C5.47308 13.652 5.41108 13.7441 5.36878 13.8457C5.32647 13.9472 5.30469 14.0562 5.30469 14.1662C5.30469 14.2762 5.32647 14.3851 5.36878 14.4867C5.41108 14.5882 5.47308 14.6804 5.55119 14.7578C5.62866 14.8359 5.72082 14.8979 5.82237 14.9402C5.92392 14.9826 6.03284 15.0043 6.14285 15.0043C6.25286 15.0043 6.36178 14.9826 6.46333 14.9402C6.56488 14.8979 6.65705 14.8359 6.73452 14.7578L10.3095 11.1745L13.8845 14.7578C13.962 14.8359 14.0542 14.8979 14.1557 14.9402C14.2573 14.9826 14.3662 15.0043 14.4762 15.0043C14.5862 15.0043 14.6951 14.9826 14.7967 14.9402C14.8982 14.8979 14.9904 14.8359 15.0679 14.7578C15.146 14.6804 15.208 14.5882 15.2503 14.4867C15.2926 14.3851 15.3144 14.2762 15.3144 14.1662C15.3144 14.0562 15.2926 13.9472 15.2503 13.8457C15.208 13.7441 15.146 13.652 15.0679 13.5745L11.4845 9.9995Z"
              fill={filterOrderType ? "#fff" : "#222"}
            />
          </svg>
        </button>
      )}
    </DarkBtn>
  );
};

export default SingleDesktopOrderFilter;
const DarkBtn = styled.div`
  border-radius: 100px;
  border: 1px solid #222;
  background: ${(props) =>
    props.filterOrderType
      ? "linear-gradient(108deg, #222 46.55%, #F3781F 204.7%)"
      : "transparent"};
  padding: 1rem;
  p {
    color: ${(props) => (props.filterOrderType ? "#fff" : "#000")};
    font-family: Inter;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 70% */
  }
`;
