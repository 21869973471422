export const channelNavData = [
  {
    title: "Go Back",
    nav: true,
    href: "/orders/all",
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: true,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
];

export const channelNavBottomData = [
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: true,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: "Proceed to Pay",
    nav: true,
    href: "/orders/checkout/receipt",
    hide: false,
  },
];

export const categoryTopNavData = [
  {
    title: "Categories",
    nav: true,
    href: "/orders/create/category-selection",
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
    hideDot: true,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: "Cancel",
    nav: true,
    href: "/orders/all",
    hide: false,
  },
];

export const categoryBottomNavData = [
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
    hideDot: true,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
  {
    title: null,
    nav: false,
    href: null,
    hide: false,
  },
];
