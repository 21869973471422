import React, { useMemo } from "react";
import Icon from "components/Icon/Icon";

const SingleAllOrderDropdown = ({
  label,
  value,
  iconName,
  dropDownFilterData,
  onClick,
}) => {
  const isChoosen = useMemo(() => {
    return dropDownFilterData.find((item) => item.value === value);
  }, [dropDownFilterData, value]);
  return (
    <button
      className="w-full flex flex-row items-center justify-between h-[4.5rem] "
      onClick={onClick}
    >
      <div className="flex flex-row items-center space-x-4 ">
        <Icon name={iconName} />
        <span className="text-[1.6rem] font-medium text-gray-800">{label}</span>
      </div>
      <div>
        {isChoosen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M6.80927 9.83398C6.87125 9.89647 6.94498 9.94607 7.02622 9.97991C7.10746 10.0138 7.1946 10.0312 7.2826 10.0312C7.37061 10.0312 7.45775 10.0138 7.53899 9.97991C7.62023 9.94607 7.69396 9.89647 7.75594 9.83398L10.4759 7.11398C10.6015 6.98845 10.672 6.81819 10.672 6.64065C10.672 6.46312 10.6015 6.29285 10.4759 6.16732C10.3504 6.04178 10.1801 5.97126 10.0026 5.97126C9.82507 5.97126 9.65481 6.04178 9.52927 6.16732L7.2826 8.42065L6.47594 7.60732C6.3504 7.48178 6.18014 7.41126 6.0026 7.41126C5.82507 7.41126 5.65481 7.48178 5.52927 7.60732C5.40373 7.73285 5.33321 7.90312 5.33321 8.08065C5.33321 8.25818 5.40373 8.42845 5.52927 8.55398L6.80927 9.83398ZM14.0026 1.33398H2.0026C1.82579 1.33398 1.65622 1.40422 1.5312 1.52925C1.40618 1.65427 1.33594 1.82384 1.33594 2.00065V14.0007C1.33594 14.1775 1.40618 14.347 1.5312 14.4721C1.65622 14.5971 1.82579 14.6673 2.0026 14.6673H14.0026C14.1794 14.6673 14.349 14.5971 14.474 14.4721C14.599 14.347 14.6693 14.1775 14.6693 14.0007V2.00065C14.6693 1.82384 14.599 1.65427 14.474 1.52925C14.349 1.40422 14.1794 1.33398 14.0026 1.33398ZM13.3359 13.334H2.66927V2.66732H13.3359V13.334Z"
              fill="#222222"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14.0026 1.33398H2.0026C1.82579 1.33398 1.65622 1.40422 1.5312 1.52925C1.40618 1.65427 1.33594 1.82384 1.33594 2.00065V14.0007C1.33594 14.1775 1.40618 14.347 1.5312 14.4721C1.65622 14.5971 1.82579 14.6673 2.0026 14.6673H14.0026C14.1794 14.6673 14.349 14.5971 14.474 14.4721C14.599 14.347 14.6693 14.1775 14.6693 14.0007V2.00065C14.6693 1.82384 14.599 1.65427 14.474 1.52925C14.349 1.40422 14.1794 1.33398 14.0026 1.33398ZM13.3359 13.334H2.66927V2.66732H13.3359V13.334Z"
              fill="#1F2937"
            />
          </svg>
        )}
      </div>
    </button>
  );
};

export default SingleAllOrderDropdown;
