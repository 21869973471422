import { useContext, useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchLocations,
  setActiveLocation,
  setActiveLocationInfo,
  setSelectedLocation,
} from "redux/locations";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logoImg from "assets/svg/defaultJalebi.svg";
import Icon from "components/Icon/Icon";
import { AuthContext } from "context/authContext";
import "react-toastify/dist/ReactToastify.css";
import useOutsideClick from "hooks/useOutsideClick";
import classes from "./LocationSwitcher.module.scss";
import { useTranslation } from "react-i18next";
// import useCookies from "../../../../../node_modules/react-cookie/cjs/useCookies";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axiosWithLocationV2 from "config/axiosWithLocationV2";
import { toggleConfig } from "redux/app";

const LocationSwitcher = () => {
  const { t } = useTranslation();
  const { locations, selectedLocation, error } = useSelector(
    (state) => state.location
  );
  const dispatch = useDispatch();
  const { token, restaurantLogo, manualLogout } = useContext(AuthContext);
  const { refetchConfig } = useSelector((state) => state.app);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  // const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();
  const activeLocation = useLocation();
  // const [isFocused, setIsFocused] = useState(false);

  const myLocation = localStorage.getItem("jalebi_active_location_id");

  useOutsideClick(menuRef, () => {
    if (showMenu) setShowMenu(false);
  });

  const toggleMenu = () => {
    setShowMenu((oldState) => !oldState);
  };

  // Is Tab focused
  // const checkTabFocused = () => {
  //   if (document.visibilityState === "visible") {
  //     setIsFocused(true);
  //   } else {
  //     setIsFocused(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("visibilitychange", checkTabFocused);
  //   return () => {
  //     document.removeEventListener("visibilitychange", checkTabFocused);
  //   };
  // });

  const handleLocationChange = (id) => {
    let locationIndex = locations?.findIndex((location) => {
      return location.id === id;
    });
    Cookies.remove("jalebi_pos_location");
    setCurrentLocation(locations[locationIndex]);
    dispatch(setActiveLocationInfo(locations[locationIndex]));
    dispatch(setActiveLocation(locations[locationIndex]));
    // setCookie("jalebi_pos_location", JSON.stringify(locations[locationIndex]));
    Cookies.set(
      "jalebi_pos_location",
      JSON.stringify(locations[locationIndex])
    );
    localStorage.setItem("jalebi_active_location_id", id);
    dispatch(setSelectedLocation(id));
    toggleMenu();
    navigate(activeLocation?.pathname);
    // navigate("/order");
    // window.location.reload();
  };

  // Getting the active location info

  const getActiveLocationInfo = () => {
    axiosWithLocationV2
      .get("/location/info")
      .then((res) => {
        // console.log(res, "RES");
        // dispatch(setActiveLocationInfo(res?.data?.data?.data));
        dispatch(setActiveLocation(res?.data?.data?.data));
        localStorage.setItem(
          "receiptOrder",
          JSON.stringify(res?.data?.data?.receiptOrder)
        );
        localStorage.setItem(
          "shiftStatus",
          res?.data?.data?.data?.shift_started
        );
        dispatch(toggleConfig(false));
      })
      .catch((err) => {
        if (err?.response && err.response?.status === 401) {
          toast.error(
            err?.response?.data?.message ||
              "Session expired.Please login again",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          manualLogout();
        }
        // else {
        //   toast.error(
        //     err?.response?.data?.message || "Error getting location info",
        //     {
        //       position: toast.POSITION.BOTTOM_RIGHT,
        //     }
        //   );
        // }
      });
  };
  useMemo(() => {
    if ((token && selectedLocation) || refetchConfig) {
      getActiveLocationInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, refetchConfig]);

  useEffect(() => {
    if (
      token &&
      (activeLocation?.pathname === "/orders" ||
        activeLocation?.pathname === "/orders/all" ||
        "/shift")
    ) {
      dispatch(fetchLocations(token));
    }
  }, [dispatch, token, activeLocation?.pathname]);

  /* Logout if token expired */
  useEffect(() => {
    if (error && error !== null) {
      toast.error(t("NOTF_LOGIN_EXPIRED"), {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      manualLogout();
    }
  }, [error, manualLogout, t]);

  /* Set current location on first render */
  useEffect(() => {
    if (locations?.length) {
      let location = locations?.find((locationObj) => {
        return locationObj.id === myLocation;
      });
      if (!location) {
        setCurrentLocation(locations[0]);
        dispatch(setSelectedLocation(locations[0]?.id));
        localStorage.setItem("jalebi_active_location_id", locations[0]?.id);
        Cookies.set("jalebi_pos_location", JSON.stringify(locations[0]));
        dispatch(setActiveLocationInfo(locations[0]));
      } else {
        setCurrentLocation(location);
        dispatch(setActiveLocation(location));
        dispatch(setActiveLocationInfo(location));
        dispatch(setSelectedLocation(location?.id));
        localStorage.setItem("jalebi_active_location_id", location?.id);
        Cookies.set("jalebi_pos_location", JSON.stringify(location));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLocation, locations]);

  return (
    <>
      {selectedLocation && currentLocation && (
        <div ref={menuRef} className={classes.LocationSwitcher}>
          <button
            onClick={toggleMenu}
            className={classes.LocationSwitcherHeader}
          >
            {restaurantLogo ? (
              <img
                src={restaurantLogo}
                alt="Logo"
                className="w-[60px] h-[60px] rounded-full"
              />
            ) : (
              <img
                src={logoImg}
                alt="Logo"
                className="w-[60px] h-[60px] rounded-full"
              />
            )}

            <h3>{currentLocation?.name || "Loading"}</h3>
            <Icon name="chevron-down" width={1.2} height={1.2} />
          </button>
          {showMenu && locations?.length > 1 && (
            <ul className={classes.LocationSwitcherMenuList}>
              <span className={classes.LocationSwitcherMenuHeader}>
                {t("SWITCH_LOCATION")}
              </span>
              {locations?.map((location, index) => {
                return (
                  <li
                    key={location.name + index}
                    className={classes.LocationSwitcherMenuItem}
                  >
                    <button
                      className={classes.LocationSwitcherMenuItemButton}
                      onClick={() => handleLocationChange(location?.id)}
                    >
                      {restaurantLogo ? (
                        <img src={restaurantLogo} alt="Logo" />
                      ) : (
                        <img src={logoImg} alt="Logo" />
                      )}
                      <span>{location?.name}</span>
                    </button>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      )}
    </>
  );
};

export default LocationSwitcher;

/* Set default selectedLocation on first render */
// useEffect(() => {
//   const location = Cookies.get("jalebi_pos_location");
//   if (selectedLocation === null && locations?.length) {
//     const location = Cookies.get("jalebi_pos_location");
//     if (!location) {
//       Cookies.set("jalebi_pos_location", JSON.stringify(locations[0]));
//       return dispatch(setSelectedLocation(locations[0].id));
//     }

//     return dispatch(setSelectedLocation(location.id));
//   }
// }, [locations, selectedLocation, currentLocation, dispatch]);

/**Second option */
// useEffect(() => {
//   if (selectedLocation !== null) {
//     let location = locations?.find((locationObj) => {
//       return locationObj.id === selectedLocation;
//     });
//     if (!location) {
//       setCurrentLocation(locations[0]);
//       // dispatch(setActiveLocation(locations[0]));
//       dispatch(setSelectedLocation(locations[0]?.id));
//       localStorage.setItem("jalebi_active_location_id", locations[0]?.id);
//     } else {
//       setCurrentLocation(location);
//       dispatch(setActiveLocation(location));
//       dispatch(setSelectedLocation(location?.id));
//       localStorage.setItem("jalebi_active_location_id", location?.id);
//     }
//   }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, [selectedLocation]);
