import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Table.module.scss";

const TableCell = ({ children, url, onClick }) => {
  return (
    <td onClick={onClick} className={styles.table__cell}>
      {url ? (
        <NavLink href={url} className="w-full" passHref>
          <a href={url}>{children}</a>
        </NavLink>
      ) : (
        children
      )}
    </td>
  );
};

export default TableCell;
