import React from "react";
import SingleScreenNavBar from "./SingleScreenNavBar";

const NavigationBarContainer = ({ data }) => {
  return (
    <div className="w-full grid grid-cols-7">
      {data.map((item, index) => (
        <SingleScreenNavBar {...item} key={index} />
      ))}
    </div>
  );
};

export default NavigationBarContainer;
