import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { cn } from "utillity/utils";
import { fetchAllTenders } from "redux/tenders";
// import { useDefaultSetting } from "hooks/useDefaultSetting";
// import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
// import { ImSpinner2 } from "react-icons/im";

const OutletTenderView = ({
  selectedTender,
  changeTenderHandler,
  // chooseDefaultTender,
  // makeDefaultTender,
  // defaultLoading,
}) => {
  const {
    tenders,
    isLoading: tenderLoading,
    error: tenderError,
  } = useSelector((state) => state.tender);
  const dispatch = useDispatch();
  // const { default_tender } = useDefaultSetting();

  return (
    <div className="w-full">
      <div className="w-full flex flex-row items-center justify-between">
        <h5 className="text-gray-800 font-Inter font-semibold">Tender</h5>
        <h5 className="text-gray-800 font-Inter font-semibold">طرق الدفع</h5>
      </div>
      {/* Tenders view */}
      <div className="w-full ">
        {tenderLoading && !tenderError && (
          <div className="w-full flex flex-row mt-10">
            <Skeleton
              count={5}
              width="100%"
              height={100}
              containerClassName="flex-1 flex flex-row w-full space-x-5"
              className="h-[8rem] flex-row flex w-full"
            />
          </div>
        )}
        {!tenderLoading && tenderError && (
          <div className="w-full flex flex-col items-center justify-center mt-10">
            <p className="text-red-500 font-Inter text-2xl">{tenderError}</p>
            <p
              onClick={() => dispatch(fetchAllTenders())}
              className="text-blue-500 font-semibold underline pt-8 cursor-pointer text-[1.4rem] font-Inter"
            >
              Try Again
            </p>
          </div>
        )}

        {!tenderLoading && tenders && tenders?.length === 0 && (
          <div className="w-full flex flex-row items-center justify-center mt-5">
            <p className="text-red-500 font-Inter text-[1.4rem]">
              No Tender available now at the moment. Please contact Manager to
              add Tender.
            </p>
          </div>
        )}
        {!tenderLoading && tenders && tenders?.length > 0 && (
          <div className="w-full grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mt-10">
            {tenders.map((item) => (
              <button
                onClick={() => changeTenderHandler(item)}
                key={item?.id}
                className={cn(
                  "rounded bg-white flex flex-row items-center justify-center h-[10rem]",
                  [selectedTender?.id === item?.id && "border border-gray-800"]
                )}
              >
                <span className="text-[1.2rem] text-gray-700 font-Inter font-semibold lg:text-[1.4rem] capitalize">
                  {item?.name}
                </span>
              </button>
            ))}
          </div>
        )}
      </div>
      {/* <div className="w-full mt-5">
        {(!default_tender || default_tender == null) &&
          !tenderLoading &&
          !tenderError && (
            <div className="flex flex-row items-center">
              <CustomCheckbox
                name="tender"
                value="makeDefaultTender"
                text="Set as default tender"
                isChecked={makeDefaultTender}
                onChanged={chooseDefaultTender}
              />
              {defaultLoading.tenderLoading && (
                <ImSpinner2 className="animate-spin ml-2 text-primary" />
              )}
            </div>
          )}
      </div> */}
    </div>
  );
};

export default OutletTenderView;
