import SingleScreenNavBar from "components/common/navigationBar/SingleScreenNavBar";
import { categoryBottomNavData } from "pages/OrdersPage/CheckoutPage/navData";
import React from "react";

const BottomNavigationBar = () => {
  return (
    <div className="navigationBar">
      {categoryBottomNavData.map((item, index) => (
        <SingleScreenNavBar key={index} {...item} />
      ))}
    </div>
  );
};

export default BottomNavigationBar;
