import CancelOrderModal from "components/cancelOrderModal/CancelOrderModal";
import { AuthContext } from "context/authContext";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import useToggle from "hooks/useToggle";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { addDuplicateOrder } from "redux/duplicateReceipt";

const OrderDetailFooter = ({
  clickedOrder,
  onEditCart,
  onProceedCart,
  fetchOrders,
  handleCloseModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.cart);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const { restaurantId, locationId, decimal_preference, currency } =
    useGetActiveLocation();
  const [orderCancelReason, setOrderCancelReason] = useState("");
  const [showCancelModal, toggleCancelModal] = useToggle(false);
  const { token, manualLogout } = useContext(AuthContext);
  const [errorReason, setErrorReason] = useState({
    error: false,
    errorText: "",
  });
  const getSubTotalPrice = () => {
    let subTotalPrice = 0;
    if (
      cartState &&
      cartState?.cart &&
      cartState?.cart[cartState?.activeCart]
    ) {
      for (const item of cartState?.cart[cartState?.activeCart]) {
        subTotalPrice += Number(item?.price) * Number(item?.quantity);
      }
    }

    return Number(subTotalPrice);
    // return numberFormat(subTotalPrice, decimal_preference);
  };

  // Main cancel order function
  const handleCancelOrder = () => {
    setErrorReason({
      ...errorReason,
      error: false,
      errorText: "",
    });
    if (!orderCancelReason) {
      setErrorReason({
        ...errorReason,
        error: true,
        errorText: "Please provide reason for cancelling",
      });
    } else {
      // toggleCancelModal();
      setIsCancelLoading(true);
      const body = {
        restaurant_id: restaurantId,
        location_id: locationId,
        status: "cancel",
        order_no: clickedOrder?.order_no,
        reason: orderCancelReason,
        receipt_amount: getSubTotalPrice(),
        paid_amount: 0,
        return_change: 0,
      };
      axios
        .post("/order/status", body, {
          headers: {
            Authorization: `Bearer ${token}`,
            Location: locationId,
          },
        })
        .then((res) => {
          setOrderCancelReason("");
          toggleCancelModal();
          setIsCancelLoading(false);
          toast.success(res.data.message || "Order has been canceled.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          handleCloseModal();
          fetchOrders();
        })
        .catch((err) => {
          setIsCancelLoading(false);
          toggleCancelModal();
          if (err && err?.response && err.response.status === 401) {
            manualLogout();
          } else if (
            err &&
            err?.response &&
            err?.response?.data?.message?.order_no
          ) {
            const message = err?.response?.data?.message?.order_no[0];
            toast.error(message || "Unable to cancel order!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error(
              err.response.data.message || "Unable to cancel order!",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }
        });
    }
  };

  return (
    <div className="w-full pb-2">
      <div className="w-full h-[5rem] bg-white rounded-md border-b border-b-gray-500 flex flex-row items-center justify-between px-4">
        <p className="text-[1.4rem] text-gray-700 font-medium">Subtotal</p>
        <p className="text-[1.4rem] font-semibold font-Inter text-gray-800">
          {currency}&nbsp;{getSubTotalPrice().toFixed(decimal_preference)}
        </p>
      </div>
      <span className="italic font-medium text-gray-500 pt-2 inline-block">
        The total amount calculate is before taxes
      </span>
      {/* Controls */}
      {clickedOrder && clickedOrder?.status === "complete" && (
        <div className="w-full mt-10 grid grid-cols-1 ">
          <button
            onClick={() => {
              dispatch(addDuplicateOrder(clickedOrder));
              navigate("/orders/checkout/duplicate-receipt");
            }}
            className="darkOutlineBtn "
          >
            Print Duplicate
          </button>
          {/* <button onClick={toggleCancelModal} className="darkBtn">
            Void Order
          </button> */}
        </div>
      )}
      {clickedOrder &&
        clickedOrder?.status !== "complete" &&
        clickedOrder?.status !== "cancel" && (
          <div className="w-full">
            <div className="w-full mt-10 grid grid-cols-2 gap-5 mb-4">
              <button
                onClick={() => {
                  if (!cartState.cart[cartState.activeCart]) {
                    return;
                  }
                  onEditCart();
                }}
                className="darkOutlineBtn"
              >
                Edit Order
              </button>
              <button
                onClick={() => {
                  if (!cartState.cart[cartState.activeCart]) {
                    return;
                  }
                  toggleCancelModal();
                }}
                className="darkOutlineBtn"
              >
                Cancel Order
              </button>
            </div>
            <button
              onClick={() => {
                if (!cartState.cart[cartState.activeCart]) {
                  return;
                }
                onProceedCart();
              }}
              className="darkBtn space-x-2 w-full"
            >
              <span>Checkout</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM15.7 12.7L12.7 15.7C12.3 16.1 11.7 16.1 11.3 15.7C10.9 15.3 10.9 14.7 11.3 14.3L12.6 13H9C8.4 13 8 12.6 8 12C8 11.4 8.4 11 9 11H12.6L11.3 9.7C10.9 9.3 10.9 8.7 11.3 8.3C11.7 7.9 12.3 7.9 12.7 8.3L15.7 11.3C16.1 11.7 16.1 12.3 15.7 12.7Z"
                  fill="#FFF3EB"
                />
              </svg>
            </button>
          </div>
        )}

      {/* Cancel order modal */}
      <CancelOrderModal
        showModal={showCancelModal}
        toggleModal={toggleCancelModal}
        value={orderCancelReason}
        onChange={(val) => setOrderCancelReason(val)}
        placeholder="Give us your reason for cancelling order"
        onProceed={() => {
          handleCancelOrder();
        }}
        error={errorReason.error}
        errorText={errorReason.errorText}
        onFocus={() => setErrorReason({ error: false, errorText: "" })}
        isLoading={isCancelLoading}
      />
    </div>
  );
};

export default OrderDetailFooter;
