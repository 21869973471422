import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import React from "react";

const SingleOrderDetailItem = ({
  item_name,
  sizes,
  translations,
  quantity,
  price,
}) => {
  const { currency, decimal_preference } = useGetActiveLocation();
  return (
    <div className="w-full bg-white border border-gray-300 px-4 py-3 rounded mb-4 grid grid-cols-[1fr_auto]">
      <div className="">
        <p className="text-[1.4rem] text-gray-700 font-medium">{item_name}</p>
        {sizes && sizes !== null && sizes[0] && (
          <p className="text-[1.4rem] text-gray-500 italic pt-1 capitalize">
            {sizes[0]?.size}
          </p>
        )}
        {translations && translations?.ar && translations?.ar !== null && (
          <p className="text-[1.4rem] text-gray-500 italic pt-1 capitalize">
            {translations?.ar?.name}
          </p>
        )}
      </div>
      <div className="flex flex-row items-center space-x-5">
        <p className="text-[1.2rem] font-medium text-gray-700">QT:{quantity}</p>
        <p className="text-[1.2rem] font-medium text-gray-800">
          {currency}&nbsp;{Number(price).toFixed(decimal_preference)}
        </p>
      </div>
    </div>
  );
};

export default SingleOrderDetailItem;
