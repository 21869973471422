import Icon from "components/Icon/Icon";
import classes from "./DrawerCartPrimaryActionButtons.module.scss";

const DrawerCartPrimaryActionButtons = ({
  onProceed = () => {},
  onEdit = () => {},
  text = "",
  textTwo = "",
  icon = "",
  iconTwo = "",
  hideVoidBtn = false,
}) => {
  return (
    <div className={classes.DrawerCartPrimaryActionButtons}>
      {!hideVoidBtn && (
        <button onClick={onEdit}>
          <Icon name={icon ? icon : "order-edit-1"} width={2.4} height={2.4} />
          <span>{text ? text : "Edit Order"}</span>
        </button>
      )}

      <button onClick={onProceed}>
        <Icon name={iconTwo ? iconTwo : "bill"} width={2.4} height={2.4} />
        <span>{textTwo ? textTwo : "Proceed to Checkout"}</span>
      </button>
    </div>
  );
};

export default DrawerCartPrimaryActionButtons;
