import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { addSelectedZone, fetchZones } from "redux/zone";
import { fetchZoneTableDetail } from "redux/zoneTable";
import ZoneTables from "../../components/ZoneTables";

const TableAllocationLayout = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { table: selectedTable } = useSelector((state) => state.createOrder);
  const [activeZone, setActiveZone] = useState({});

  const {
    error: zonesError,
    isLoading: zonesLoading,
    zones,
    selectedZone,
  } = useSelector((state) => state.zone);
  const {
    zoneTableDetail,
    isLoading: zoneDetailLoading,
    error: zoneDetailError,
  } = useSelector((state) => state.zoneTableDetail);

  // Change viewed zone
  const changeZone = (zone) => {
    setActiveZone(zone);
  };
  // console.log(zones);
  const getData = () => {
    dispatch(fetchZones());
  };
  const getTable = () => {
    dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
  };

  useEffect(() => {
    dispatch(fetchZones());
  }, [dispatch]);

  // Choosing Default Zone
  const chooseDefaultZone = useCallback(() => {
    if (zones && zones.length > 0) {
      if (selectedZone) {
        const activeZone = zones?.filter(
          (item) => item?.id === selectedZone
        )[0];
        if (activeZone) {
          setActiveZone(activeZone);
        } else {
          dispatch(addSelectedZone(Number(zones[0].id)));
          setActiveZone(zones[0]);
        }
      } else {
        dispatch(addSelectedZone(Number(zones[0].id)));
        setActiveZone(zones[0]);
      }
    }
  }, [zones, dispatch, selectedZone]);

  useEffect(() => {
    chooseDefaultZone();
  }, [chooseDefaultZone]);

  useEffect(() => {
    if (zones && zones?.length > 0 && activeZone?.id) {
      dispatch(fetchZoneTableDetail({ id: activeZone?.id }));
    }
  }, [activeZone, dispatch, zones]);

  return (
    <div className="w-full h-full bg-gray-50 xl:px-[2rem] xl:py-[4rem] lg:px-[2rem] lg:py-[3rem] md:px-[1rem] md:py-[2.5rem]">
      <div className="w-full h-full flex lg:flex-row flex-col-reverse justify-between lg:space-x-16 space-x-5">
        {/* Table Zone */}
        <div className=" w-full lg:w-[80%]  h-full">
          <h6 className="text-[1.5rem] font-semibold text-gray-500 mb-2">
            Please Select a Table for the seating.
          </h6>
          {activeZone && activeZone?.name && (
            <h6 className="text-gray-700 text-[16px] font-semibold mb-4">
              {activeZone?.name}
            </h6>
          )}
          <div className=" w-full bg-[#E5E7EB] h-full">
            {activeZone?.id && (
              <ZoneTables
                zoneTableDetail={zoneTableDetail}
                zoneDetailLoading={zoneDetailLoading}
                zoneDetailError={zoneDetailError}
                getTable={getTable}
                activeZone={activeZone}
                zones={zones}
              />
            )}
            {!activeZone?.id && (
              <div className="w-[80%] h-full flex flex-col items-center justify-center mx-auto">
                <p className="pt-5 text-3xl text-center font-Inter font-medium text-textColor">
                  <span>
                    No zones available. Please contact your Manager to add
                    zones.
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
        {/* Zones */}
        <div className="w-full lg:w-[20%] ">
          <h6 className="text-gray-800 text-3xl font-bold">All Zones</h6>

          <div className="w-full lg:h-full  min-w-full">
            {/* Loading view */}
            {zonesLoading && !zonesError && (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <Loader />
              </div>
            )}
            {/* Error view */}
            {!zonesLoading && zonesError && (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <p className="text-2xl lg:text-2xl font-Inter font-medium text-red-300 text-center">
                  Error occurred while fetching your zones
                </p>
                <div className="mt-10 w-full flex flex-row items-center justify-center">
                  <PrimaryButton
                    text="Try Again"
                    onClicked={() => {
                      getData();
                    }}
                  />
                </div>
              </div>
            )}
            {/* Empty view */}
            {!zonesError && !zonesLoading && zones?.length === 0 && (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <p className="pt-5 text-2xl font-Inter font-medium text-textColor">
                  <span>
                    No zones available. Please contact your Manager to add
                    zones.
                  </span>
                </p>
              </div>
            )}
            {/* Zones view */}
            {!zonesError && !zonesLoading && zones?.length > 0 && (
              <div className="w-full lg:h-full grid lg:flex grid-cols-4 gap-5 lg:flex-col items-center lg:space-y-4 mt-14 lg:mb-0 mb-8  min-w-full">
                {zones.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      dispatch(addSelectedZone(item?.id));
                      changeZone(item);
                    }}
                    className={
                      activeZone?.id === item?.id
                        ? "w-full rounded-md flex flex-row items-center justify-center border bg-textColor border-textColor  h-[70px]"
                        : "w-full rounded-md flex flex-row items-center justify-center border border-gray-300 h-[70px]"
                    }
                  >
                    <p
                      className={
                        activeZone?.id === item.id
                          ? "text-[1.5rem] text-white font-bold "
                          : "text-[1.5rem] text-textColor font-bold "
                      }
                    >
                      {item?.name}
                    </p>
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableAllocationLayout;
