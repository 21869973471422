import ButtonSingleScreenNavBar from "components/common/navigationBar/ButtonSingleScreenNavBar";
import SingleScreenNavBar from "components/common/navigationBar/SingleScreenNavBar";
import { ORDER_UPDATE_PERMISSION } from "constants/constants";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const MainDishBottomBar = ({ dishView, changeDishView, placeOrder }) => {
  const cartState = useSelector((state) => state.cart);
  // To determine if the logged in user can update an order
  const canUpdateOrder = usePermissionHooks(ORDER_UPDATE_PERMISSION);
  const { isEditing } = useSelector((state) => state.createOrder);

  const submitFunction = () => {
    if (cartState?.cart[cartState?.activeCart]?.length === 0) {
      toast.warning("Please add items to the order before proceeding", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (!canUpdateOrder.canView) {
      toast.warning("You don not have permission to perform this action", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    if (isEditing) {
      placeOrder("update");
    } else {
      placeOrder("create");
    }
  };

  return (
    <div className="navigationBar">
      <SingleScreenNavBar title="Discard Order" href="/orders/all" nav={true} />
      <ButtonSingleScreenNavBar
        btn={true}
        hide={false}
        nav={false}
        title="Hold Course"
      />
      <ButtonSingleScreenNavBar
        btn={true}
        hide={false}
        nav={false}
        title="Hold Cheque"
      />
      <ButtonSingleScreenNavBar btn={true} hide={true} nav={false} />
      <ButtonSingleScreenNavBar
        btn={true}
        hide={false}
        nav={false}
        title="Notes"
        active={dishView === "note"}
        onClick={() => {
          changeDishView("note");
        }}
      />
      <ButtonSingleScreenNavBar
        btn={true}
        hide={false}
        nav={false}
        title="Discounts"
        active={dishView === "discount" || dishView === "discount_selection"}
        onClick={() => {
          changeDishView("discount_selection");
        }}
      />
      <ButtonSingleScreenNavBar
        btn={true}
        hide={false}
        nav={false}
        title={isEditing ? "Update" : "Proceed"}
        onClick={submitFunction}
      />
    </div>
  );
};

export default MainDishBottomBar;
