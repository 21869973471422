import React from "react";
import styled from "styled-components";

const ShiftInput = ({ placeholder, value, onChange }) => {
  return (
    <InputContainer className="w-full flex flex-row items-center  bg-white  rounded-md px-4 h-[88px] mb-4">
      <input
        aria-label="size-input"
        className="w-[90%] ml-8 outline-none h-full bg-transparent focused:outline-none text-4xl"
        // id={id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        min="1"
        type="number"
      />
      <div className="xl:w-[30%] lg:w-[40%] md:w-[50%] h-full flex flex-col justify-center py-2">
        <p className="text-gray-400 text-[24px]">أدخل المصروفات النثرية</p>
      </div>
    </InputContainer>
  );
};

export default ShiftInput;

const InputContainer = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
