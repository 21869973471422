import React from "react";
import styled from "styled-components";

const RoundedButton = ({
  style = {},
  onClick = () => {},
  enText = "",
  arText = "",
  secondaryBtn = false,
  dark = false,
}) => {
  if (dark) {
    return (
      <DarkBtn
        onClick={onClick}
        className="flex flex-row items-center justify-end space-x-5"
      >
        <p>{enText}</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM15.7 12.7L12.7 15.7C12.3 16.1 11.7 16.1 11.3 15.7C10.9 15.3 10.9 14.7 11.3 14.3L12.6 13H9C8.4 13 8 12.6 8 12C8 11.4 8.4 11 9 11H12.6L11.3 9.7C10.9 9.3 10.9 8.7 11.3 8.3C11.7 7.9 12.3 7.9 12.7 8.3L15.7 11.3C16.1 11.7 16.1 12.3 15.7 12.7Z"
            fill="#FFF3EB"
          />
        </svg>
      </DarkBtn>
    );
  }
  return (
    <div
      onClick={onClick}
      className={
        !secondaryBtn
          ? "px-5 py-4 rounded-[30px] flex flex-row items-center justify-center border border-primary  text-primary cursor-pointer"
          : "px-5 py-4 rounded-[30px] flex flex-row items-center justify-center border border-gray-400 text-gray-600 cursor-pointer"
      }
      style={style}
    >
      <p className="px-4 font-Inter font-medium capitalize">{enText}</p>
      <p className="px-4 font-Inter font-medium capitalize">{arText}</p>
    </div>
  );
};

export default RoundedButton;

const DarkBtn = styled.button`
  border-radius: 100px;
  border: 1px solid var(--flowkit-charcoal, #222);
  background: var(--flowkit-charcoal, #222);
  padding: 1rem 2rem;
  p {
    color: #fff3eb;
    font-family: Inter;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 70% */
  }
`;
