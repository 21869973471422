import React from "react";
import moment from "moment";
import { STATUS } from "components/Status/Status";

const SinglePosOrderHeader = ({ selectedOrderType, filterOrderType, item }) => {
  const { status, created_at, delivery_channel, outletTable, zone } = item;
  return (
    <div className="w-full">
      {selectedOrderType?.value !== "complete" && (
        <div className="w-full">
          <div className="w-full flex flex-row items-center justify-between">
            <p className="text-lg text-gray-700 font-Inter font-medium">
              <span>{moment(created_at).format("hh:mm:ss A")}</span>
            </p>
            <div className="flex flex-row items-center space-x-10">
              <div className="flex flex-row items-center space-x-2">
                <span
                  className="w-[0.6rem] h-[0.6rem] rounded-full inline-block"
                  style={{
                    backgroundColor: STATUS[status].dotColor,
                  }}
                ></span>
                <span className="font-Inter font-medium text-[1.4rem]">
                  {STATUS[status].text}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedOrderType?.value === "complete" && (
        <div className="w-full">
          {/* Not dine in  but complete-order */}
          {filterOrderType?.value !== "dine in" && (
            <div className="w-full flex flex-row items-center justify-between">
              <p className="text-lg text-gray-700 font-Inter font-medium">
                <span>{moment(created_at).format("hh:mm:ss A")}</span>
              </p>
              {delivery_channel && delivery_channel?.name && (
                <p className="text-[1.6rem] font-Inter font-semibold text-gray-700">
                  {delivery_channel?.name}
                </p>
              )}
              {!delivery_channel && !delivery_channel?.name && (
                <div className="flex flex-row items-center space-x-2">
                  <span
                    className="w-[0.6rem] h-[0.6rem] rounded-full inline-block"
                    style={{
                      backgroundColor: STATUS[status].dotColor,
                    }}
                  ></span>
                  <span className="font-Inter font-medium text-[1.4rem]">
                    {STATUS[status].text}
                  </span>
                </div>
              )}
            </div>
          )}
          {/* Dine in completed order */}
          {filterOrderType?.value === "dine in" && (
            <div className="w-full flex flex-row items-center justify-between">
              <div>
                {outletTable && outletTable?.table_number && (
                  <h6 className="text-[2rem] text-gray-800 font-medium">
                    {outletTable.table_number}
                  </h6>
                )}
                {outletTable === null && zone && zone?.name && (
                  <h6 className="text-[2rem] text-gray-800 font-medium">
                    {zone?.name}
                  </h6>
                )}
              </div>
              <div className=" flex flex-row items-center justify-center bg-[#F1F5FD] rounded-lg py-[0.8rem] px-[1.2rem]">
                <p className="text-gray-700 font-medium font-Inter text-[1.4rem]">
                  45 mins
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SinglePosOrderHeader;
