import React from "react";
import TopNavigationBar from "./navigationBar/TopNavigationBar";
import BottomNavigationBar from "./navigationBar/BottomNavigationBar";

const CategoryNavigationLayout = ({ children }) => {
  return (
    <div className="w-full grid grid-rows-[auto_1fr_auto] h-screen max-h-screen">
      <TopNavigationBar />
      {children}
      <BottomNavigationBar />
    </div>
  );
};

export default CategoryNavigationLayout;
