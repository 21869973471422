import React from "react";
// import MainDishSelectionDishes from "./MainDishSelectionDishes";
import MainDishSelectionFeaturedItems from "./MainDishSelectionFeaturedItems";
import MainDishSelectionPopularItems from "./MainDishSelectionPopularItems";
import MainDishSelectionCategory from "./MainDishSelectionCategory";

const MainDishSelectionLeftSide = ({
  fetchDishes,
  changePage,
  total_pages,
  page,
  addDishToCartHandler,
  dishSelectionView,
  changeDishSelectionView,
}) => {
  return (
    <div className="w-full h-full bg-gray-200 lg:px-4 py-8 lg:pr-4 pr-8 pl-4">
      {dishSelectionView && dishSelectionView === "featured" && (
        <MainDishSelectionFeaturedItems
          changeDishSelectionView={changeDishSelectionView}
          addDishToCartHandler={addDishToCartHandler}
        />
      )}
      {dishSelectionView && dishSelectionView === "popular" && (
        <MainDishSelectionPopularItems
          changeDishSelectionView={changeDishSelectionView}
          addDishToCartHandler={addDishToCartHandler}
        />
      )}
      {dishSelectionView && dishSelectionView === "categories" && (
        <MainDishSelectionCategory
          changeDishSelectionView={changeDishSelectionView}
          fetchDishes={fetchDishes}
          changePage={changePage}
          total_pages={total_pages}
          page={page}
          addDishToCartHandler={addDishToCartHandler}
        />
      )}
      {/* <MainDishSelectionDishes
        fetchDishes={fetchDishes}
        changePage={changePage}
        total_pages={total_pages}
        page={page}
        addDishToCartHandler={addDishToCartHandler}
      /> */}
    </div>
  );
};

export default MainDishSelectionLeftSide;
