import NavigationBarContainer from "components/common/navigationBar/NavigationBarContainer";
import React from "react";
import { channelNavData } from "./navData";

const CheckoutNavigationLayout = ({ children }) => {
  // let location = useLocation();
  return (
    <div className="w-full min-h-screen flex-1  max-h-screen overflow-hidden grid grid-rows-[auto_1fr_auto]">
      <NavigationBarContainer data={channelNavData} />
      {children}
      {/* {location &&
        location?.pathname === "/orders/checkout/payment-channel" && (
          <NavigationBarContainer data={channelNavBottomData} />
        )} */}
    </div>
  );
};

export default CheckoutNavigationLayout;
