import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  shiftReport: [],
  shiftReportTableHeaderData: [],
};

const shiftSlice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    saveReport(state, { payload }) {
      state.shiftReport = [...state.shiftReport, ...payload.data];
      state.shiftReportTableHeaderData = [...payload.tableHeaderData];
    },
    clearShiftReport(state) {
      state.shiftReport = [];
      state.shiftReportTableHeaderData = [];
    },
  },
});

export const { saveReport, clearShiftReport } = shiftSlice.actions;
export default shiftSlice.reducer;
